<template>
    <svg class="scale-150" viewBox="0 0 347.48 347.48">
        <defs>
            <clipPath id="clip-path">
                <rect x="7.76" y="7.33" width="331.96" height="331.96" fill="none"/>
            </clipPath>
        </defs>
        <g clip-path="url(#clip-path)">
            <path fill="white"
                  d="M173.74,7.33A166,166,0,0,0,34.72,264L18,326.54,79.62,310A166,166,0,1,0,173.74,7.33Zm-1,70.56c17.61,0,28.07,14.3,28.07,26,0,14-10,25.76-28.07,25.76-16.07,0-27.54-13.52-27.54-25.76C145.17,92.19,154.85,77.89,172.71,77.89Zm36.75,190.82H138V235.29h10.71V174.34H138V141.16H197.2v94.13h12.24v33.42Z"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: "InfoIcon"
}
</script>
