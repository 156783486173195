document.addEventListener('DOMContentLoaded', function(){
    let tabContainers = document.querySelectorAll("ul.tabs");

    if (tabContainers.length > 0) {
        tabContainers.forEach(function(tabContainer) {
            let tabTogglers = tabContainer.querySelectorAll("li");
            let tabContents = tabContainer.parentNode.querySelector(".tab-contents").children;

            let firstTab = null;
            if (tabContents.length > 0) {
                firstTab = tabContents[0].id;
            }

            tabTogglers.forEach(function(toggler) {
                toggler.addEventListener("click", function(e) {
                    e.preventDefault();

                    let tab = this.querySelector('a');
                    let tabName = tab.getAttribute("href");

                    tabTogglers.forEach(tabToggler => tabToggler.classList.remove('selected'));
                    Array.from(tabContents).forEach(tabContent => {
                        tabContent.classList.remove('active');

                        if ("#" + tabContent.id === tabName) {
                            tabContent.classList.add("active");
                        }
                    });

                    this.classList.add("selected");
                });

                if ('#' + firstTab === toggler.querySelector('a').getAttribute('href')) {
                    toggler.click();
                }
            });
        });
    }
});
