<template>
<div class="w-full flex justify-between items-center py-4 px-2 bg-gray-100">
    <div class="w-full font-bold">{{ title }}</div>
    <div class="flex space-x-2">
        <div class="flex" v-if="selected_tool.options.includes('displayStudentName')" :class="!loading ? 'visible':'invisible'">
            <div class="flex items-center justify-center space-x-2" v-if="options['displayStudentName'].value">
                <ValueSelect :obj="options['displayStudentName']" :prefix="['İsim', 'Numara']"/>
            </div>
            <div class="flex"  v-else>
                <ValueSelect :obj="options['gradeSize']"/>
                <div class="p-1">
                    <button class="w-full h-full px-3 bg-secondary rounded-full hover:bg-primary-300 text-white" @click="options['displayStudentName'].value = true" v-if="options['displayStudentName'].visible">
                        x
                    </button>
                </div>
            </div>
        </div>

        <div class="flex items-center justify-center space-x-2" v-if="selected_tool.options.includes('fineSelection')" :class="!loading ? 'visible':'invisible'">
            <ValueSelect :obj="options['fineSelection']" :prefix="['Hassas Seç', 'Rastgele Seç']"/>
        </div>

        <div class="flex items-center justify-center space-x-2" v-if="selected_tool.options.includes('groupSize')" :class="!loading ? 'visible':'invisible'">
            <ValueSelect :obj="options['groupSize']"/>
        </div>

        <div class="flex items-center justify-center space-x-2" v-if="selected_tool.options.includes('randomSelectionSpeed')" :class="!loading ? 'visible':'invisible'">
            <ValueSelect :obj="options['randomSelectionSpeed']" :prefix="['sn']"/>
        </div>

        <div class="bg-secondary px-2 py-1 rounded-lg cursor-pointer text-lg text-white w-max flex items-center justify-center" @click="fscreen = !fscreen">
            <FullScreen v-if="!fscreen"/>
            <OffScreen v-else/>
        </div>
        <div class="bg-secondary px-2 py-1 rounded-lg cursor-pointer text-lg text-white" @click="setClose">Kapat</div>
    </div>
</div>
</template>


<script setup>
import { defineProps, defineEmits,ref, watch} from 'vue';
import ValueSelect from './valueSelect.vue'
import {FullScreen, OffScreen} from '@icon-park/vue-next'

const props         = defineProps(['options', 'title', 'selected_tool', 'loading', 'fullScreen'])
const emit          = defineEmits(['close', 'fullscreen'])
const fscreen    = ref(false)

const setClose  = () => {
    emit('close')
    fscreen.value = false
    emit('fullscreen', false)
}


watch(fscreen, cFullscreen => {
    emit('fullscreen', cFullscreen)
})


</script>
