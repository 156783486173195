<template>
    <div class="py-1 flex justify-between" v-if="selected || props.data.show">
        <div class="flex items-center font-medium text-sm text-gray-900">
            <img class="fill-current w-8 h-8" src="/img/profile/default_profile_image.jpg" :alt="props.data.fullName" />
            <span class="ml-2">{{ props.data.full_name }}</span>
        </div>
        <div v-if="props.selected === 0" class="flex items-center shrink-0">
            <button type="button" class="mx-2 btn btn-sm" @click="$emit('onStudentSelected', props.data)">Ekle</button>
        </div>
        <div v-else class="flex items-center shrink-0">
            <button type="button" class="mx-2 btn btn-sm" @click="$emit('onStudentDeselected', props.data)">Çıkar</button>
        </div>
    </div>
</template>

<script setup>
    const props = defineProps(['data', 'selected']);
    const emits = defineEmits(['onStudentSelected', 'onStudentDeselected']);
</script>
