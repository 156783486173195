let sidebar = document.getElementById('landing-menu');

if (sidebar) {
    document.getElementById('menu-button').addEventListener("click", function(e) {
        sidebar.classList.add("opened");
        document.getElementById('menu-close-overlay').style.display = 'block';
    });
    document.getElementById('menu-close-button').addEventListener("click", function(e) {
        closeSidebar();
    });
    document.getElementById('menu-close-overlay').addEventListener("click", function(e) {
        closeSidebar();
    });

    let menuLinks = sidebar.querySelectorAll('.menu-link');
    menuLinks.forEach(function(menuLink) {
        menuLink.addEventListener("click", function(e) {
            closeSidebar();
        });
    });

    function closeSidebar(){
        sidebar.classList.remove("opened");
        document.getElementById('menu-close-overlay').style.display = 'none';
    }

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });
}
