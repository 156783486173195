<template>
    <div class="bg-white p-4 rounded-xl">
        <div class="mb-2 text-gray-960 font-bold text-center text-xs md:text-sm">{{ label }}</div>
        <div v-if="props.filterData.length" class="text-right">
            <select v-model="selectedFilterValue" class="form-control" @change="getData">
                <option value="">{{ filterDefaultOption }}</option>
                <option :key="filterDatum.id" v-for="filterDatum in filterData" :value="filterDatum.id">
                    {{ filterDatum.name }}
                </option>
            </select>
        </div>
        <DataTable
            :columns="columns"
            :ajax="props.url"
            :options="options"
            class="display text-sm"
            width="100%">
            <thead class="text-secondary">
            <tr class="text-sm">
                <th v-for="columnText in props.columnTexts">{{ columnText }}</th>
            </tr>
            </thead>
        </DataTable>
    </div>
</template>

<script setup>
    import {ref} from "vue";
    import DataTable from 'datatables.net-vue3';
    import DataTablesCore from 'datatables.net';

    DataTable.use(DataTablesCore);

    const props = defineProps({
        url: {type: String},
        label: {type: String, default: ''},
        columnKeys: {type: Array, default: []},
        columnTexts: {type: Array, default: []},
        filterData: {type: Array, default: []},
        filterKey: {type: String, default: ''},
        filterDefaultOption: {type: String, default: ''}
    });

    const columns = [];

    props.columnKeys.forEach(item => {
        columns.push({data: item});
    });

    const options = {
        responsive: true,
        searching: false,
        paging: false,
        lengthChange: false,
        info: false,
        language: {
            "url": "/assets/plugins/datatables/Turkish.json"
        }
    };
</script>

<style>
@import 'datatables.net-dt';
@import 'datatables.net-responsive-dt';
</style>
