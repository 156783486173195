<template>
    <fs v-model="fullscreen" class="h-full w-full select-none bg-primary-darkest">

        <div class="flex flex-col w-full h-full space-y-2 items-center justify-center py-6">
            <div class="text-white absolute -left-24 -bottom-24 z-10 opacity-40"><Timer size="240" /></div>
            <div class="flex font-bold py-6 text-yellow-default" :class="fullscreen ? 'text-8xl': 'text-5xl'">
                <span>{{cStopwatch.hours <= 9 ? '0':''}}{{stopwatch.hours}}</span>:
                <span>{{cStopwatch.minutes <= 9 ? '0':''}}{{stopwatch.minutes}}</span>:
                <span>{{cStopwatch.seconds <= 9 ? '0':''}}{{stopwatch.seconds}}</span>
                <span class="flex items-center" :class="fullscreen ? 'text-2xl': 'text-sm '">. {{getMilisecondString()}}</span>
            </div>

            <div class="flex space-x-2 text-white h-12">
                <button class="bg-secondary px-4 py-2 rounded-lg"   @click="actions = 'start'" v-if="actions == 'beginning'">
                    <PlayOne size="20"/>
                </button>
                <div class="flex space-x-2" v-else>
                    <button class="px-4 py-2 rounded-lg" :class="['start', 'restart'].includes(actions) ? 'hidden':'bg-secondary'"  @click="!['start', 'restart'].includes(actions) && (actions = 'start')">
                        <PlayOne size="20"/>
                    </button>
                    <button class=" px-4 py-2 rounded-lg" :class="['pause'].includes(actions) ? 'hidden':'bg-secondary'"  @click="!['pause'].includes(actions) && (actions = 'pause')">
                        <Pause size="20"/>
                    </button>
                    <button class="px-4 py-2 rounded-lg" :class="['start', 'restart'].includes(actions) ? 'hidden':'bg-secondary'"  @click="!['start', 'restart'].includes(actions) && (actions = 'restart')">
                        <Refresh size="20"/>
                    </button>
                    <button class="px-4 py-2 rounded-lg" :class="['pause'].includes(actions) ? 'hidden':'bg-secondary'" @click="addStap">
                        <Plus size="20"/>
                    </button>
                </div>
                <button class="px-4 py-2 rounded-lg bg-secondary" @click="fullscreen = !fullscreen">
                    <FullScreen size="20" v-if="!fullscreen"/>
                    <OffScreen size="20" v-else/>
                </button>
            </div>


            <div class="w-full pt-20 pb-2 flex items-center justify-center" v-if="staps.length > 0">
                <div class="bg-white rounded-lg p-2  h-72 overflow-auto" :class="fullscreen ? 'w-1/2': 'w-full'">
                    <div class="flex items-center justify-center border-b p-2" v-for="s,key in staps" :key="s.id">
                        <div class="w-8 text-gray-600">{{ key + 1 }}.</div>
                        <div class="flex w-full text-2xl">
                            <span>{{s.hours <= 9 ? '0':''}}{{s.hours}}</span>:
                            <span>{{s.minutes <= 9 ? '0':''}}{{s.minutes}}</span>:
                            <span>{{s.seconds <= 9 ? '0':''}}{{s.seconds}}</span>
                            <span class="text-gray-600 text-sm flex items-end mb-1">.{{s.millisecond}}</span>
                        </div>
                        <div>
                            <button class="bg-secondary p-2 text-xs text-white rounded-lg" @click="deleteStap(s.id)">
                                <Delete size="15" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </fs>
</template>


<script setup>
import { watch, defineProps, ref} from "vue";
import { useStopwatch } from 'vue-timer-hook';
import {Timer, Pause, PlayOne, Refresh, Delete, Plus, FullScreen, OffScreen} from '@icon-park/vue-next'
const props         = defineProps(['index', 'hour', 'second', 'minute'])
const stopwatch     = useStopwatch(0,false);

const fullscreen    = ref(false)

const actions       = ref('beginning') //beginning
const cStopwatch    = ref({seconds: 0, minutes:0, hours:0})
const millisecond   = ref(0)
const interval      = ref(null)
const staps         = ref([])


watch(actions, (cActions) => {
    if (cActions == 'start') {
        stopwatch.start()
        setMillisecond()
    }
    else if (cActions == 'restart') {
        stopwatch.reset()
        millisecond.value = 0
        clearInterval(interval.value)
        //setMillisecond()
        staps.value = []
        actions.value = 'beginning'
        stopwatch.pause()
    }
    else if(cActions == 'pause') {
        stopwatch.pause()
        clearInterval(interval.value)
    }
})


watch(stopwatch.seconds,(t) => {
    cStopwatch.value.seconds = t
}, {immediate: true})


watch(stopwatch.minutes,(t) => {
    cStopwatch.value.minutes = t
}, {immediate: true})

watch(stopwatch.hours,(t) => {
    cStopwatch.value.hours = t
}, {immediate: true})

const getRandomNumber = () => {
    return Math.floor(Math.random() * 10000);
}

const addStap = () => {
    let newStap = {
        id: getRandomNumber(),
        millisecond: getMilisecondString(),
        ...cStopwatch.value
    }

    staps.value.push(newStap)
}

const deleteStap = (id) => {
    staps.value = [... staps.value.filter(i => i.id != id)]
}

const setMillisecond = () => {
    interval.value = setInterval(() => {
        millisecond.value += 40
        if (millisecond.value >= 999) {
            millisecond.value = Math.floor(Math.random() * 10)
        }
    }, 40);
}


const getMilisecondString = () => {
    let string = ""
    if (millisecond.value <= 9) {
        string = "00"
    }else{
        if (millisecond.value <= 99) {
            string = "0"
        }
    }
    return `${string}${millisecond.value}`
}

</script>
