<template>
    <TransitionRoot as="template" :show="isModalOpen">
        <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-500" enter-from="opacity-0"
                                 enter-to="opacity-100"
                                 leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-black bg-opacity-75 transition-opacity"/>
                </TransitionChild>

                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300"
                                 enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                 enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                 leave-from="opacity-100 translate-y-0 sm:scale-100"
                                 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="sm:max-w-2xl w-full inline-block  sm:align-middle p-2 sm:p-4 bg-white rounded-lg shadow-xl transform transition-all border border-gray-990">
                        <div class="flex flex-col sm:flex-row">
                            <div class="flex-shrink-0 flex justify-center mb-2 sm:mb-0">
                                <img class="w-24 sm:w-44" src="/img/modal/teachers_day.gif" alt="" />
                            </div>
                            <div class="text-xs sm:text-sm">
                                <type-writer :content="message" stop-blinking="true"
                                             @callback="() => isCloseButtonShown = true">
                                </type-writer>
                            </div>
                        </div>
                        <button v-show="isCloseButtonShown" class="mt-2 btn btn-sm" @click="isModalOpen = false">
                            Kapat
                        </button>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import {ref} from 'vue'
import {Dialog, DialogOverlay, TransitionChild, TransitionRoot} from '@headlessui/vue'
import TypeWriter from "../../utils/TypeWriter.vue";

const props = defineProps(['name']);

const isModalOpen = ref(true);
const isCloseButtonShown = ref(false);

const message = props.name + ' öğretmenim, öğrencilerinizin faydası için ekstra çalışmalar yapmaya var gücünüzle devam ediyor, ' +
    'öğrencilerinize nasıl daha faydalı olabileceğinizi düşünerek Derslig\'i ziyaret ediyorsunuz. Emekleriniz için tüm ' +
    'öğrencilerimiz, velilerimiz, hatta tüm ülke adına teşekkürü bir borç biliyor, öğretmenler gününüzü en içten ' +
    'dileklerimizle kutluyoruz. Derslig olarak en büyük isteğimiz bu yoğun ve özverili çalışmalarınıza destek olabilmek. ' +
    'İyi ki varsınız ' + props.name + ' Öğretmenim!';
</script>
