<template>
    <div class="bg-white p-2 rounded-xl">
        <div class="mb-2 text-gray-960 font-bold text-center text-xs md:text-sm">{{ label }}</div>
        <div v-if="props.filterData.length" class="text-right">
            <select v-model="selectedFilterValue" class="form-control" @change="getData">
                <option value="">{{ props.filterDefaultOption }}</option>
                <option :key="filterDatum.id" v-for="filterDatum in props.filterData" :value="filterDatum.id">
                    {{ filterDatum.name }}
                </option>
            </select>
        </div>
        <div v-if="isLoading" class="mt-2">
            <div class="w-full h-full flex items-center justify-center">
                <clip-loader :color="'#03B0B9'" :size="'24px'"></clip-loader>
            </div>
        </div>
        <template v-else-if="isLoaded">
            <div class="flex justify-center">
                <div class="w-1/3 xs:w-1/2">
                    <Doughnut :data="chartData" :options="chartOptions" :plugins="chartPlugins" :key="chartVersion" />
                </div>
            </div>
        </template>
        <div v-else class="mt-2 text-center text-xs text-secondary">
            Veri yok
        </div>
    </div>
</template>

<script setup>
    import { Doughnut } from 'vue-chartjs';
    import { Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
    import {onMounted, ref, reactive} from "vue";
    import ClipLoader from "vue-spinner/src/ClipLoader.vue";

    ChartJS.register(ArcElement, Tooltip, Legend)

    const props = defineProps({
        url: {type: String, required: true},
        label: {type: String, default: ''},
        drawCenter: {type: Boolean, default: false},
        drawFirstData: {type: Boolean, default: false},
        filterData: {type: Array, default: []},
        filterKey: {type: String, default: ''},
        filterDefaultOption: {type: String, default: ''}
    });

    const isLoaded = ref(false);
    const isLoading = ref(false);
    const selectedFilterValue = ref('');
    const chartVersion = ref(0);

    const chartData = reactive({
        labels: [],
        datasets: [{
            backgroundColor: [
                'rgba(196, 69, 105, 1)',
                'rgba(200, 200, 200)'
            ],
            data: []
        }]
    });

    let chartOptions = {
        cutout: '60%',
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            }
        }
    };

    let chartPlugins = [];

    if (props.drawCenter) {
        chartPlugins.push({
            id: 'centerText',
            beforeDraw(chart, args, options) {
                const {ctx, chartArea: {top, right, bottom, left, width, height}} = chart;

                ctx.textBaseline = 'middle';
                ctx.textAlign = 'center';

                if (props.drawFirstData) {
                    ctx.fillStyle = '#03B0B9';
                    ctx.font = ' ' + Math.floor(height * 0.18) + 'px "Arial Black"';
                    ctx.fillText('%' + chart.data.datasets[0].data[0], width / 2, height * 0.52);
                }
            }
        });
    }

    onMounted( () => {
        getData();
    });

    const getData = () => {
        isLoading.value = true;

        chartData.labels = [];
        chartData.datasets[0].data = [];

        let params = {};

        if (selectedFilterValue.value) {
            params.school_level_id = selectedFilterValue.value;
        }

        axios.get(props.url, {
            params: params
        }).then((response) => {
            if (response.data.error === "") {
                if (response.data.data && Object.values(response.data.data)[0] !== null) {
                    Object.entries(response.data.data).forEach(entry => {
                        const [key, value] = entry;

                        chartData.labels.push(key);
                        chartData.datasets[0].data.push(value);
                        chartData.datasets[0].data.push(100-value);
                    });

                    isLoaded.value = true;
                }
            }
        }).catch((error) => {
        }).then(() => {
            isLoading.value = false;
            chartVersion.value++;
        });
    }
</script>
