<template>
    <div>
        <div class="w-full flex space-x-2 items-center justify-between p-4">
            <div class="text-xl font-bold ">Ayarlar</div>
            <button  class="bg-secondary px-4 py-2 cursor-pointer hover:bg-primary-light rounded-lg text-sm text-white" @click="setScreen">Kapat</button>
        </div>


        <div>
            <div class="flex justify-between p-3 border-b" v-for="o, key in options" :key="key">
                    <div class="w-full flex flex-col space-y-1 justify-center border-gray-200" v-if="o.visible">
                        <div class="text-bold">{{o.title}}</div>
                        <div class="text-xs text-gray-800">{{o.description}}</div>
                    </div>
                    <div>
                        <ValueSelect :obj="o" />
                    </div>
                </div>
        </div>

    </div>
</template>

<script setup>
import { defineProps, defineEmits} from 'vue';
import ValueSelect from '../other/valueSelect.vue'

const emit = defineEmits(['screen'])
const props = defineProps(['options'])


const setScreen = () => {
    emit('screen', 'grade')
}
</script>

