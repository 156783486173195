<template>
    <div class="xs:ml-4 xs:mt-0 flex flex-col justify-between items-center">
        <div class="w-36 flex items-center justify-between space-x-2">
            <div v-if="showScore" class="font-bold">
                {{ ratingScore }}
            </div>
            <div class="flex space-x-1">
                <AnFilledStar :key="i" v-for="i in maxStars"  class="w-4 h-4 fill-current"  @click="props.canRate && rate(i)" viewBox="0 0 10 9.286"
                     :class="[(ratingScore >= i) ? 'text-yellow-default' : 'text-gray-700', props.canRate ? 'cursor-pointer' : '']"
                     @mouseover="isFilled = i" @mouseleave="isFilled = false">
                </AnFilledStar>
            </div>
        </div>
        <div v-if="showCount" class="font-semibold text-xs text-gray-960 flex-shrink-0">
            {{ ratingCount }} Değerlendirme
        </div>
        <div v-show="isLoading" class="absolute w-full h-full top-1/2 left-1/2 text-center bg-black bg-opacity-10 z-10" style="transform: translate(-50%,-50%)">
            <div class="w-full h-full flex items-center justify-center">
                <pulse-loader :color="'#03B0B9'" :size="'12px'"></pulse-loader>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {ref, watch} from "vue";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import { AnFilledStar } from "@kalimahapps/vue-icons";

    const props = defineProps({
        content: {
            type: Object
        },
        ratingScore: {
            type: Number,
            default: 0,
        },
        ratingCount: {
            type: Number,
            default: 0,
        },
        canRate: {
            type: Boolean,
            default: false,
        },
        showScore: {
            type: Boolean,
            default: false,
        },
        showCount: {
            type: Boolean,
            default: false,
        },
        maxStars: {
            type: Number,
            default: 5,
        }
    });
    const isLoading = ref(false);
    const isFilled = ref(false);
    const ratingScore = ref(props.ratingScore);
    const ratingCount = ref(props.ratingCount);
    const tempRatingScore = ref(props.ratingScore);

    const fillStars = (score) => {
        tempRatingScore.value = ratingScore.value;
        ratingScore.value = score;
    }

    const exitFillStars = () => {
        ratingScore.value = tempRatingScore.value;
    }

    const rate = (score) => {
        isLoading.value = true;

        axios.post('/api/oyla', {
            contentType: props.content.type,
            contentId: props.content.id,
            score: score
        }).then((response) => {
            if (response.data) {
                if (isFilled.value) {
                    tempRatingScore.value = roundFloat(response.data.data.ratingScore);
                } else {
                    ratingScore.value = roundFloat(response.data.data.ratingScore);
                }

                ratingCount.value = response.data.data.ratingCount;
            }
        }).catch((error) => {
            isLoading.value = false;

            if (error && error.response && error.response.data && error.response.data.error) {
                alert(error.response.data.error);
            } else {
                alert("Hata oluştu!");
            }
        }).then(() => {
            isLoading.value = false;
        });
    }

    watch(isFilled, (isFilled) => {
        if (props.canRate) {
            if (isFilled) {
                fillStars(isFilled)
            } else {
                exitFillStars();
            }
        }
    });

    const roundFloat = (n) => {
        return Math.round((n + Number.EPSILON) * 100) / 100;
    }
</script>
