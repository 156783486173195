<template>
    <svg class="scale-150" x="0px" y="0px" viewBox="0 0 366.2 366.2">
        <g>
            <g>
                <circle style="fill:none;stroke:#FFFFFF;stroke-width:21;" cx="185.4" cy="183.1" r="166" />
                <path style="fill:none;stroke:#FFFFFF;stroke-width:14;" d="M157.9,137.8c0,16.3-13,29.4-29,29.4c-15.9,0-29-13.1-29-29.4s13-29.4,29-29.4
		C144.8,108.3,157.9,121.4,157.9,137.8z" />
                <circle style="fill:none;stroke:#FFFFFF;stroke-width:14;"  cx="246.1" cy="137.8" r="29.4" />
            </g>
            <path style="fill:none;stroke:#FFFFFF;stroke-width:21;" d="M99.9,265.2c0,0,29-40.5,87.8-40.5c58.4,0,87.8,40.5,87.8,40.5" />
        </g>
    </svg>
</template>

<script>
export default {
    name: "SadIcon"
}
</script>
