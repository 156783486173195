document.addEventListener('DOMContentLoaded', function(){
    let tabContainers = document.querySelectorAll(".landing-tabs");

    if (tabContainers) {
        tabContainers.forEach(function(tabContainer) {
            let tabTogglers = tabContainer.querySelectorAll(".tablinks");

            tabTogglers.forEach(function(toggler) {
                toggler.addEventListener("mouseover", function(e) {
                    e.preventDefault();

                    let tabName = toggler.dataset.href;
                    let tabContents = tabContainer.parentNode.querySelector(".tab-contents");
                    let tabContentCount = tabContents.children.length;

                    for (let i = 0; i < tabContentCount; i++) {
                        tabTogglers[i].classList.remove("active");
                        tabContents.children[i].classList.remove("active");

                        if ("#" + tabContents.children[i].id === tabName) {
                            tabContents.children[i].classList.add("active");
                        }
                    }

                    this.classList.add("active");
                });
            });

            tabContainer.querySelector(".tablinks").dispatchEvent(new MouseEvent('mouseover', { 'bubbles': true }));
        });
    }
});
