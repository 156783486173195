async function track() {
    let trackCookieName = 'derslig_analytics_tracked';
    let trackMinutes = 60;

    if (window._analyticsUrl !== undefined && window._page !== undefined) {
        let p = window._page;
        let url = window._analyticsUrl;

        if (
            !CookieManager.getCookie(trackCookieName)
            || p === 'pro'
            || p.indexOf('siparis/') === 0
            || p.indexOf('kurslar/icerik/') === 0
            || p.indexOf('kurslar/test/') === 0
            || p.indexOf('ek-paketler/') === 0
            || p.indexOf('sinif-yonetimi') === 0
        ) {
            axios.post(url, {
                page: p
            }).then(() => {
                CookieManager.setCookie(trackCookieName, 1, trackMinutes);
            }).catch((error) => {
            });
        }
    }
}

export { track }
