const cookieConsentConfig = {
    guiOptions: {
        consentModal: {
            layout: 'box',
            position: 'bottom left',
            equalWeightButtons: false
        },
        preferencesModal: {
            layout: 'box',
            equalWeightButtons: false
        }
    },

    onFirstConsent: () => {
    },

    onConsent: () => {
    },

    onChange: () => {
    },

    categories: {
        necessary: {
            readOnly: true,
            enabled: true,
        },
        analytics: {
            enabled: true,
            services: {
                ga: {
                    label: 'Google Analytics',
                    onAccept: () => {
                        // enable ga
                        // import '../thirdPartyLibraries/ga.js'
                    },
                    onReject: () => {
                        // disable ga
                    },
                    cookies: [
                        {
                            name: /^(_ga|_gid)/
                        }
                    ]
                },
                ym: {
                    label: 'Yandex Metrica',
                    onAccept: () => {
                        // enable ym
                        // import '../thirdPartyLibraries/ym.js'
                    },
                    onReject: () => {
                        // disable ym
                    },
                    cookies: [
                        {
                            name: /^(_ym)/
                        }
                    ]
                },
                tt: {
                    label: 'Tiktok',
                    onAccept: () => {
                        // enable tt
                        // import '../thirdPartyLibraries/tt.js'
                    },
                    onReject: () => {
                        // disable tt
                    },
                    cookies: [
                        {
                            name: /^(_tt)/
                        }
                    ]
                },
            },
        },
        ads: {
            enabled: true,
            services: {
                fp: {
                    label: 'Facebook Pixel',
                    onAccept: () => {
                        // enable fp
                    },
                    onReject: () => {
                        // disable fp
                    },
                    cookies: [
                        {
                            name: /^(_fbp)/
                        }
                    ]
                },
            }
        },
    },

    language: {
        default: 'tr',
        autoDetect: 'browser',

        translations: {
            en: {
                consentModal: {
                    title: "This website uses cookies",
                    description: 'We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.',
                    acceptAllBtn: 'Allow All',
                    acceptNecessaryBtn: 'Allow only necessary cookies',
                    showPreferencesBtn: 'Manage preferences',
                    closeIconLabel: 'Deny all and close',
                    footer: `
                      <a target="_blank" href="/cerez-politikasi">Cookie Policy</a>
                    `,
                },
                preferencesModal: {
                    title: 'Cookie preferences',
                    acceptAllBtn: 'Accept all',
                    acceptNecessaryBtn: 'Reject all',
                    savePreferencesBtn: 'Save preferences',
                    sections: [
                        {
                            title: 'Cookie usage',
                            description: 'You can manage the cookies used on our site here.',
                        },
                        {
                            title: 'Strictly necessary cookies',
                            description: 'Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.',
                            linkedCategory: 'necessary',
                            cookieTable: {
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Service',
                                    desc: 'Description',
                                    expiration: 'Expiration'
                                },
                                body: [
                                    {
                                        name: 'derslig_cookie',
                                        domain: 'Derslig',
                                        desc: 'Manages authentication on the website',
                                        expiration: '4 months'
                                    },
                                    {
                                        name: 'XSRF-TOKEN',
                                        domain: 'Derslig',
                                        desc: 'Manages authentication on the website',
                                        expiration: '4 months'
                                    },
                                    {
                                        name: 'cc_cookie',
                                        domain: 'Derslig',
                                        desc: 'Stores cookie preferences.',
                                        expiration: '6 months'
                                    },
                                    {
                                        name: 'derslig_analytics_tracked',
                                        domain: 'Derslig',
                                        desc: 'Used for analytics is tracked.',
                                        expiration: '1 hour'
                                    },
                                    {
                                        name: 'dersligGuestWelcomeModalv1',
                                        domain: 'Derslig',
                                        desc: 'Manages guest welcome modal',
                                        expiration: '1 day'
                                    },
                                ],
                            },
                        },
                        {
                            title: 'Performance and analytics cookies',
                            description: 'This cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Service',
                                    desc: 'Description',
                                    expiration: 'Expiration'
                                },
                                body: [
                                    {
                                        name: '_ga',
                                        domain: 'Google Analytics',
                                        desc: 'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.',
                                        expiration: "2 years"
                                    },
                                    {
                                        name: '_ga_',
                                        domain: 'Google Analytics',
                                        desc: 'Used by Google Analytics to collect data on the number of times a user has visited the website as well as dates for the first and most recent visit.',
                                        expiration: '2 years'
                                    },
                                    {
                                        name: '_gid',
                                        domain: 'Google Analytics',
                                        desc: 'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.',
                                        expiration: '1 day'
                                    },
                                    {
                                        name: '_ym_d',
                                        domain: 'Yandex Metrica',
                                        desc: 'Used by Yandex Metrica to collect data about the first and last dates of a user\'s visit to the website in order to produce statistical data on how the visitor uses the website.',
                                        expiration: '1 year'
                                    },
                                    {
                                        name: '_ym_uid',
                                        domain: 'Yandex Metrica',
                                        desc: 'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.',
                                        expiration: '1 year'
                                    },
                                    {
                                        name: 'ttwid',
                                        domain: 'Tiktok',
                                        desc: 'Tiktok sets this cookie to keep track of user authentication and session information.',
                                        expiration: '1 year'
                                    }
                                ],
                            },
                        },
                        {
                            title: 'Advertisement and targeting cookies',
                            description: 'Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.\n',
                            linkedCategory: 'ads',
                            cookieTable: {
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Service',
                                    desc: 'Description',
                                    expiration: 'Expiration'
                                },
                                body: [
                                    {
                                        name: '_fbp',
                                        domain: 'Facebook Pixel',
                                        desc: 'Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.',
                                        expiration: '3 months'
                                    },
                                ],
                            },
                        },
                        {
                            title: 'More information',
                            description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc__link" href="mailto:iletisim@derslig.com">contact us</a>.',
                        },
                    ],
                },
            },
            tr: {
                consentModal: {
                    title: "Çerez Tercihleri",
                    description: 'İçerik ve reklamları kişiselleştirmek, sosyal medya özellikleri sağlamak ve trafiğimizi analiz etmek için çerezler kullanırız. Ayrıca sitemizi kullanımınızla ilgili bilgileri, bunları kendilerine sağladığınız veya hizmetlerini kullanımınızdan topladıkları diğer bilgilerle birleştirebilecek sosyal medya, reklamcılık ve analiz ortaklarımızla paylaşırız.',
                    acceptAllBtn: 'Hepsine izin ver',
                    acceptNecessaryBtn: 'Gerekli olanlara izin ver',
                    showPreferencesBtn: 'Tercihleri yönet',
                    closeIconLabel: 'Hepsini engelle ve kapat',
                    footer: `
                      <a target="_blank" href="/cerez-politikasi">Çerez Politikası</a>
                  `,
                },
                preferencesModal: {
                    title: 'Çerez Tercihleri',
                    acceptAllBtn: 'Hepsine izin ver',
                    acceptNecessaryBtn: 'Hepsini reddet',
                    savePreferencesBtn: 'Terichleri kaydet',
                    sections: [
                        {
                            title: 'Çerez kullanımı',
                            description: 'Sitemizde kullanılan çerezleri buradan yönetebilirsiniz.',
                        },
                        {
                            title: 'Gerekli çerezler',
                            description: 'Gerekli çerezler, sayfada gezinme ve web sitesinin güvenli alanlarına erişim gibi temel işlevleri etkinleştirerek bir web sitesinin kullanılabilir olmasına yardımcı olur. Bu çerezler olmadan web sitesi düzgün bir şekilde çalışamaz.',
                            linkedCategory: 'necessary',
                            cookieTable: {
                                headers: {
                                    name: 'Çerez',
                                    domain: 'Servis',
                                    desc: 'Açıklama',
                                    expiration: 'Geçerlilik süresi'
                                },
                                body: [
                                    {
                                        name: 'derslig_cookie',
                                        domain: 'Derslig',
                                        desc: 'Sitedeki kullanıcı oturumunu yönetir',
                                        expiration: '4 ay'
                                    },
                                    {
                                        name: 'XSRF-TOKEN',
                                        domain: 'Derslig',
                                        desc: 'Sitedeki kullanıcı oturumunu yönetir',
                                        expiration: '4 ay'
                                    },
                                    {
                                        name: 'cc_cookie',
                                        domain: 'Derslig',
                                        desc: 'Çerez tercihlerine verilen izinleri depolar.',
                                        expiration: '6 ay'
                                    },
                                    {
                                        name: 'derslig_analytics_tracked',
                                        domain: 'Derslig',
                                        desc: 'Derslig analitik verileri için kullanılır.',
                                        expiration: '1 saat'
                                    },
                                    {
                                        name: 'dersligGuestWelcomeModalv1',
                                        domain: 'Derslig',
                                        desc: 'Ziyaretçiler henüz üye olmadığında gösterilen modalı yönetir.',
                                        expiration: '1 gün'
                                    },
                                ],
                            },
                        },
                        {
                            title: 'Performans ve veri analitiği çerezleri',
                            description: 'Bu çerezler, web sitesi sahiplerinin, bilgileri anonim olarak toplayıp raporlayarak ziyaretçilerin web siteleriyle nasıl etkileşim kurduğunu anlamalarına yardımcı olur.',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                headers: {
                                    name: 'Çerez',
                                    domain: 'Servis',
                                    desc: 'Açıklama',
                                    expiration: 'Geçerlilik süresi'
                                },
                                body: [
                                    {
                                        name: '_ga',
                                        domain: 'Google Analytics',
                                        desc: 'Ziyaretçinin web sitesini nasıl kullandığına ilişkin istatistiksel veriler üretmek için kullanılan benzersiz bir kimlik kaydeder.',
                                        expiration: '2 yıl'
                                    },
                                    {
                                        name: '_ga_',
                                        domain: 'Google Analytics',
                                        desc: 'Google Analytics tarafından bir kullanıcının web sitesini kaç kez ziyaret ettiği, ilk ve son ziyaret tarihleri hakkında veri toplamak için kullanılır.',
                                        expiration: '2 yıl'
                                    },
                                    {
                                        name: '_gid',
                                        domain: 'Google Analytics',
                                        desc: 'Ziyaretçinin web sitesini nasıl kullandığına ilişkin istatistiksel veriler üretmek için kullanılan benzersiz bir kimlik kaydeder.',
                                        expiration: 'Oturum'
                                    },
                                    {
                                        name: '_ym_d',
                                        domain: 'Yandex Metrica',
                                        desc: 'Ziyaretçinin web sitesini nasıl kullandığına ilişkin istatistiksel verilen üretmek için Yandex Metrica tarafından bir kullanıcının web sitesini ilk ve son ziyaret tarihleri hakkında veri toplamak için kullanılır.',
                                        expiration: '1 yıl'
                                    },
                                    {
                                        name: '_ym_uid',
                                        domain: 'Yandex Metrica',
                                        desc: 'Ziyaretçinin web sitesini nasıl kullandığına ilişkin istatistiksel veriler üretmek için kullanılan benzersiz bir kimlik kaydeder.',
                                        expiration: '1 yıl'
                                    },
                                    {
                                        name: 'ttwid',
                                        domain: 'Tiktok',
                                        desc: 'TikTok, kullanıcı kimlik doğrulamasını ve oturum bilgilerini takip etmek için bu çerezi ayarlar.',
                                        expiration: '1 year'
                                    }
                                ],
                            },
                        },
                        {
                            title: 'Pazarlama ve hedefleme çerezleri',
                            description: 'Pazarlama çerezleri, ziyaretçileri web siteleri arasında izlemek için kullanılır. Amaç, bireysel kullanıcı için alakalı ve ilgi çekici reklamlar göstermek ve dolayısıyla yayıncılar ve üçüncü taraf reklamverenler için daha değerli olmaktır.',
                            linkedCategory: 'ads',
                            cookieTable: {
                                headers: {
                                    name: 'Çerez',
                                    domain: 'Servis',
                                    desc: 'Açıklama',
                                    expiration: 'Geçerlilik süresi'
                                },
                                body: [
                                    {
                                        name: '_fbp',
                                        domain: 'Facebook Pixel',
                                        desc: 'Facebook tarafından üçüncü taraf reklamverenlerin gerçek zamanlı teklif verme gibi bir dizi reklam ürününü sunmak için kullanılır.',
                                        expiration: '3 ay'
                                    },
                                ],
                            },
                        },
                        {
                            title: 'Daha fazla bilgi',
                            description: 'Çerezler ve tercihlerinizle ilgili politikamızla ilgili tüm sorularınız için lütfen <a class="cc__link" href="mailto:iletisim@derslig.com"> bizimle iletişime geçin.</a>.',
                        },
                    ],
                },
            },
        },
    },
};

export {cookieConsentConfig}
