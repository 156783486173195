document.addEventListener('DOMContentLoaded', function(){
    let tabSelects = document.querySelectorAll("select.tabs");

    if (tabSelects) {
        tabSelects.forEach(function(tabSelect) {
            let tabSource = tabSelect.dataset.source;
            let tabContents = document.getElementById(tabSource);

            tabSelect.addEventListener("change", function(e) {
                e.preventDefault();

                let tabName = this.value;

                for (let i = 0; i < tabContents.children.length; i++) {
                    tabContents.children[i].classList.remove("active");

                    if (tabContents.children[i].id === tabName) {
                        tabContents.children[i].classList.add("active");
                    }
                }
            });

            tabSelect.dispatchEvent(new Event('change'));
        });
    }
});
