export function initMagnificPopup() {
    $.extend(true, $.magnificPopup.defaults, {
        tClose: 'Kapat (Esc)',
        tLoading: 'Yükleniyor...',
        gallery: {
            tPrev: 'Önceki',
            tNext: 'Sonraki',
            tCounter: '%curr% / %total%'
        },
        image: {
            tError: '<a href="%url%">Görsel</a> yüklenemedi.'
        },
        ajax: {
            tError: '<a href="%url%">İçerik</a> yüklenemedi.'
        },
        callbacks: {
            open: function() {
                let zoomPercent = "100";

                $(".mfp-figure figure").css("cursor", "zoom-in").click(function(){
                    switch (zoomPercent) {
                        case "100":
                            zoomPercent = "200";
                            $(this).css("cursor", "zoom-in");
                            break;
                        case "200":
                            zoomPercent = "300";
                            $(this).css("cursor", "zoom-out");
                            break;
                        case "300":
                            zoomPercent = "100";
                            $(this).css("cursor", "zoom-in");
                            break;
                    }

                    $(this).css("zoom", zoomPercent+"%");
                });
            }
        }
    });
}
export function initYoutubeMagnificPopups() {
    $(function () {
        $('.magnific-popup-youtube').each(function(){
            $(this).magnificPopup({
                type: 'iframe',
                iframe: {
                    markup: '<div class="mfp-iframe-scaler">' +
                        '<div class="mfp-close"></div>' +
                        '<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
                        '</div>',
                    patterns: {
                        youtube: {
                            index: 'youtube.com/',
                            id: 'v=',
                            src: 'https://www.youtube.com/embed/%id%?autoplay=1'
                        }
                    }
                }
            });
        });
    });
}
