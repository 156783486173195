<template>
    <div class="p-4 bg-white rounded-xl">
        <div class="mb-4">
            <button type="button" class="btn" @click="showQuestionManageModal()">
                Yeni Soru
            </button>
        </div>
        <div v-for="item in listData"
             class="mb-2 p-4 w-full h-auto flex flex-col sm:flex-row justify-between sm:items-center bg-gray-200 rounded-xl">
            <div class="flex flex-col xs:flex-row xs:items-center">
                <div class="mr-4 flex justify-center">
                    <Toggle :id="item.id" :checked="item.published" @publish="publish"></Toggle>
                </div>
                <div class="mt-4 xs:mt-0 flex flex-col">
                    <div class="font-bold text-base mb-1 line-clamp-1">
                        <a :href="item.image_src" class="inline-block cursor-zoom-in" target="_blank">
                            <img class="h-16" :src="item.image_src"/>
                        </a>
                    </div>
                    <div class="font-medium text-xs text-gray-960 opacity-70">
                        <div class="font-bold text-990">
                            <span class="mr-0.5">{{ item.grade.name }}</span>
                            <span class="mr-0.5">·</span>
                            <span class="mr-0.5">{{ item.course.name }}</span>
                            <span class="mr-0.5">·</span>
                            <span>{{ item.unit.name }}</span>
                        </div>
                        <div>
                            {{ item.learning_outcome.text }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4 sm:mt-0 flex flex-shrink-0 items-center">
                <div class="mr-2 p-3 bg-primary-500 rounded-lg cursor-pointer" @click="showEditModal(item)">
                    <svg class="w-4 h-4 text-white stroke-current stroke-2" viewBox="0 0 24 24" fill="none">
                        <path d="M12 20h9"></path>
                        <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                    </svg>
                </div>
                <div class="mr-2 p-3 bg-primary-500 rounded-lg cursor-pointer" @click="deleteQuestion(item)">
                    <svg class="w-4 h-4 text-white stroke-current stroke-2" viewBox="0 0 24 24" fill="none">
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                </div>
            </div>
        </div>
    </div>
    <QuestionManageModal ref="questionManageModalRef" @getQuestions="getQuestions"></QuestionManageModal>
    <div v-show="isLoading" class="fixed w-full h-full top-1/2 left-1/2 text-center bg-black bg-opacity-10 z-10" style="transform: translate(-50%,-50%)">
        <div class="w-full h-full flex items-center justify-center">
            <pulse-loader :color="'#03B0B9'" :size="'12px'"></pulse-loader>
        </div>
    </div>
</template>

<script setup>
    import {onMounted, ref} from "vue";
    import QuestionManageModal from "./QuestionManageModal.vue";
    import Toggle from "../../components/form/Toggle.vue"
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

    const props = defineProps(['grades']);
    const isLoading = ref(false);
    const questionManageModalRef = ref(null);

    const listData = ref([]);

    onMounted(() => {
        questionManageModalRef.value.grades = props.grades;
        getQuestions();
    });

    const getQuestions = async () => {
        isLoading.value = true;

        let response = await axios.get('/icerik-yonetimi/profesor/listele');
        listData.value = response.data;

        isLoading.value = false;
    }

    const showQuestionManageModal = () => {
        questionManageModalRef.value.isModalOpen = true;
    }

    const showEditModal = (item) => {
        const question = {
            id: item.id,
            questionImageUrl: item.image_src,
            correctAnswer: item.correct_answer,
            widthType: item.width_type,
            difficulty: item.difficulty,
            solutionImageUrl: item.solution_image_src,
            solutionVideoUrl: item.solution_video_url,
            solutionText: item.solution_text,
            hint: item.hint,
            isChanged: false,
        };

        questionManageModalRef.value.selectedGradeId = item.grade.id;
        questionManageModalRef.value.getCourses(item.course.id, item.unit.id, item.learning_outcome.id);

        questionManageModalRef.value.questions = [...questionManageModalRef.value.questions, question];

        questionManageModalRef.value.isEditMode = true;
        questionManageModalRef.value.isModalOpen = true;
    }

    const deleteQuestion = (item) => {
        if (confirm('Soruyu silmek istediğinize emin misiniz?')) {
            axios.post('/icerik-yonetimi/profesor/sil/' + item.id).then(response => {
                isLoading.value = false;

                if (response.data.error === "") {
                    getQuestions();
                }
            }).catch(e => {
                isLoading.value = false;

                let message = '';

                if (!e.response) {
                    message = 'Lütfen internet bağlantınızı kontrol ediniz!';
                } else {
                    message = 'Bir hata oluştu! Lütfen tekrar deneyin.';
                }

                alert(message);
            });
        }
    }

    const publish = (id, value) => {
        axios.post('/icerik-yonetimi/profesor/yayinla/' + id, {
                'value': value
        }).then(response => {
            isLoading.value = false;

            if (response.data.error === "") {

            }
        }).catch(e => {
            isLoading.value = false;

            let message = '';

            if (!e.response) {
                message = 'Lütfen internet bağlantınızı kontrol ediniz!';
            } else {
                message = 'Bir hata oluştu! Lütfen tekrar deneyin.';
            }

            alert(message);
        });
    }
</script>
