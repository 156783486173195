function getCookie(name) {
    var cookies = document.cookie ? document.cookie.split('; ') : []

    for (let i = 0; i < cookies.length; ++i) {
        let parts = cookies[i].split('=');
        var found = decodeURIComponent(parts[0]);

        if (name === found) {
            return read(parts.slice(1).join('='));
        }
    }

    return false;
}

function setCookie(name, value, minutes, path = '/') {
    if (typeof document === 'undefined') {
        return;
    }

    var expDate = new Date();
    expDate.setTime(expDate.getTime() + (minutes * 60 * 1000));

    name = encodeURIComponent(name)
        .replace(/%(2[346B]|5E|60|7C)/g, decodeURIComponent)
        .replace(/[()]/g, escape);

    document.cookie = name + '=' + write(value) + ';expires=' + expDate.toUTCString() + ';domain=' + getCookieDomain() + ';path=' + path;
}

function deleteCookie(name, domain, path = '/') {
    if (typeof document === 'undefined' || typeof domain === 'undefined') {
        return;
    }
    document.cookie = name + '=; domain=' + domain + '; path=' + path + '; expires=Sun, 09 Apr 1970 11:00:00 UTC;';
}

function getCookieDomain() {
    return "." + location.hostname.split('.').reverse()[1] + "." + location.hostname.split('.').reverse()[0];
}

function read(value) {
    if (value[0] === '"') {
        value = value.slice(1, -1)
    }
    return value.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent)
}

function write(value) {
    return encodeURIComponent(value).replace(
        /%(2[346BF]|3[AC-F]|40|5[BDE]|60|7[BCD])/g,
        decodeURIComponent
    )
}

export {getCookie, setCookie, deleteCookie, getCookieDomain}
