<template>
    <div class="p-4 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4" v-if="selected || props.data.show">
        <div class="w-full flex flex-col md:flex-row space-y-2 md:items-center md:justify-between font-medium text-sm text-gray-900">
            <div class="flex items-center">
                <img class="fill-current w-12 h-12" :src="imageUrlPrefix + props.data.type_id + '.svg'" :alt="typeName" />
                <span class="ml-2">{{ props.data.title }}</span>
            </div>
            <textarea v-if="selected" class="md:ml-4 w-auto xl:w-64 shrink-0 form-control" placeholder="Ödev açıklaması"></textarea>
        </div>
        <div v-if="props.selected === 0" class="flex items-center shrink-0">
            <a target="_blank" class="btn btn-sm btn-yellow mr-2" :href="props.data.url">İncele</a>
            <button type="button" class="btn btn-sm" @click="$emit('onTaskSelected', props.data)">Ekle</button>
        </div>
        <div v-else class="flex items-center shrink-0">
            <button :class="[props.data.type_group_id !== 2 || (props.data.type_id !== 2 && props.data.type_id !== 7) ? 'invisible hidden md:block' : '']" type="button" class="btn btn-sm btn-gray mr-2" @click="settingsModal.isModalOpen = true">Ayarlar</button>
            <a target="_blank" class="btn btn-sm btn-yellow mr-2" :href="props.data.url">İncele</a>
            <button type="button" class="btn btn-sm" @click="$emit('onTaskDeselected', props.data)">Çıkar</button>
        </div>
        <SettingsModal ref="settingsModal" @onSaved="onSaved"></SettingsModal>
    </div>
</template>

<script setup>
    import SettingsModal from "./SettingsModal.vue";
    import {ref} from "vue";

    const props = defineProps(['data', 'selected']);
    const emit = defineEmits(['onTaskSelected', 'onTaskDeselected', 'onTaskSettingsUpdated']);

    const settingsModal = ref(null);

    let imageUrlPrefix = '';
    let typeName = '';

    if (props.data.type_group_id === 4) {
        imageUrlPrefix = '/img/courses/types/';
        typeName = 'Konu Anlatımı ve İnteraktif Etkinlikler';
    } else if (props.data.type_group_id === 5) {
        imageUrlPrefix = '/img/tests/types/';
        typeName = 'Soru Seti';
    } else if (props.data.type_group_id === 2) {
        imageUrlPrefix = '/img/documents/types/';
        typeName = props.data.type.name;
    }
    const onSaved = (settings) => {
        emit('onTaskSettingsUpdated', props.data, settings);
    }
</script>
