<template>
    <div class="flex flex-col  relative">

        <!--Hata-->
        <div class="bg-secondary text-white p-4 rounded-lg mt-4" v-if="(classManagementError || error) && !selected_tool">
             <div v-if="classManagementError">{{ classManagementError }}</div>
             <div v-if="error">{{ error }}</div>
        </div>

        <!--header-->
        <div class="flex justify-between items-center mt-4" v-if="!selected_tool">
            <!--left-->
            <div class="w-full">
                <!--loading-->
                <div class="w-full flex space-x-2 py-1 px-2 bg-gray-300 rounded-lg" v-if="classManagementLoading && isLoading">
                    <div class="bg-gray-400 px-4 py-2 cursor-pointer opacity-80 h-11 w-20 rounded-lg" v-for="l in 5" :key="l"></div>
                </div>


                <!-- Sınıf sayısı az ise burası devreye girecek -->
                <div class="flex w-full space-x-2 px-3" v-if="grades.length > 0 && grades.length < gradeMenuLimit + 1 && !(classManagementLoading && isLoading)">
                    <div class="rounded-full flex items-center justify-center px-4 py-2 cursor-pointer  font-bold border-2"
                    v-for="g, key in grades" :key="key"
                    @click="!classManagementLoading && !g?.school && (selected_grade = {key, ...g})"
                    :class="selected_grade.key == key ? 'bg-primary-default text-white border-primary-default' : 'text-black border-gray-600'"
                    >
                        {{ g.sectionName }}
                    </div>
                </div>

                <!-- Sınıf sayısı fazla ise burası devreye girecek -->
                <div class="flex flex-col space-y-2" v-if="grades.length > gradeMenuLimit && !(classManagementLoading && isLoading)">

                    <div class="flex w-full space-x-2 px-3">
                        <div class="rounded-full flex items-center justify-center px-4 py-2 cursor-pointer  font-bold border-2"
                        v-for="g, key in getGradeSize" :key="key"
                        @click="selectGradeMenuIndex = g"
                        :class="selectGradeMenuIndex == g ? 'bg-primary-default text-white border-primary-default' : 'text-black border-gray-600'"
                        >
                            {{ g }}. Sınıf
                        </div>
                    </div>

                    <div class="flex w-full space-x-2 px-3">
                        <div class="rounded-full flex items-center justify-center px-4 py-2 cursor-pointer  font-bold border-2"
                        v-for="g, key in grades.filter(i => i.sectionName.includes(selectGradeMenuIndex) || i.sectionName == 'Şablon')" :key="key"
                        @click="!classManagementLoading && !g?.school && (selected_grade = setSelectGrade(g))"
                        :class="selected_grade.sectionName == g.sectionName ? 'bg-primary-default text-white border-primary-default' : 'text-black border-gray-600'"
                        >
                            {{ g.sectionName }}
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!--tools-->
        <div class="p-4 mb-4 bg-white rounded-xl mt-4" v-if="!selected_tool && screen == 'grade'">

            <div class="w-full flex items-end justify-end ">
                <div class="flex space-x-2">
                    <a class="bg-yellow-default px-4 py-2 cursor-pointer  hover:text-white  rounded-lg" href="/sinif-paketi/ekle">Sınıf Ekle</a>
                    <button class="bg-yellow-default px-4 py-2 cursor-pointer  hover:text-white  rounded-lg flex items-center justify-center space-x-2" @click="screen = 'options'">
                        <div><Setting class="w-3 h-3"/></div>
                        <div>Ayarlar</div>
                    </button>
                </div>
            </div>

            <div class="flex justify-start">
                <div>
                    <div class="flex space-x-4 mt-4 w-full">
                        <a class="border-2 border-gray-400 rounded-lg flex flex-col cursor-pointer bg-primary-default mr-4" href="/profil/okul-duzenle" v-if="!isSchoolSelected">
                            <div class="p-8 flex items-center justify-center  text-white">
                                <Home size="60"/>
                            </div>
                            <div class="flex items-center justify-center p-2 text-sm font-bold text-white">
                                Okul Seçiniz
                            </div>
                        </a>

                        <a class="border-2 border-gray-400 rounded-lg flex flex-col cursor-pointer bg-primary-default mr-4" href="/sinif-paketi/ekle" v-if="!isLoading && grades && isSchoolSelected && grades.length == 1">
                            <div class="p-8 flex items-center justify-center  text-white">
                                <Plus size="60"/>
                            </div>
                            <div class="flex items-center justify-center p-2 text-sm font-bold text-white">
                                Sınıf Ekle
                            </div>
                        </a>
                    </div>
                </div>

                <div class="flex space-x-4 mt-4">
                    <div class="border-2 border-gray-400 rounded-lg flex flex-col cursor-pointer bg-gray-100 hover:bg-primary-light" v-for="t in tools" :key="t.id" @click="!classManagementLoading && (selected_tool = t)">
                        <div class="p-8 flex items-center justify-center text-primary-default hover:text-white">
                            <User size="60" v-if="t.id == 'select_random_student'"/>
                            <EveryUser size="60" v-if="t.id == 'student_random_group'"/>
                            <Stopwatch size="60" v-if="t.id == 'timer'"/>
                        </div>
                        <div class="flex items-center justify-center p-2 bg-primary-default text-xs text-white">
                            {{ t.title }}
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <!--grades-->
        <div class="p-4 mb-4 bg-white rounded-xl mt-4" v-if="!selected_tool">
            <div v-if="classManagementLoading">
                <!-- Bekleyiniz... -->
                <div class="w-full flex space-x-2 py-1 px-2 rounded-lg">
                    <div class="bg-gray-400 px-4 py-2 cursor-pointer opacity-80 h-20 w-32 rounded-lg" v-for="l in 5" :key="l"></div>
                </div>

            </div>
            <div v-else>
                <Grade :event="event" :user="user" :grade="grades.filter(i => i.grade_id == selected_grade.grade_id && i.grade_section_id == selected_grade.grade_section_id)[0]" :options="options" v-if="screen=='grade'"/>
                <Options  @screen="screen=$event" :options="options" v-if="screen == 'options'"/>
            </div>
        </div>


        <fs v-model="fullscreen" class="h-full w-full select-none bg-white">
        <div class="p-4 mb-4 bg-white rounded-xl mt-4"  v-if="selected_tool">
            <ToolsOptions :fullscreen="fullscreen" @fullscreen="fullscreen = $event" :soundStart="soundStart" :loading="toolsLoading" :options="options" @close="selected_tool = null" :selected_tool="selected_tool" :title="selected_tool.title"/>
            <SelectRandomStudent :soundStart="soundStart" @loading="toolsLoading = $event" :event="event" :grade="grades.filter(i => i.grade_id == selected_grade.grade_id && i.grade_section_id == selected_grade.grade_section_id)[0]" :options="options" v-if="selected_tool.id == 'select_random_student'"/>
            <StudentsRandomGroup :soundStart="soundStart" @loading="toolsLoading = $event" :event="event" :grade="grades.filter(i => i.grade_id == selected_grade.grade_id && i.grade_section_id == selected_grade.grade_section_id)[0]" :options="options" v-if="selected_tool.id == 'student_random_group'"/>
            <Timer :soundStart="soundStart" v-if="selected_tool.id == 'timer'"/>
        </div>
        </fs>


        <!--Reaksiyon -->
        <div class="absolute w-full h-full z-40 flex items-center justify-center mt-4 container" v-if="reaction">
            <div class="absolute w-full h-full opacity-40 bg-yellow-default"></div>

            <div class="absolute w-full h-full flex items-start justify-center z-50 pt-10">

                <div class="bg-white p-12 shadow-2xl shadow-gray-500 flex flex-col space-y-5 items-center justify-center rounded-lg w-1/2">
                    <div class="text-sm">Alkış</div>
                    <div class="font-bold text-4xl">{{ event.value.obj?.full_name || `${event.value.obj?.index}. Öğrenci` }}</div>
                </div>


                <div class="absolute flex w-full h-full z-50 bottom-0 left-0">
                    <vue-damp-fireworks class="z-50">
                        <vue-damp-fireworks />
                        <vue-damp-fireworks />
                    </vue-damp-fireworks>
                </div>
        </div>
    </div>

</div>

</template>

<script setup>
import {ref, defineProps, onMounted, watch, computed} from 'vue'
import SelectRandomStudent from './components/tools/SelectRandomStudent.vue'
import StudentsRandomGroup from './components/tools/StudentsRandomGroup.vue'
import Timer from './components/tools/Timer.vue'
import useSound from './sounds'
import Grade from './components/screen/Grade.vue'
import Options from './components/screen/Options.vue'
import ToolsOptions from './components/other/toolsOptions.vue'
import useModelClassManagement from "./compositions/useModelClassManagement";
import {Setting,User,EveryUser, Stopwatch, Plus, Home} from '@icon-park/vue-next';


const props             = defineProps(['user', 'filesUrl'])
const grades            = ref([])
const selected_tool     = ref(null)
const selected_grade    = ref({})
const screen            = ref('options')
const isLoading         = ref(false)
const event             = ref({})
const reaction          = ref(false)
const toolsLoading      = ref(false)
const error             = ref(null)
const isSchoolSelected  = ref(true)
const fullscreen        = ref(false)
const gradeMenuLimit    = ref(11)
const selectGradeMenuIndex   = ref(null)

const {soundStart, soundSetVolume} = useSound({files_url:props.filesUrl})
const { classManagementLoading, classManagementData, classManagementError, getGrade, getStudents} = useModelClassManagement();

onMounted(() => soundSetVolume(true))

const defaultNewStudent = {
    selectable: true,
    point: 0,
    selectedSize: 0
}

const tools = [
    {
        id: 'select_random_student',
        title: 'Öğrenci Seç',
        options: ['displayStudentName', 'fineSelection', 'randomSelectionSpeed', 'gradeSize']

    },
    {
        id: 'student_random_group',
        title: 'Öğrenci Grupla',
        options: ['displayStudentName', 'randomSelectionSpeed', 'gradeSize', 'groupSize']

    },
    {
        id: 'timer',
        title: 'Zamanlayıcı',
        options: []

    }
]


const options  = ref({
    displayStudentName: {
        type: 'boolean',
        prefix: ["Evet", "Hayır"],
        visible: true,
        value: true,
        title: "Öğrenci İsmi Görünsün Mü?",
        description: "Bu ayarı seçebilmeniz için okul seçmeniz gerekir."
    },
    fineSelection: {
        type: 'boolean',
        prefix: ["Açık", "Kapalı"],
        visible: true,
        value: true,
        title: 'Öğrenciyi Hassas Seçme',
        description: 'Her öğrencinin eşit miktarda söz almasını bu kısımdan ayarlayabilirsiniz.'
    },
    randomSelectionSpeed: {
        type: 'number',
        prefix: ["saniye"],
        visible: true,
        increment: 1,
        minValue: 1,
        value: 2,
        title: 'Rastgele Seçme Hızı',
        description: 'Seçme hızını bu kısımdan ayarlayabilirsiniz.'
    },
    gradeSize: {
        type: 'number',
        prefix: ["öğrenci"],
        visible: true,
        increment: 1,
        minValue: 2,
        value: 24,
        title: 'Sınıf Mevcudu',
        description: 'Varsayılan sınıf mevcudunu bu kısımdan ayarlayabilirsiniz.'
    },
    groupSize: {
        type: 'number',
        prefix: ["grup"],
        visible: true,
        increment: 1,
        minValue: 2,
        value: 2,
        title: 'Grup Sayısı',
        description: 'Varsayılan grup sayısını bu kısımdan ayarlayabilirsiniz.'
    },
    sound: {
        type: 'boolean',
        prefix: ["Açık", "Kapalı"],
        visible: true,
        value: true,
        title: "Ses Açılsın mı?",
        description: "Etkinliklerde olan sesleri açıp kapatabilirsiniz."
    }
})


onMounted(() => {
    isLoading.value = true
    screen.value = 'grade'
    getGrade()
    .then(res => {
        let newGrades = [...res?.data].map(g => {
            g.students = []
            g.noNameStudents = []
            return g
        })

        let newData = [{
            grade_id: 1111,
            grade_section_id: 1111,
            sectionName: "Şablon",
            students:[],
            noNameStudents:[]
        }]

        grades.value = [...newGrades, ...newData] || []
        if (newGrades.length > 0) {
            selected_grade.value = {key: 0, ...res?.data[0]}
        }

        selectGradeMenuIndex.value = grades.value?.[0].sectionName?.split("-")?.[0]?.trim() || 5
        isLoading.value = false
    })
    .catch(err => {
        let newData = [{
            grade_id: 1,
            school: true,
            grade_section_id: 1,
            sectionName: "Derslig Sınıfı",
            students:[],
            noNameStudents:[]
        }]

        grades.value = [...newData]
        selected_grade.value = {key: 0, school: true, ...newData[0]}
        options.value.displayStudentName.value  = false
        options.value.displayStudentName.visible = false
        error.value = "Sınıflarınızı görebilmek için okul seçmelisiniz."
        isSchoolSelected.value = false

        isLoading.value = false
    })
})


const setSelectGrade = (nG) => {
    let key = grades.value.findIndex(i => i.sectionName == nG.sectionName)
    return {key, ...nG}
}


watch(selected_grade, (cSelectedGrade) => {
    if (cSelectedGrade.sectionName != "şablon") {
        options.value['displayStudentName'].visible = true
        options.value['displayStudentName'].value   = true
    }

    noNameStudents()
    const selectedGradeIndex = grades.value.findIndex(i => i.grade_id == cSelectedGrade.grade_id && i.grade_section_id == cSelectedGrade.grade_section_id)
    if (cSelectedGrade && grades.value[selectedGradeIndex]?.students && grades.value[selectedGradeIndex]?.students.length == 0 && !cSelectedGrade?.school) {
        getStudents(cSelectedGrade)
        .then(res => {
            const newStudents = [...res?.data.map(s => {
                return {
                    ...s,
                    ...defaultNewStudent
                }
            })] || []


            grades.value[cSelectedGrade.key]['students'] = newStudents || []
            //selected_grade.value['students'] = newStudents || []
            if(res?.data.length == 0){
                options.value['displayStudentName'].visible = false
                options.value['displayStudentName'].value   = false
            }else{
                options.value['displayStudentName'].visible = true
                options.value['displayStudentName'].value   = true
            }

            //selected_tool.value = tools[1]
        })
        .catch(error => console.log("err2", error))
    }else{
        console.log("Daha önce eklendi");
    }
}, {deep: true})


const getGradeSize = computed(() => {
    let arr = [...grades.value.map(i => i.sectionName.split("-")[0].trim())]
    let uniqueArray = [...new Set(arr)];
    return uniqueArray.filter(i => i != "Şablon")
})

const noNameStudents = () => {
    const selectedGradeIndex = grades.value.findIndex(i => i.grade_id == selected_grade.value.grade_id && i.grade_section_id == selected_grade.value.grade_section_id)
    if (!grades.value[selectedGradeIndex]?.noNameStudents) return false
    if(grades.value[selectedGradeIndex]?.['noNameStudents']?.length == options.value['gradeSize']?.value) return false
    if(grades.value[selectedGradeIndex]?.noNameStudents.length == 0){
        let newStudents = Array.from({ length: options.value['gradeSize'].value }, (_, index) => index) || []
        grades.value[selectedGradeIndex]['noNameStudents'] = newStudents.map(s => {
            return {
                ...defaultNewStudent
            }
        })
    }else{
        if(grades.value[selectedGradeIndex]?.noNameStudents.length < options.value['gradeSize'].value){
            let newStudents = Array.from({ length: options.value['gradeSize'].value - grades.value[selectedGradeIndex].noNameStudents.length}, (_, index) => index);
            grades.value[selectedGradeIndex].noNameStudents = [...grades.value[selectedGradeIndex].noNameStudents, ...newStudents.map(s => {
                return {
                    ...defaultNewStudent
                }
            })]
        }else if(grades.value[selectedGradeIndex].noNameStudents.length > options.value['gradeSize'].value){
            grades.value[selectedGradeIndex].noNameStudents = grades.value[selectedGradeIndex].noNameStudents.splice(0, options.value['gradeSize'].value)
        }
    }
}



watch(options, (cOptions) => {
    noNameStudents()
    soundSetVolume(cOptions.sound.value)
}, {deep: true})

watch(selected_tool, () => toolsLoading.value = false, {deep: true})


watch(event, (cEvent) => {
    const whichStudents = options.value.displayStudentName.value ? 'students' : 'noNameStudents'
    const selectedGradeIndex = grades.value.findIndex(i => i.grade_id == selected_grade.value.grade_id && i.grade_section_id == selected_grade.value.grade_section_id)

    if (cEvent.value.type == 'applause') {
        reaction.value = true
        soundStart(0)
        setTimeout(() => reaction.value = false, 5000);
    }else if (cEvent.value.type == 'deactiveSelection') {
        grades.value[selectedGradeIndex][whichStudents][cEvent.value.obj.index].selectable = false
    }else if (cEvent.value.type == 'activeSelection') {
        grades.value[selectedGradeIndex][whichStudents][cEvent.value.obj.index].selectable = true
    }else if (cEvent.value.type == 'trueAnswer') {
        soundStart(1)
        grades.value[selectedGradeIndex][whichStudents][cEvent.value.obj.index].point += 1
    }else if (cEvent.value.type == 'falseAnswer') {
        soundStart(2)
        grades.value[selectedGradeIndex][whichStudents][cEvent.value.obj.index].point -= 1
    }


}, {deep: true})




</script>

