<template>
    <div ref="menuRef">
        <Menu as="div" class="relative inline-block text-left w-full">
            <MenuButton @click="isOpen = !isOpen"
                        class="text-left w-full h-10 px-4 py-2 text-gray-960 text-sm font-medium cursor-pointer border border-gray-500 placeholder-gray-850 rounded focus:border-primary-500">
                <div ref="menuButtonRef" class="flex justify-between whitespace-nowrap overflow-x-hidden">
                    <div v-show="selectedText.length === 0">{{ placeholder }}</div>
                    <div v-show="selectedText.length > 0" class="">{{ selectedText }}</div>
                    <ChevronDownIcon v-show="!isOpen" class="w-5 h-5 ml-2 text-gray-960" aria-hidden="true"/>
                    <ChevronUpIcon v-show="isOpen" class="w-5 h-5 ml-2 text-gray-960" aria-hidden="true"/>
                </div>
            </MenuButton>
            <div v-show="isOpen"
                 class="absolute left-0 w-full h-36 divide-y divide-gray-500 focus:outline-none shadow-flat z-10"
                 :class="[position === 'top' ? '-top-36' : '']">
                <input v-if="searchable" type="text" class="sticky form-control w-full bg-gray-200" v-model="filterValue" ref="inputRef"
                       @keyup="filterSelect()" placeholder="Ara..."/>
                <MenuItems as="div" class="bg-white overflow-y-scroll" :class="searchable ? 'h-26' : 'h-36'" static>
                    <div ref="menuListRef">
                        <MenuItem as="div" v-show="data.length > 1" v-slot="{ active }">
                            <button type="button"
                                    :class="[active ? 'bg-gray-100' : '','group flex items-center w-full px-3 py-1.5',]"
                                    @click="selectAll($event)">
                                <input type="checkbox" class="pointer-events-none" ref="selectAllRef">
                                <span class="ml-2">{{ selectAllText }}</span>
                            </button>
                        </MenuItem>
                        <MenuItem v-slot="{ active }" v-for="(item, index) in data">
                            <button type="button"
                                    :class="[active ? 'bg-gray-100' : '','group flex items-center w-full px-3 py-1.5',]"
                                    @click="check($event, index)">
                                <input type="checkbox" v-model="selectedDataFiltered[index]"
                                       class="pointer-events-none">
                                <span class="ml-2 text-left">{{ item[textLabel] }}</span>
                            </button>
                        </MenuItem>
                    </div>
                </MenuItems>
            </div>
        </Menu>
        <select multiple ref="selectRef" v-show="false"></select>
        <input type="hidden" :name="formName" :value="selectedValuesText">
    </div>
</template>

<script>
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/vue/solid'
import {onMounted, ref} from "vue";

export default {
    name: "MultiSelect",
    components: {
        Menu,
        MenuButton,
        MenuItems,
        MenuItem,
        ChevronDownIcon,
        ChevronUpIcon,
    },
    props: {
        data: Array,
        preselectedDataText: {
            type: String,
            default: ''
        },
        formName: String,
        placeholder: String,
        textLabel: String,
        valueLabel: String,
        position: String,
        searchable: {
            type: Boolean,
            default: false
        },
    },
    setup(props) {
        const selectAllRef = ref(null);
        const selectedText = ref('');
        const selectAllText = ref('Tümünü seç');
        const menuRef = ref(null);
        const menuListRef = ref(null);
        const menuButtonRef = ref(null);
        const selectRef = ref(null);
        const isOpen = ref(false);
        const data = ref([]);
        const selectedData = ref([]);
        const selectedDataFiltered = ref([]);
        const selectedValues = ref([]);
        const selectedValuesText = ref('');
        const filterValue = ref('');

        onMounted(() => {
            data.value = props.data;

            let preselectedData = props.preselectedDataText !== '' ? props.preselectedDataText.split(',') : [];
            for (let i = 0; i < props.data.length; ++i) {
                selectedData.value[i] = preselectedData.includes("" + props.data[i].id);
            }

            selectedDataFiltered.value = selectedData.value;

            updateItems();

            document.addEventListener("keydown", (e) => {
                if (e.key === "Esc" || e.key === "Escape") {
                    isOpen.value = false;
                }
            });

            document.addEventListener("mousedown", (e) => {
                if (isOpen.value === false)
                    return false;

                if (!menuRef.value.contains(e.target)) {
                    isOpen.value = false;
                }
            });
        });

        const check = (e, index) => {
            let checkbox = e.currentTarget.querySelector('input');
            if (checkbox.checked) {
                checkbox.checked = '';
                selectedData.value[index] = false;
                selectedDataFiltered.value[index] = false;
            } else {
                checkbox.checked = 'checked';
                selectedData.value[index] = true;
                selectedDataFiltered.value[index] = true;
            }
            updateItems();
        };

        const selectAll = (e) => {
            let checkbox = e.currentTarget.querySelector('input');
            if (checkbox.checked) {
                checkbox.checked = '';
            } else {
                checkbox.checked = 'checked';
            }

            let newValue = checkbox.checked;

            for (let i = 0; i < props.data.length; ++i) {
                selectedData.value[i] = newValue;
            }

            for (let i = 0; i < data.value.length; ++i) {
                selectedDataFiltered.value[i] = newValue;
            }

            updateItems();
        };

        const updateItems = () => {
            let selectedLabels = [];
            selectedValues.value = [];
            selectRef.value.innerHTML = '';

            for (let i = 0; i < props.data.length; ++i) {
                if (selectedData.value[i]) {
                    // Add option to select
                    let option = document.createElement('option');
                    option.innerHTML = props.data[i][props.textLabel];
                    option.value = props.data[i][props.valueLabel];
                    option.selected = 'selected';
                    selectRef.value.appendChild(option);

                    selectedLabels.push(props.data[i][props.textLabel]);
                    selectedValues.value.push(props.data[i][props.valueLabel]);
                }
            }

            if (props.data.length === selectedLabels.length) {
                selectAllRef.value.checked = 'checked';
                selectAllText.value = 'Tümünün seçimini kaldır';
            } else {
                selectAllRef.value.checked = '';
                selectAllText.value = 'Tümünü seç';
            }

            selectedText.value = selectedLabels.join(', ');
            selectedValuesText.value = selectedValues.value.join(',');
        };

        const filterSelect = () => {
            selectedDataFiltered.value = JSON.parse(JSON.stringify(selectedData.value));

            data.value = props.data.filter(function (item, index) {
                let isExist = item[props.textLabel].toLowerCase().includes(filterValue.value.toLowerCase());

                if (!isExist && selectedDataFiltered[index] !== 'undefined') {
                    selectedDataFiltered.value.splice(index, 1);
                }

                return isExist;
            });
        }

        return {
            showSelectAll: true,
            isOpen,
            data,
            selectedData,
            selectedDataFiltered,
            selectedValues,
            selectedValuesText,
            menuRef,
            menuListRef,
            menuButtonRef,
            selectRef,
            selectAllRef,
            selectAllText,
            filterValue,
            selectedText,
            check,
            selectAll,
            filterSelect,
        };
    },
}
</script>
