<template>
    <div v-if="obj.visible && obj">
        <div class="flex" v-if="obj?.type == 'number'">
            <button class="px-4 py-2 bg-gray-200 border-lg hover:bg-primary-light rounded-l-lg" @click="obj.value != obj?.minValue && (obj.value -= obj?.increment)">-</button>
            <div class="px-4 py-2 bg-gray-100 border-lg  min-w-max text-sm">{{ obj?.value }} {{ cPrefix?.[0] }}</div>
            <button class="px-4 py-2 bg-gray-200 border-lg hover:bg-primary-light rounded-r-lg" @click="obj.value += obj?.increment">+</button>
        </div>
        <div class="flex" v-if="obj?.type == 'boolean'">
            <button class="px-4 py-2  border-lg hover:bg-primary-light rounded-l-lg text-sm min-w-max" :class="obj.value ? 'bg-secondary text-white':'bg-gray-200'" @click="obj.value = true">{{ cPrefix?.[0] }}</button>
            <button class="px-4 py-2  border-lg hover:bg-primary-light rounded-r-lg text-sm min-w-max" :class="!obj.value ? 'bg-secondary text-white':'bg-gray-200'" @click="obj.value = false">{{ cPrefix?.[1] }}</button>
        </div>

    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref} from 'vue';

const emit = defineEmits([])
const props = defineProps({obj:{}, prefix: {default: []}})

const cPrefix = ref(props?.prefix.length == 0 ? props.obj.prefix : props.prefix)

</script>
