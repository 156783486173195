import axios from "axios";

const http = axios.create({
  baseURL: '/api/',
  withCredentials: false,
});

http.interceptors.request.use(
  (config) => {
    //config.headers["X-CSRF-TOKEN"] = "";
    return config;
  },
  (error) => {
    //console.log("httpError", error);
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.config?.errorHandle === false) {
      return Promise.reject(error);
    }

    //console.log("httpError", error);
    //401, 404 etc
    //console.log(error.response.status);
  }
);

export default http;
