<template>
    <div>

        <!--Modal -->
        <div class="absolute top-0 right-0 w-full h-full z-30 py-4" v-if="modal && selected">
            <div class="absolute w-full h-full opacity-40 bg-yellow-default"></div>

            <div class="absolute w-full h-full flex items-start justify-center z-50 pt-10">

                <div class="bg-white shadow-2xl shadow-gray-500 flex flex-col items-center justify-center rounded-lg w-1/2">
                    <div class="w-full flex items-end justify-end p-4">
                        <button  class="bg-secondary px-4 py-2 cursor-pointer hover:bg-primary-light rounded-lg text-sm text-white" @click="modal = !modal">Kapat</button>
                    </div>

                    <div class="py-6 text-4xl">
                        {{ students[selected[0]]?.full_name || `${(students[selected[0]].index + 1)}. Öğrenci` }}
                    </div>

                    <div>
                        <Student :buttons="['applause', 'falseAnswer', 'trueAnswer']" :info="true" :deactive-buttons="[]" @event="student_select_button = $event" :event="event" :obj="{...students[selected[0]], index: selected[0]}" />
                    </div>

                    <div class="mt-4 p-6">
                        <div class="bg-yellow-default rounded-lg px-4 py-2 flex items-center justify-center text-xl " :class="isSelectable ? 'opacity-100 cursor-pointer' : 'opacity-50'"
                        @click="startSelection" v-if="students.length > 0 && !error && !selectedIndexError">
                            Yeniden Seç
                        </div>
                    </div>
                </div>

            </div>
        </div>


        <div class="w-full flex flex-col space-y-2 py-10">
            <div class="w-full flex flex-col space-y-4 items-center justify-center">
                <div class="w-full bg-secondary text-left p-4 text-white" v-if="students.length == 0 || selectedIndexError || error">
                    <div v-if="error">Öğrenci seçme işlemi gerçekleştirilemedi.</div>
                    <div v-if="selectedIndexError">{{ selectedIndexError }}</div>
                    <div v-if="students.length == 0">Öğrenci sayısı yetersiz.</div>
                </div>
            </div>

            <div>
                <div class="flex  items-center justify-center">
                    <div class="bg-yellow-default rounded-lg px-4 py-2 flex items-center justify-center text-xl w-auto" :class="isSelectable ? 'opacity-100 cursor-pointer' : 'opacity-50'"
                        @click="startSelection" v-if="students.length > 0 && !error && !selectedIndexError">
                        Seçimi Başlat
                    </div>
                </div>

                <div class="flex flex-wrap mt-4">
                    <div class="w-1/6 flex items-center justify-center p-2" v-for="s, index in students" :key="index">
                        <Student :selected="temporarySelection?.[0] == index" :event="event" :buttons="['deactiveSelection','activeSelection']" :info="!temporarySelection ? true : false" :deactive-buttons="[!s.selectable ? 'deactiveSelection' : 'activeSelection']" :obj="{...s, index}"/>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script setup>
import {ref, defineProps, defineEmits, computed, reactive, watch} from 'vue'
import useSound from '../../sounds'
import Student from '../other/student.vue'
import useRandIndex from '../../compositions/useRandIndex'

const {soundStart, soundSetVolume} = useSound({files_url:null})
const {selectedIndexError, select, selectingIndex, getRandomSelectIndex, excludedIndexes, selectableLength, selectableIndexes} = useRandIndex()

const props              = defineProps(['options', 'grade', 'event', 'soundStart'])
const emit               = defineEmits(['loading'])
const isSelectable       = ref(true)
const selected           = ref(null)
const temporarySelection = ref(null) //geçici seçilen
const loading            = ref(false)
const error              = ref(null)
const student_select_button = ref(null)
const modal             = ref(false)

const students = computed(() => {
    return reactive(props.options['displayStudentName'].value ? props.grade.students : props.grade.noNameStudents)
})

watch(students, (cStudents) => {
    error.value = null
    if (cStudents.filter(i => i.selectable).length == 0) {
        error.value = "Seçilebilcek öğrenci sayısı sıfır olamaz."
    }

}, {deep: true})

watch(loading, (cLoading) => {
    emit('loading', cLoading)
})


//doğru yanlış butonuna bastı ise yeni seçim yapsın
watch(student_select_button, (cSelected) => {
    //if (['trueAnswer', 'falseAnswer'].includes(cSelected)) startSelection()
    student_select_button.value = null
})

watch(selected, (cSelected) => {
   if (cSelected) props.soundStart(3)
})

watch(temporarySelection, (cSelected) => {
   if (cSelected) props.soundStart(4)
})


const startSelection = () => {
    if (!isSelectable.value)  return false
    loading.value = true
    modal.value   = false

    //seçimi iptal edilenleri eleyelim
    let index = -1;
    let excludedIndexesArr = students.value.map(i => {
        index++
        return !i.selectable ?  index : 'x'
    }).filter(x => x != 'x')

    excludedIndexes.value = [...excludedIndexesArr]
    selectableLength.value = students.value.length
    select()

    isSelectable.value = !isSelectable.value
    selected.value = null
    temporarySelection.value = null

    let i = 0;
    const interval = setInterval(() => {
        temporarySelection.value = getRandomSelectIndex()
        if (i == (props.options['randomSelectionSpeed'].value * 2)) {
            temporarySelection.value = null
            const selectIndex   = props.options['fineSelection'].value ? selectingIndex.value : getRandomSelectIndex()
            selected.value      = selectIndex
            isSelectable.value  = !isSelectable.value
            students.value[selectIndex[0]].selectedSize += 1
            loading.value = false
            clearInterval(interval)
            modal.value = true
        }
        i++
    }, 500);
}








</script>
