<template>
    <TransitionRoot as="template" :show="isModalOpen">
        <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto">
            <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0" id="welcome-modal-container">
                <TransitionChild as="template" enter="ease-out duration-500" enter-from="opacity-0" enter-to="opacity-100"
                                 leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-black bg-opacity-90 transition-opacity"/>
                </TransitionChild>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-1000"
                                 enter-from="opacity-0 translate-y-64 sm:scale-90"
                                 enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                 leave-from="opacity-100 translate-y-0 sm:scale-100"
                                 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="p-4 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl
                    transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full border-2 border-gray-980">
                        <div class="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:space-x-4 text-center sm:text-left">
                            <div class="animate-[wiggle_1s_ease-in-out] flex justify-center sm:block flex-shrink-0">
                                <img v-if="screen === 'first' || screen === 'verified'" src="/img/professor/expressions/1.png" />
                                <img v-else-if="screen === 'phone-update' || screen === 'verification'" src="/img/professor/expressions/2.png" />
                                <img v-else-if="screen === 'success'" src="/img/professor/expressions/3.png" />
                                <img v-else-if="screen === 'verified-error'" src="/img/professor/expressions/2.png" />
                            </div>
                            <div v-if="screen === 'first'" class="w-full">
                                <div class="h-full flex flex-col justify-between">
                                    <type-writer :content="welcomeText" stop-blinking="true" @callback="showAnswerButtons"></type-writer>
                                    <div v-if="showWelcomeButtons" class="mt-4 bg-gray-50 flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4">
                                        <div class="w-full">
                                            <a href="/aktivasyon"
                                               class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2
                                               bg-secondary text-base sm:text-sm font-bold text-white hover:bg-opacity-80 focus:outline-none">
                                                Evet
                                            </a>
                                        </div>
                                        <div class="w-full">
                                            <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-secondary
                                    text-base sm:text-sm font-bold text-white hover:bg-opacity-80 focus:outline-none" @click="showPhoneUpdateScreen()">
                                                Hayır
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="screen === 'phone-update'">
                                <div class="h-full flex flex-col justify-between">
                                    <DialogTitle as="h3" class="text-lg leading-6 font-bold text-secondary mb-2">
                                        Derslig Pro'yu Ücretsiz Dene
                                    </DialogTitle>
                                    <div class="mb-4 text-sm">
                                        Telefon numaranı doğrulayarak Derslig Pro'yu 3 gün boyunca <span class="text-primary-500 font-bold">ücretsiz</span>
                                        deneme hakkı kazanırsın. Deneme süresinin bitiminde senden herhangi bir <span class="text-primary-500 font-bold">ücret talep edilmeyecek</span>.
                                    </div>
                                    <div class="mb-2">
                                        <input type="tel" class="form-control focus:border-secondary" v-model="phone" v-mask="'0 (999) 999 99 99'" ref="phoneNumberRef" tabindex="0"  @keyup.enter="sendCode()" />
                                    </div>
                                    <div v-show="phoneUpdateInfoMessage" class="text-secondary text-xs mb-4">
                                        {{ phoneUpdateInfoMessage }}
                                    </div>
                                    <div class="bg-gray-50 flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4">
                                        <div class="w-full">
                                            <button type="button" class="w-full px-4 py-2 inline-flex justify-center rounded-md border border-transparent shadow-sm
                                    bg-secondary text-base sm:text-sm font-bold text-white hover:bg-opacity-80" @click="sendCode()">
                                                Kod Gönder
                                            </button>
                                        </div>
                                        <div class="w-full">
                                            <button type="button" class="w-full px-4 py-2 inline-flex justify-center rounded-md border border-gray-600 shadow-sm bg-white
                                    text-base sm:text-sm font-bold text-gray-800 hover:text-gray-900 hover:bg-gray-200 hover:border-gray-800" @click="isModalOpen = false">
                                                Şimdi Değil
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="screen === 'verification'">
                                <DialogTitle as="h3" class="text-lg leading-6 font-bold text-secondary mb-2">
                                    Telefon Numaranı Doğrulayalım
                                </DialogTitle>
                                <div class="mb-4 text-sm">
                                    <span class="text-primary-500 font-bold">{{ phone.substring(0, 4) + 'XXX' + phone.substring(7) }}</span>
                                    numaralı telefona gönderilen 4 haneli kodu gir.
                                </div>
                                <Transition name="slide">
                                    <div v-show="verificationInfoMessage" class="alert alert-info text-sm mb-4 bg-primary-500">
                                        {{ verificationInfoMessage }}
                                    </div>
                                </Transition>
                                <div class="mb-4">
                                    <input type="text" class="form-control focus:border-secondary" maxlength="4" placeholder="Onay kodunu gir" v-model="verificationCode" ref="verificationCodeRef" />
                                </div>
                                <div class="bg-gray-50 flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4">
                                    <div class="w-full">
                                        <button type="button" class="w-full px-4 py-2 inline-flex justify-center rounded-md border border-transparent shadow-sm
                                    bg-secondary text-base sm:text-sm font-bold text-white hover:bg-opacity-80 focus:outline-none" @click="verifyCode()">
                                            Doğrula
                                        </button>
                                    </div>
                                    <div class="w-full">
                                        <button type="button" class="w-full px-4 py-2 inline-flex justify-center rounded-md border border-gray-600 shadow-sm bg-white
                                    text-base sm:text-sm font-bold text-gray-800 hover:text-gray-900 hover:bg-gray-200 hover:border-gray-800" @click="showPhoneUpdateScreen()">
                                            Numaramı değiştir
                                        </button>
                                    </div>
                                </div>
                                <div v-show="remainingTimeText" class="mt-4 text-sm font-bold text-center text-gray-900">
                                    Kodu girebilmek için kalan süre: <span>{{ remainingTimeText }}</span>
                                </div>
                                <div v-show="showResendButton">
                                    <button type="button" class="w-full mt-4 px-4 py-2 inline-flex justify-center rounded-md border border-transparent shadow-sm bg-primary-default
                                    text-base sm:text-sm font-bold text-white hover:bg-opacity-80 focus:outline-none" @click="sendVerificationCode()">
                                        Kodu yeniden gönder
                                    </button>
                                </div>
                            </div>
                            <div v-else-if="screen === 'verified'">
                                <div class="flex flex-col justify-between h-full">
                                    <div>
                                        <DialogTitle as="h3" class="text-lg leading-6 font-bold text-secondary mb-2">
                                            Derslig Pro'yu Ücretsiz Dene
                                        </DialogTitle>
                                        <div class="mb-4 text-sm">
                                            Derslig Pro'yu 3 günlük deneme hakkı kazandın. Hemen kullanmaya başlamak ister misin?<br />
                                            Deneme süresinin bitiminde senden herhangi bir <span class="text-primary-500 font-bold">ücret talep edilmeyecek</span>.
                                        </div>
                                    </div>
                                    <div class="mt-4 bg-gray-50 flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4">
                                        <div class="w-full">
                                            <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-secondary
                                text-base sm:text-sm font-bold text-white hover:bg-opacity-80 focus:outline-none" @click="activate()">
                                                Evet
                                            </button>
                                        </div>
                                        <div class="w-full">
                                            <button type="button" class="w-full px-4 py-2 inline-flex justify-center rounded-md border border-gray-600 shadow-sm bg-white
                                    text-base sm:text-sm font-bold text-gray-800 hover:text-gray-900 hover:bg-gray-200 hover:border-gray-800" @click="isModalOpen = false">
                                                Şimdi Değil
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="screen === 'verified-error'" class="w-full">
                                <div class="flex flex-col justify-between h-full">
                                    <div>
                                        <DialogTitle as="h3" class="text-lg leading-6 font-bold text-secondary mb-2">
                                            Derslig Pro'yu Ücretsiz Dene
                                        </DialogTitle>
                                        <div class="mb-4 text-sm">
                                            {{ errorMessage }}
                                        </div>
                                    </div>
                                    <div>
                                        <button type="button" class="w-full px-4 py-2 inline-flex justify-center rounded-md border border-gray-600 shadow-sm bg-white
                                text-base sm:text-sm font-bold text-gray-800 hover:text-gray-900 hover:bg-gray-200 hover:border-gray-800" @click="isModalOpen = false">
                                            Kapat
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="screen === 'pro-school-user-warning'">
                                <div class="flex flex-col justify-between h-full">
                                    <div>
                                        <DialogTitle as="h3" class="text-lg leading-6 font-bold text-secondary mb-2">
                                            Derslig Kurumsal Üyeliğiniz Bulunmaktadır
                                        </DialogTitle>
                                        <div class="mb-4 text-sm">
                                            {{ errorMessage }}
                                        </div>
                                    </div>
                                    <div class="w-full">
                                        <a href="/cikis?r=giris" class="w-full px-4 py-2 inline-flex justify-center rounded-md border border-transparent shadow-sm bg-secondary
                                            text-base sm:text-sm font-bold text-white hover:bg-opacity-80 focus:outline-none" @click="isModalOpen = false">
                                            Kurumsal Üyeliğinle Giriş Yap
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="screen === 'success'">
                                <div class="flex flex-col justify-between h-full">
                                    <div>
                                        <DialogTitle as="h3" class="text-lg leading-6 font-bold text-secondary mb-2">
                                            Tebrikler!
                                        </DialogTitle>
                                        <div class="mb-4 text-sm">
                                            Hesabın Derslig Pro'ya yükseltildi. 3 gün boyunca Derslig Pro'yu kullanmaya başlayabilirsin.
                                        </div>
                                    </div>
                                    <div class="w-full">
                                        <a href="/" class="w-full px-4 py-2 inline-flex justify-center rounded-md border border-transparent shadow-sm bg-secondary
                                            text-base sm:text-sm font-bold text-white hover:bg-opacity-80 focus:outline-none" @click="isModalOpen = false">
                                            Kullanmaya Başla
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TransitionChild>
            </div>
            <div v-show="isLoading" class="absolute w-full h-full top-1/2 left-1/2 text-center bg-black bg-opacity-10 z-10" style="transform: translate(-50%,-50%)">
                <div class="w-full h-full flex items-center justify-center">
                    <pulse-loader :color="'#03B0B9'" :size="'12px'"></pulse-loader>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
    import { ref, nextTick, onMounted } from 'vue'
    import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
    import TypeWriter from "../utils/TypeWriter.vue";
    import EventBus from "../events";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    onMounted(() => {
        EventBus.on('open-welcome-modal-first', () => {
            isModalOpen.value = true;
        });

        EventBus.on('open-welcome-modal', () => {
            isModalOpen.value = true;
            showPhoneUpdateScreen();
        });

        // Datalayer object
        window.dataLayer = window.dataLayer || [];
    });

    const props = defineProps(['user']);

    const welcomeText = "Hoş geldin " + props.user.name + ", Derslig Pro aktivasyon kodun var mı?";

    const isModalOpen = ref(false);
    const isLoading = ref(false);
    const screen = ref('first');
    const showWelcomeButtons = ref(false);

    const phoneNumberRef = ref(null);
    const verificationCodeRef = ref(null);

    const phone = ref(props.user.phone);

    const verificationCode = ref('');
    const phoneUpdateInfoMessage = ref(null);
    const verificationInfoMessage = ref(null);
    const showResendButton = ref(false);
    const remainingTimeText = ref(null);
    const errorMessage = ref(null);

    let smsTimer = null;
    let remainingSeconds = 180;

    const showAnswerButtons = () => {
        showWelcomeButtons.value = true;
    }

    const showPhoneUpdateScreen = () => {
        if (props.user.is_phone_verified) {
            screen.value = 'verified';
            return;
        }

        screen.value = 'phone-update';

        phoneUpdateInfoMessage.value = '';
        verificationCode.value = '';
        verificationInfoMessage.value = '';

        nextTick(() => {
            phoneNumberRef.value?.focus();
        });
    }

    const sendCode = () => {
        let inputPhoneValue = '0' + phoneNumberRef.value.inputmask.unmaskedvalue();

        if (inputPhoneValue === '0') {
            phoneUpdateInfoMessage.value = 'Telefon numaranızı giriniz';
            phoneNumberRef.value.focus();
            return;
        }

        if (!isValidPhoneNumber(inputPhoneValue)) {
            phoneUpdateInfoMessage.value = 'Hatalı numara girdiniz';
            phoneNumberRef.value.focus();
            return;
        }

        phone.value = inputPhoneValue;
        phoneUpdateInfoMessage.value = '';

        screen.value = 'verification';
        verificationCode.value = '';

        nextTick(() => {
            verificationCodeRef.value?.focus();
        });

        sendVerificationCode();
    }

    const sendVerificationCode = () => {
        if (isValidPhoneNumber(phone.value)) {
            axios.post('/profil/telefon-dogrulama/onay-kodu-gonder', {
                phone: phone.value,
            }).then(response => {
                if (response.data.error === '') {
                    verificationInfoMessage.value = 'Onay kodu başarıyla gönderildi!';
                    startSmsTimer();

                    window.dataLayer.push({
                        event: "user_form_submit",
                        category: "phone"
                    });
                } else {
                    if (response.data.remainingSeconds !== undefined) {
                        startSmsTimer(response.data.remainingSeconds);
                        verificationInfoMessage.value = response.data.error;
                    } else if (response.data.isProSchoolUser !== undefined) {
                        errorMessage.value = response.data.error;
                        screen.value = 'pro-school-user-warning';
                    } else {
                        phoneUpdateInfoMessage.value = response.data.error;
                        screen.value = 'phone-update';
                    }
                }
            }).catch(e => {
                if (!e.response) {
                    phoneUpdateInfoMessage.value = 'Lütfen internet bağlantınızı kontrol ediniz!';
                } else {
                    phoneUpdateInfoMessage.value = 'Onay kodu gönderilemedi lütfen tekrar deneyiniz!';
                }
                screen.value = 'phone-update';
                showResendButton.value = true;
            }).then(() => {
                setTimeout(() => verificationInfoMessage.value = '', 4000);
            });
        }
    }

    const verifyCode = () => {
        if (verificationCode.value.length === 4) {
            verificationInfoMessage.value = 'Doğrulanıyor...';

            axios.post('/profil/telefon-dogrulama/dogrula?pro=1', {
                phone: phone.value,
                code: verificationCode.value,
            }).then(response => {
                if (response.data.error === '') {
                    verificationInfoMessage.value = '';
                    screen.value = 'success';

                    window.dataLayer.push({
                        event: "user_form_submit",
                        category: "registered"
                    });
                } else {
                    verificationInfoMessage.value = response.data.error;
                }
            }).catch(e => {
                if (!e.response) {
                    verificationInfoMessage.value = 'Lütfen internet bağlantınızı kontrol ediniz!';
                } else {
                    verificationInfoMessage.value = 'Hata oluştu, lütfen tekrar deneyiniz!';
                }
            }).then(() => {
                setTimeout(() => verificationInfoMessage.value = '', 4000);
            });
        }
    }

    const activate = () => {
        isLoading.value = true;

        axios.post('/api/start-trial').then(response => {
            if (response.data.error === '') {
                screen.value = 'success';

                window.dataLayer.push({
                    event: "user_form_submit",
                    category: "registered"
                });
            } else {
                errorMessage.value = response.data.error;
                screen.value = 'verified-error';
            }
        }).catch(e => {
            if (!e.response) {
                errorMessage.value = 'Lütfen internet bağlantınızı kontrol ediniz!';
            } else {
                errorMessage.value = e.response.data.error;
            }
            screen.value = 'verified-error';
        }).then(() => {
            isLoading.value = false;
        });
    }

    const isValidPhoneNumber = (phoneValue) => {
        return phoneValue.length === 11 && phoneValue.indexOf('05') === 0;
    }

    function showResend(){
        screen.value = 'phone-update';
        showResendButton.value = true;
    }

    function startSmsTimer(seconds = 180) {
        showResendButton.value = false;
        stopSmsTimer();

        remainingSeconds = seconds;

        smsTimerProcess();
        if (remainingSeconds > 0) {
            smsTimer = setInterval(smsTimerProcess, 1000);
        }
    }

    function stopSmsTimer() {
        clearInterval(smsTimer);
    }

    function smsTimerProcess() {
        printSmsTimer();

        if (remainingSeconds === 0) {
            stopSmsTimer();
            showResendButton.value = true;
        }
        remainingSeconds--;
    }

    function printSmsTimer() {
        let min = Math.floor(remainingSeconds / 60).toString();
        let sec = (remainingSeconds % 60).toString()
        remainingTimeText.value = min.padStart(2, "0") + ':' + sec.padStart(2, "0");
    }
</script>

<style scoped>
    .slide-enter-active {
        animation: slide 4s ease-out;
    }
    @keyframes slide {
        0% {
            transform: scaleY(0);
            transform-origin: top;
        }
        15% {
            transform: scaleY(1);
        }
        85% {
            transform: scaleY(1);
        }
        100% {
            transform: scaleY(0);
            transform-origin: top;
        }
    }
</style>
