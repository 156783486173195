<template>
    <slot name="content"></slot>
    <div v-if="!shown" class="mt-2 flex items-center" @click="shown = true">
        <span class="mr-2 font-semibold text-secondary text-sm  cursor-pointer">Daha fazla</span>
        <svg class="cursor-pointer" width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4.75H11.5M11.5 4.75L7 0.75M11.5 4.75L7 9.25" stroke="#E50069" stroke-width="2"/>
        </svg>
    </div>
    <slot v-if="shown" name="more"></slot>
</template>

<script setup>
    import {ref} from 'vue';

    const shown = ref(false);
</script>
