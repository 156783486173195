import {Howl, Howler} from 'howler';

export default function ({files_url}) {
    files_url = files_url ? files_url : '/'
    // files_url = "/"
    const arr = []
    arr[0]  = new Howl({src: [files_url + 'img/classManagement/sounds/applause.mp3'], html5: true});
    arr[1]  = new Howl({src: [files_url + 'img/classManagement/sounds/correct1.mp3'], html5: true});
    arr[2]  = new Howl({src: [files_url + 'img/classManagement/sounds/fail1.mp3'], html5: true});
    arr[3]  = new Howl({src: [files_url + 'img/classManagement/sounds/select3.mp3'], html5: true});
    arr[4]  = new Howl({src: [files_url + 'img/classManagement/sounds/tick.mp3'], html5: true});
    // arr[1]  = new Howl({src: [files_url + 'img/classManagement/sounds/beeping1.mp3'], html5: true});
    // arr[2]  = new Howl({src: [files_url + 'img/classManagement/sounds/blob1.mp3'], html5: true});
    // arr[3]  = new Howl({src: [files_url + 'img/classManagement/sounds/copper1.mp3'], html5: true});
    // arr[5]  = new Howl({src: [files_url + 'img/classManagement/sounds/correct2.mp3'], html5: true});
    // arr[6]  = new Howl({src: [files_url + 'img/classManagement/sounds/ding1.mp3'], html5: true});
    // arr[7]  = new Howl({src: [files_url + 'img/classManagement/sounds/fail1.mp3'], html5: true});
    // arr[8]  = new Howl({src: [files_url + 'img/classManagement/sounds/pop.mp3'], html5: true});
    // arr[9]  = new Howl({src: [files_url + 'img/classManagement/sounds/punchy1.mp3'], html5: true});
    // arr[10] = new Howl({src: [files_url + 'img/classManagement/sounds/select1.mp3'], html5: true});
    // arr[11] = new Howl({src: [files_url + 'img/classManagement/sounds/select2.mp3'], html5: true});
    // arr[12] = new Howl({src: [files_url + 'img/classManagement/sounds/select3.mp3'], html5: true});
    // arr[13] = new Howl({src: [files_url + 'img/classManagement/sounds/select4.mp3'], html5: true});
    // arr[14] = new Howl({src: [files_url + 'img/classManagement/sounds/surprise.mp3'], html5: true});
    // arr[15] = new Howl({src: [files_url + 'img/classManagement/sounds/tick.mp3'], html5: true});


    const soundSetVolume = (newVolume) => {
        Howler.volume(newVolume ? 1 : 0);
    }

    const soundStart = (selectNumber) => {
        arr[selectNumber].play()
    }

    return {
        soundStart,
        soundSetVolume
    };
}
