<template>
    <div class="back-to-top" @click="backToTop" :class="{'show': shown}">
        <a href="#" class="bg-secondary text-white shadow-md flex justify-center items-center">
            <BsCaretUpFill />
        </a>
    </div>
</template>

<script setup>
    import {ref, onMounted} from 'vue'
    import { BsCaretUpFill } from "@kalimahapps/vue-icons";

    const shown = ref(false);

    onMounted(() => {
        window.addEventListener('scroll', checkButton);
    });

    const checkButton = () => {
        // if 400 px far from top, show
        shown.value = document.documentElement.scrollTop > 400;
    }

    const backToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
</script>
