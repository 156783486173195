let TypewriterManager = (function () {
    function TypewriterManager() {
        this.element = null;
        this.index = 0;
        this.content = '';
        this.typeWriterHandler = null;
        this.startTypeWriterHandler = null;
        this.sound = false;
        this.writerSpeed = 60; // ms
        this.writerDelay = 1000; // ms
        this.professorTypingAudio = new Audio("/img/learning/professor/typing.mp3");
    }

    TypewriterManager.prototype.setElement = function (element) {
        this.element = element;
    }

    TypewriterManager.prototype.setBlink = function (value) {
        this.blink = value;
    }

    TypewriterManager.prototype.setSound = function (value) {
        this.sound = value;
    }

    TypewriterManager.prototype.setWriterSpeed = function (writerSpeed) {
        this.writerSpeed = writerSpeed;
    }

    TypewriterManager.prototype.setWriterDelay = function (writerDelay) {
        this.writerDelay = writerDelay;
    }

    TypewriterManager.prototype.reset = function () {
        this.index = 0;
        this.content = '';

        clearInterval(this.typeWriterHandler);
        clearTimeout(this.startTypeWriterHandler);
    }

    TypewriterManager.prototype.start = function (text) {
        this.reset();
        this.content = text;

        this.startTypeWriterHandler = setTimeout(() => {
            if (this.sound) {
                this.professorTypingAudio.loop = true;
                this.professorTypingAudio.play();
            }

            this.typeWriterHandler = setInterval(() => {
                console.log(this.content);
                if (this.index < this.content.length) {
                    this.element.append(this.content.charAt(this.index));
                    this.index++;
                } else {
                    this.stop();
                }
            }, this.writerSpeed);

        }, this.writerDelay);
    }

    TypewriterManager.prototype.stop = function () {
        if (this.sound) {
            this.professorTypingAudio.pause();
            this.professorTypingAudio.currentTime = 0;
        }

        clearInterval(this.typeWriterHandler);
    }

    TypewriterManager.prototype.constructor = TypewriterManager;

    return new TypewriterManager;
})();

export {TypewriterManager}
