<template>
    <TransitionRoot as="template" :show="isModalOpen">
        <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="closeModal()">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-500" enter-from="opacity-0"
                                 enter-to="opacity-100"
                                 leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-black bg-opacity-75 transition-opacity"/>
                </TransitionChild>
                <TransitionChild as="template" enter="ease-out duration-300"
                                 enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                 enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                 leave-from="opacity-100 translate-y-0 sm:scale-100"
                                 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div
                        class="inline-block bg-white rounded-lg shadow-xl transform transition-all w-full max-w-6xl border-2 border-gray-900 p-4">
                        <div class="flex justify-between items-center mb-2">
                            <DialogTitle as="h3" class="text-lg leading-6 font-bold text-gray-900">
                                {{ isEditMode ? 'SORU DÜZENLE' : 'SORU EKLE' }}
                            </DialogTitle>
                            <div>
                                <button class="btn btn-sm btn-gray" @click="closeModal()">
                                    Kapat
                                </button>
                            </div>
                        </div>
                        <div v-if="!isFinished" class="text-left">
                            <div class="flex space-x-2">
                                <div>
                                    <select class="form-control" v-model="selectedGradeId"
                                            @change="selectedGradeId && getCourses()">
                                        <option value="">Sınıf seçiniz</option>
                                        <option :key="grade.id" v-for="grade in grades" :value="grade.id">
                                            {{ grade.name }}
                                        </option>
                                    </select>
                                </div>
                                <div v-if="selectedGradeId">
                                    <select class="form-control" v-model="selectedCourseId"
                                            @change="selectedCourseId && getUnits()">
                                        <option value="">Ders seçiniz</option>
                                        <option :key="course.id" v-for="course in courses" :value="course.id">
                                            {{ course.name }}
                                        </option>
                                    </select>
                                </div>
                                <div v-if="selectedGradeId && selectedCourseId">
                                    <select class="form-control" v-model="selectedUnitId"
                                            @change="selectedUnitId && getLearningOutcomes()">
                                        <option value="">Ünite seçiniz</option>
                                        <option :key="unit.id" v-for="unit in units" :value="unit.id">
                                            {{ unit.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="mt-2" v-if="selectedGradeId && selectedCourseId && selectedUnitId">
                                <select class="form-control" v-model="selectedLearningOutcomeId"
                                        @change="showQuestions()">
                                    <option value="">Kazanım seçiniz</option>
                                    <option :key="learningOutcome.id" v-for="learningOutcome in learningOutcomes"
                                            :value="learningOutcome.id">
                                        {{ learningOutcome.text }}
                                    </option>
                                </select>
                            </div>
                            <div class="mt-4">
                                <Question :key="questions[i].id" v-for="(q, i) in questions" :data="questions[i]" :no="i" :answer-count="answerCount"
                                      @removeQuestion="removeQuestion" @onImageSelected="onImageSelected"></Question>
                            </div>
                            <div v-if="questions.length > 0" class="flex justify-between">
                                <button class="btn btn-secondary" type="button" @click="save()">KAYDET</button>
                                <button v-if="!isEditMode" class="btn btn-yellow" type="button" @click="addQuestion()">Yeni Soru</button>
                            </div>
                        </div>
                        <div v-else class="alert alert-success">
                            {{ isEditMode ? 'Soru başarıyla düzenlendi!' : 'Sorular başarıyla eklendi!' }}
                        </div>
                    </div>
                </TransitionChild>
            </div>
            <ProgressBarModal ref="progressBarModalRef"></ProgressBarModal>
            <div v-show="isLoading" class="fixed w-full h-full top-1/2 left-1/2 text-center bg-black bg-opacity-10 z-10" style="transform: translate(-50%,-50%)">
                <div class="w-full h-full flex items-center justify-center">
                    <pulse-loader :color="'#03B0B9'" :size="'12px'"></pulse-loader>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
    import {ref, computed} from 'vue'
    import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    import ProgressBarModal from '../../components/modal/ProgressBarModal.vue'
    import Question from "./Question.vue";

    const emit = defineEmits(['getQuestions']);

    const isLoading = ref(false);
    const isModalOpen = ref(false);
    const isEditMode = ref(false);
    const isFinished = ref(false);
    const progressBarModalRef = ref(null);

    const grades = ref([]);
    const courses = ref([]);
    const units = ref([]);
    const learningOutcomes = ref([]);
    const selectedGradeId = ref('');
    const selectedCourseId = ref('');
    const selectedUnitId = ref('');
    const selectedLearningOutcomeId = ref('');

    const questions = ref([]);
    let questionId = 0;

    const getCourses = async (courseId = null, unitId = null, learningOutcomeId = null) => {
        let response = await axios.get('/api/courses', {
            params: {
                gradeId: selectedGradeId.value
            }
        });
        courses.value = response.data.data;
        selectedCourseId.value = '';

        if (courseId) {
            selectedCourseId.value = courseId;
            await getUnits(unitId, learningOutcomeId);
        }
    }

    const getUnits = async (unitId = null, learningOutcomeId = null) => {
        let response = await axios.get('/api/units', {
            params: {
                'gradeId': selectedGradeId.value,
                'courseId': selectedCourseId.value
            }
        });
        units.value = response.data.data;
        selectedUnitId.value = '';

        if (unitId) {
            selectedUnitId.value = unitId;
            await getLearningOutcomes(learningOutcomeId);
        }
    }

    const getLearningOutcomes = async (learningOutcomeId = null) => {
        let response = await axios.get('/icerik-yonetimi/getLearningOutcomes/' + selectedUnitId.value);
        learningOutcomes.value = response.data.data;
        selectedLearningOutcomeId.value = '';

        if (learningOutcomeId) {
            selectedLearningOutcomeId.value = learningOutcomeId;
        }
    }

    const showQuestions = () => {
        if (questions.value.length === 0) {
            addQuestion();
        }
    }

    const addQuestion = () => {
        const newQuestion = {
            id: questionId,
            questionImageUrl: null,
            correctAnswer: null,
            widthType: '',
            difficulty: '',
            solutionImageUrl: null,
            solutionVideoUrl: null,
            solutionText: null,
            hint: null,
            isChanged: false,
        };
        questions.value = [...questions.value, newQuestion];
        questionId++;
    }

    const removeQuestion = (i)  => {
        if (questions.value.length > 1) {
            questions.value.splice(i, 1);
        }
    }

    const onImageSelected = (questionNo, type, event) => {
        if (event.target.files.length > 0) {
            const formData = new FormData();
            formData.append('type', 'image');
            formData.append('file', event.target.files[0]);

            progressBarModalRef.value.isModalOpen = true;

            axios.post('/api/file-upload', formData, {
                onUploadProgress: uploadEvent => {
                    progressBarModalRef.value.percent = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
                }
            }).then(response => {
                if (type === "question") {
                    questions.value[questionNo].questionImageUrl = response.data.data.url;
                    questions.value[questionNo].questionImageTempFilename = response.data.data.name;
                } else if (type === "solution") {
                    questions.value[questionNo].solutionImageUrl = response.data.data.url;
                    questions.value[questionNo].solutionImageTempFilename = response.data.data.name;
                }
            }).catch(e => {
                let message = '';

                if (!e.response) {
                    message = 'Lütfen internet bağlantınızı kontrol ediniz!';
                } else if (e.response.status === 422) {
                    message = e.response.data.error;
                } else {
                    message = 'Bir hata oluştu! Lütfen tekrar deneyin.';
                }

                alert(message);
            }).then(() => {
                progressBarModalRef.value.isModalOpen = false;
            });
        }
    }

    const save = () => {
        isLoading.value = true;

        let endpoint = '/icerik-yonetimi/profesor/';

        if (isEditMode.value) {
            endpoint += 'duzenle/' + questions.value[0].id;
        } else {
            endpoint += 'ekle';
        }

        axios.post(endpoint, {
            gradeId: selectedGradeId.value,
            courseId: selectedCourseId.value,
            unitId: selectedUnitId.value,
            learningOutcomeId: selectedLearningOutcomeId.value,
            questions: questions.value,
        }).then((response) => {
            isLoading.value = false;

            if (response.data.error === "") {
                isFinished.value = true;
                emit('getQuestions');
            }
        }).catch(e => {
            isLoading.value = false;

            let message = '';

            if (!e.response) {
                message = 'Lütfen internet bağlantınızı kontrol ediniz!';
            } else if (e.response.status === 422) {
                Object.keys(e.response.data.errors).forEach(field => {
                    e.response.data.errors[field].forEach(errorMessage => {
                        let questionNo = null;
                        if (field.indexOf('.') > -1) {
                            questionNo = parseInt(field.substring(field.indexOf('.') + 1, field.indexOf('.', field.indexOf('.')+1))) + 1 ;
                        }
                        message += (questionNo ? questionNo + '. soruda ' : '') + errorMessage + "\n";
                    });
                });
            } else {
                message = 'Bir hata oluştu! Lütfen tekrar deneyin.';
            }

            alert(message);
        });
    }

    const closeModal = () => {
        if (isFinished.value || questions.value.length === 0 || !checkChanged() || confirm('Değişiklikleriniz kaydedilemedi, yine de çıkmak istiyor musunuz?')) {
            isModalOpen.value = false;
            resetModal();
        }
    }

    const checkChanged = () => {
        for (const [i, q] of questions.value.entries()) {
            if (q.isChanged) {
                return true;
            }
        }

        return false;
    }

    const resetModal = () => {
        isFinished.value = false;
        isEditMode.value = false;
        courses.value = [];
        units.value = [];
        learningOutcomes.value = [];
        selectedGradeId.value = '';
        selectedCourseId.value = '';
        selectedUnitId.value = '';
        selectedLearningOutcomeId.value = '';
        questions.value = [];
        questionId = 0;
    }

    const answerCount = computed( () => {
        return grades.value.find(grade => grade.id === selectedGradeId.value)?.answerCount || 0;
    });

    defineExpose({
        isModalOpen,
        isEditMode,
        selectedGradeId,
        grades,
        getCourses,
        questions,
    });
</script>
