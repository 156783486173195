document.addEventListener('DOMContentLoaded', function() {
    let sliderContainers = document.getElementsByClassName('derslig-slider-container');

    if (sliderContainers.length > 0) {
        for (let sliderContainer of sliderContainers){
            let leftBtn = sliderContainer.querySelector('.slider-left-btn');
            let rightBtn = sliderContainer.querySelector('.slider-right-btn');
            let slideContainer = sliderContainer.querySelector('.slide-container');
            let items = slideContainer.querySelectorAll('.slide');

            items[0].classList.add('current');
            items[1]?.classList.add('next');
            items[items.length - 1]?.classList.add('prev');

            leftBtn.addEventListener("click", e => {
                let currentItem = slideContainer.querySelector('.slide.current');
                let nextItem = slideContainer.querySelector('.slide.next');
                let prevItem = slideContainer.querySelector('.slide.prev');

                currentItem.classList.remove('current');
                currentItem.classList.add('next');
                currentItem.classList.add('in-transition');
                prevItem.classList.remove('prev');
                prevItem.classList.add('current');
                nextItem.classList.remove('next');
                prevItem.previousElementSibling ? prevItem.previousElementSibling.classList.add('prev') : items[items.length - 1].classList.add('prev');

                setTimeout(() => {
                    currentItem.classList.remove('in-transition');
                }, 200);
            });

            rightBtn.addEventListener("click", e => {
                let currentItem = slideContainer.querySelector('.slide.current');
                let nextItem = slideContainer.querySelector('.slide.next');
                let prevItem = slideContainer.querySelector('.slide.prev');

                currentItem.classList.remove('current');
                currentItem.classList.add('prev');
                currentItem.classList.add('in-transition');
                nextItem.classList.remove('next');
                nextItem.classList.add('current');
                prevItem.classList.remove('prev');
                nextItem.nextElementSibling ? nextItem.nextElementSibling.classList.add('next') : items[0].classList.add('next');

                setTimeout(() => {
                    currentItem.classList.remove('in-transition');
                }, 200);
            });
        }
    }
});
