<template>
    <TransitionRoot as="template" :show="isModalOpen">
        <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="closeModal()">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-0 text-center">
                <TransitionChild as="template" enter="ease-out duration-500" enter-from="opacity-0"
                                 enter-to="opacity-100"
                                 leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-black bg-opacity-75 transition-opacity"/>
                </TransitionChild>
                <TransitionChild as="template" enter="ease-out duration-300"
                                 enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                 enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                 leave-from="opacity-100 translate-y-0 sm:scale-100"
                                 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="inline-block bg-white rounded-lg shadow-xl transform transition-all w-full max-w-19/20 border border-primary-default">
                        <div class="flex justify-between items-center p-2">
                            <template v-if="props.managementMode">
                                <DialogTitle class="text-sm sm:text-lg leading-6 font-bold text-gray-900">
                                    Cevap Anahtarı Düzenle
                                </DialogTitle>
                                <div class="flex space-x-2">
                                    <button class="btn btn-sm btn-gray" @click="closeModal()">
                                        Vazgeç
                                    </button>
                                    <button class="btn btn-sm" @click="closeAndSaveModal()">
                                        Kaydet
                                    </button>
                                </div>
                            </template>
                            <template v-else>
                                <DialogTitle as="h3" class="text-lg leading-6 font-bold text-gray-900 flex items-center">
                                    <div class="flex items-center">
                                        <img class="fill-current w-12 h-12"
                                             :src="'/img/documents/types/' + props.data?.type_id + '.svg'"/>
                                        <span class="ml-2 text-xs xs:text-sm sm:text-base">
                                            {{ props.data?.title }}
                                        </span>
                                    </div>
                                </DialogTitle>

                                <div v-if="props.data?.type?.auto_assessment === 0" class="flex space-x-2"> <!-- Manuel kontrollü ödevse -->
                                    <a v-if="fileUrl" target="_blank" :href="fileUrl" class="btn btn-xs sm:btn-sm btn-yellow">
                                        Dosyayı İndir
                                    </a>
                                    <div v-if="!isTaskFinished && props.data.file_upload" class="relative inline-block overflow-hidden cursor-pointer">
                                        <button type="button" class="btn btn-xs sm:btn-sm inline-flex cursor-pointer">
                                            <svg class="w-4 h-4" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm6.905 9.97a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72V18a.75.75 0 001.5 0v-4.19l1.72 1.72a.75.75 0 101.06-1.06l-3-3z" clip-rule="evenodd"></path>
                                                <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z"></path>
                                            </svg>
                                            <span class="ml-1">Dosya Yükle</span>
                                        </button>
                                        <input type="file" class="absolute top-0 left-0 text-8xl opacity-0 cursor-pointer" title="Dosya yükle" @change="onDocumentSelected" />
                                    </div>

                                    <button class="btn btn-xs sm:btn-sm  btn-gray" @click="closeModal()">
                                        Kapat
                                    </button>
                                </div>
                                <template v-else>  <!-- Otomatik kontrollü ödevse -->
                                    <div v-if="isTaskFinished" class="flex space-x-2">  <!-- Ödev bitmişse -->
                                        <button v-if="isResultsOpen" class="btn btn-sm" @click="isResultsOpen = false">
                                            Dokümanı Gör
                                        </button>
                                        <button v-else class="btn btn-sm" @click="isResultsOpen = true">
                                            Sonuçları Gör
                                        </button>

                                        <button class="btn btn-sm btn-gray" @click="closeModal()">
                                            Kapat
                                        </button>
                                    </div>
                                    <div v-else class="flex space-x-2"> <!-- Ödev bitmemişse -->
                                        <button class="btn btn-sm btn-gray" @click="closeAndSaveModal()">
                                            Sonra Devam Et
                                        </button>
                                        <button class="btn btn-sm" @click="finishTask()">
                                            Ödevi Gönder
                                        </button>
                                    </div>
                                </template>
                            </template>
                        </div>
                        <div class="w-full min-h-[85vh] h-max flex border-t-2 border-primary-default">
                            <div class="w-full h-full"> <!-- Doküman veya sonuçların olduğu sol frame -->
                                <div v-if="isResultsOpen && result" class="p-4 text-left">    <!-- Sonuçlar Kısmı -->
                                    <div class="p-2 grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-5 gap-4 text-center font-semibold lg:divide-x-2 lg:divide-gray-400 lg:rounded-xl lg:shadow-flat">
                                        <div class="p-2 shadow-flat lg:shadow-none rounded-xl">
                                            <div class="text-xs xs:text-sm md:text-base">Başarı Oranı</div>
                                            <div class="mt-2 text-status-success text-2xl">{{ successRate }}</div>
                                        </div>
                                        <div class="p-2 shadow-flat lg:shadow-none rounded-xl">
                                            <div class="text-xs xs:text-sm md:text-base">Doğru Sayısı</div>
                                            <div class="mt-2 text-status-success text-2xl">{{ result.correct }}</div>
                                        </div>
                                        <div class="p-2 shadow-flat lg:shadow-none rounded-xl">
                                            <div class="text-xs xs:text-sm md:text-base">Yanlış Sayısı</div>
                                            <div class="mt-2 text-status-danger text-2xl">{{ result.wrong }}</div>
                                        </div>
                                        <div class="p-2 shadow-flat lg:shadow-none rounded-xl">
                                            <div class="text-xs xs:text-sm md:text-base">Boş Sayısı</div>
                                            <div class="mt-2 text-status-warning text-2xl">{{ result.empty }}</div>
                                        </div>
                                        <div class="p-2 shadow-flat lg:shadow-none rounded-xl">
                                            <div class="text-xs xs:text-sm md:text-base">Puan</div>
                                            <div class="mt-2 text-primary-light text-2xl">{{ result.score }}</div>
                                        </div>
                                    </div>
                                    <div class="mt-4 font-bold text-secondary text-sm">
                                        Ödev {{ result.finish_time }} tarihinde gönderildi.
                                    </div>
                                    <div class="mt-4 text-sm">
                                        <h5>Kazanılan puan nasıl hesapladı?</h5>
                                        <div>Ödevden maksimum 25 puan kazanırsınız. Bu puan, ödevdeki başarı oranınızın 25 ile çarpılmasıyla hesaplanır.</div>
                                    </div>
                                </div>
                                <!-- Doküman Gösterilen Alan -->
                                <iframe v-else :src="props.filesUrl+'vendor/pdfjs/web/viewer.html?file='+props.data.full_url"
                                     class="min-h-[85vh] h-max" width="100%" height="100%"></iframe>
                            </div>
                            <div class="shrink-0 min-h-[85vh] h-max bg-gray-600 overflow-x-hidden"> <!-- Cevap Anahtarının olduğu sağ frame -->
                                <div v-if="props.data?.question_count" :key="i" v-for="(m, i) in props.data.question_count"
                                     class="p-1 sm:p-2 w-full h-8 lg:h-12 flex justify-between items-center bg-gray-600">
                                    <div v-if="isTaskFinished">
                                        <svg v-if="props.data.answers2.split(',')[i] == answers[i]" class="w-4 h-4 text-green-700 stroke-current stroke-2" viewBox="0 0 1792 1792" fill="currentColor">
                                            <path d="M1671 566c0 25-10 50-28 68l-724 724-136 136c-18 18-43 28-68 28s-50-10-68-28l-136-136-362-362c-18-18-28-43-28-68s10-50 28-68l136-136c18-18 43-28 68-28s50 10 68 28l294 295 656-657c18-18 43-28 68-28s50 10 68 28l136 136c18 18 28 43 28 68z"></path>
                                        </svg>
                                        <svg v-if="answers[i] !== null && props.data.answers2.split(',')[i] != answers[i]" class="w-4 h-4 text-red-700 stroke-current stroke-2" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"></path>
                                        </svg>
                                        <svg v-if="answers[i] === null" class="w-4 h-4 text-yellow-default stroke-current stroke-3" viewBox="0 0 32 32" fill="currentColor">
                                            <path d="M 16 4 C 9.382813 4 4 9.382813 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z M 16 6 C 21.535156 6 26 10.464844 26 16 C 26 21.535156 21.535156 26 16 26 C 10.464844 26 6 21.535156 6 16 C 6 10.464844 10.464844 6 16 6 Z"></path>
                                        </svg>
                                    </div>
                                    <div class="text-white text-sm sm:text-base lg:text-lg font-bold" :class="{'flex-grow mr-2': !isTaskFinished, 'mx-2': isTaskFinished}">
                                        {{ (i + 1) }}
                                    </div>
                                    <div class="flex items-center h-full text-xs lg:text-lg text-white" :class="{'space-x-1': isTaskFinished, 'space-x-2': !isTaskFinished}"
                                         v-show="props.data.answer_count">
                                        <div :key="i+'_'+j" v-for="(n, j) in props.data.answer_count" @click="!isTaskFinished && saveAnswer(i, j)"
                                             class="w-5 h-5 lg:w-8 lg:h-8 flex items-center justify-center font-bold text-white border border-white rounded-full select-none outline-none"
                                             :class="{'cursor-pointer' : !isTaskFinished,
                                             'bg-gray-900 border-gray-900': !isTaskFinished && answers[i] === j,
                                             'bg-green-700': isTaskFinished && props.data.answers2.split(',')[i] == j,
                                             'bg-red-700': isTaskFinished && answers[i] === j && props.data.answers2.split(',')[i] != answers[i]}"
                                        >
                                            {{ String.fromCharCode(65 + j) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="isLoading"
                             class="absolute w-full h-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center bg-black bg-opacity-10 z-10">
                            <div class="w-full h-full flex items-center justify-center">
                                <pulse-loader :color="'#03B0B9'" :size="'12px'"></pulse-loader>
                            </div>
                        </div>
                        <ProgressBarModal ref="progressBarModalRef"></ProgressBarModal>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
    import {ref, computed, watch} from 'vue'
    import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    import ProgressBarModal from '../../components/modal/ProgressBarModal.vue'
    import { useToast } from "vue-toastification";

    const props = defineProps({managementMode: Boolean, solutionMode: Boolean, data: Object, filesUrl: String});
    const emit = defineEmits(['setAnswers', 'setTaskResult']);

    const isModalOpen = ref(false);
    const isLoading = ref(false);
    const isTaskFinished = ref(false);
    const isResultsOpen = ref(false);
    const answers = ref([]);
    const result = ref(null);
    const fileUrl = ref(null);
    const toast = useToast();

    const data = ref({
        'document_temp_id': '',
        'previous_temp_id': ''
    });
    const progressBarModalRef = ref(null);
    const fileInputRef = ref(null);

    const loadAnswers = (studentAnswers) => {
        if (props.managementMode) {
            if (props.data.answers2 && props.data.answers2.length > 0) {
                answers.value = props.data.answers2.split(',').map(item => parseInt(item));
            }
        } else {
            answers.value = studentAnswers.split(',').map(item => item === "null" ? null : parseInt(item));
        }
    }

    const closeModal = () => {
        isModalOpen.value = false;
    }

    const closeAndSaveModal = () => {
        if (props.managementMode) {
            if (filter(answers.value).length !== props.data.question_count) {
                alert('Lütfen tüm soruların cevaplarını giriniz.');
                return;
            }

            emit('setAnswers', answers.value);
        }

        isModalOpen.value = false;
    }

    const getStudentData = (studentId = '') => {
        // Otomatik kontrollü doküman ödeviyse (Yaprak Test, Mini Sınav)
        if (props.data.type.auto_assessment) { // Yaprak test ve mini sınav ise
            isLoading.value = true;

            axios.get('/api/dokuman/' + props.data.id + '?studentId=' + studentId).then((response) => {
                if (response.data.error === "") {
                    loadAnswers(response.data.data.studentAnswers);
                    result.value = response.data.data.result;
                }
            }).catch((error) => {

            }).then(() => {
                isLoading.value = false;

                if (!isTaskFinished.value && result.value?.finished_at) {
                    finishTask();
                }
            });
        } else {
            isResultsOpen.value = false;
        }
    }

    const saveAnswer = (questionNo, answer) => {
        let newAnswer = answers.value[questionNo] !== answer ? answer : null;

        if (props.managementMode) {
            answers.value[questionNo] = newAnswer;
        } else {
            isLoading.value = true;

            let tempAnswers = answers.value;
            tempAnswers[questionNo] = newAnswer;

            axios.post("/api/dokuman/cevap-kaydet", {
                documentId: props.data.id,
                answers: tempAnswers
            }).then((response) => {
                if (response.data.error === "") {
                    answers.value[questionNo] = newAnswer;
                }
            }).catch((error) => {

            }).then(() => {
                isLoading.value = false;
            });
        }
    }

    const finishTask = () => {
        isLoading.value = true;

        axios.post('/api/dokuman/bitir', {
            documentId: props.data.id,
            answers: answers.value
        }).then((response) => {
            if (response.data.error === "") {
                loadAnswers(response.data.data.studentAnswers);
                result.value = response.data.data.results;
                isTaskFinished.value = true;
                isResultsOpen.value = true;
                emit('setTaskResult', response.data.data.learningTaskResult);

                if (!response.data.data.learningTaskResult) {
                    closeModal();
                }
            }
        }).catch((error) => {
            if (error && error.response && error.response.data && error.response.data.error) {
                alert(error.response.data.error);
            } else {
                alert("Hata oluştu!");
            }

            closeModal();
        }).then(() => {
            isLoading.value = false;
        });
    }

    const successRate = computed( () => {
        return result.value ? '%' + Math.round(result.value.correct / (result.value.correct + result.value.wrong + result.value.empty) * 100) : '-';
    });

    const onDocumentSelected = (event) => {
        if (event.target.files.length > 0) {
            const formData = new FormData();
            formData.append('is_temp_file', 0);
            formData.append('type', 'task-document');
            formData.append('file', event.target.files[0]);
            formData.append('filename', event.target.files[0].name);
            formData.append('task_id', props.data.task_id);

            progressBarModalRef.value.isModalOpen = true;

            axios.post('/api/file-upload', formData, {
                onUploadProgress: uploadEvent => {
                    progressBarModalRef.value.percent = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
                }
            }).then(response => {
                if (response.data.error === '') {
                    fileUrl.value = response.data.data.url;
                    emit('setTaskResult', response.data.data.taskResult);

                    toast.success('Dosya başarıyla yüklendi', {
                        type: 'success',
                        timeout: 3000
                    });
                } else {
                    toast.error(response.data.error, {
                        type: 'error',
                        timeout: 3000
                    });
                }
            }).catch(e => {
                fileUrl.value = '';
                fileInputRef.value.value = '';

                let message = '';

                if (!e.response) {
                    message = 'Lütfen internet bağlantınızı kontrol ediniz!';
                } else if (e.response.status === 422) {
                    Object.keys(e.response.data.errors).forEach(field => {
                        e.response.data.errors[field].forEach(errorMessage => {
                            message += errorMessage + "\n";
                        });
                    });
                } else {
                    message = 'Bir hata oluştu! Lütfen başka bir dosya ile tekrar deneyin.';
                }

                toast.error(message, {
                    type: 'error',
                    timeout: 3000
                });
            }).then(() => {
                progressBarModalRef.value.isModalOpen = false;
            });
        } else {
            fileUrl.value = '';

            toast.error('Lütfen dosya seçiniz!', {
                type: 'error',
                timeout: 3000
            });
        }
    }

    const filter = (data) => {
        return data.filter(item => item !== null && item !== undefined);
    }

    watch(() => props.data, () => {
        if (props.data) {
            getStudentData();
        }
    });

    defineExpose({
        isModalOpen,
        isResultsOpen,
        isTaskFinished,
        answers,
        loadAnswers,
        getStudentData,
        fileUrl,
    });
</script>
