<template>
    <TransitionRoot as="template" :show="isModalOpen">
        <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto">
            <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-500" enter-from="opacity-0"
                                 enter-to="opacity-100"
                                 leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-black bg-opacity-75 transition-opacity"/>
                </TransitionChild>

                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300"
                                 enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                 enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                 leave-from="opacity-100 translate-y-0 sm:scale-100"
                                 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="sm:max-w-lg w-full inline-block sm:align-middle text-left p-2 sm:p-4 bg-white rounded-lg shadow-xl transform transition-all border border-gray-990">
                        <DialogTitle as="h3" class="text-lg leading-6 font-semibold text-gray-900 mb-2">
                            Değerlendirme Ayarları
                        </DialogTitle>
                        <div class="mt-2 font-medium text-xs sm:text-sm text-gray-900">
                            <div>Konu özeti veya çalışma kağıdı için değerlendirme öğretmen tarafından yapılır.</div>
                            <div class="mt-2">
                                <label class="font-semibold font-gray-990">Öğrenciler için Dosya Yükleme</label>
                                <select name="students_upload" class="mt-1 w-full form-control" v-model="settings.fileUpload">
                                    <option :value="null">Lütfen dosya yükleme ayarı seçiniz</option>
                                    <option :value="0">Öğrenciler dosya yüklemesin</option>
                                    <option :value="1">Öğrenciler dosya yüklesin</option>
                                </select>
                            </div>
                            <div class="mt-2">
                                <label class="font-semibold font-gray-990">Ödevin Değerlendirme Türü</label>
                                <div class="mt-1 flex items-center">
                                    <input id="assess_type_1" type="radio" class="mr-1 form-control" v-model="settings.assessmentType" :value="2" />
                                    <label for="assess_type_1">Puan (0 - 100)</label>
                                    <input id="assess_type_2" type="radio" class="ml-4 mr-1 form-control" v-model="settings.assessmentType" :value="1" />
                                    <label for="assess_type_2">Yapılma Durumu (Yapıldı - Yapılmadı)</label>
                                </div>
                            </div>
                        </div>
                        <button class="mt-2 btn btn-sm" @click="save">
                            Kaydet
                        </button>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
    import {ref} from 'vue'
    import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'

    const emit = defineEmits(['onSaved']);

    const settings = ref({
        'fileUpload': null,
        'assessmentType': null,
    });

    const isModalOpen = ref(false);

    defineExpose({ isModalOpen });

    const save = () => {
        emit('onSaved', settings);
        isModalOpen.value = false;
    }
</script>
