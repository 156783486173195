let CountdownManager = (function(){
    function CountdownManager() {
        this.timer = null;
        this.isCountdownRunning = false;
        this.isBlurred = false;
        this.pauseOnBlur = false;
        this.remainingSeconds = -1;
    }

    CountdownManager.prototype.setContainer = function(container){
        this.container = container;
    }

    CountdownManager.prototype.setEndFunction = function(endFunction){
        this.endFunction = endFunction;
    }

    CountdownManager.prototype.setResumeFunction = function(resumeFunction){
        this.resumeFunction = resumeFunction;
    }

    CountdownManager.prototype.setPauseFunction = function(pauseFunction){
        this.pauseFunction = pauseFunction;
    }

    CountdownManager.prototype.setPauseOnBlur = function(pauseOnBlur){
        this.pauseOnBlur = pauseOnBlur;

        if (this.pauseOnBlur) {
            window.addEventListener("focus", () => {
                this.resume();
                this.isBlurred = false;

                if (this.isCountdownRunning) {
                    this.onBlurFunction();
                }
            });

            window.addEventListener("blur", () => {
                this.isBlurred = true;
                this.pause();
            });
        }
    }

    CountdownManager.prototype.setOnBlurFunction = function(onBlurFunction){
        this.onBlurFunction = onBlurFunction;
    }

    CountdownManager.prototype.start = function(remainingSeconds) {
        this.remainingSeconds = remainingSeconds;

        if (this.container && this.remainingSeconds >= 1) {
            this.draw();
            this.resume(false);
            this.isCountdownRunning = true;
        }
    }

    CountdownManager.prototype.resume = function(triggerResumeFunction = true) {
        clearInterval(this.timer);

        if ((this.isBlurred && this.isCountdownRunning || !this.isBlurred && !this.isCountdownRunning) && this.remainingSeconds >= 0) {
            this.isCountdownRunning = true;
            this.timer = setInterval( () => this.timerFunction(), 1000);

            if (this.resumeFunction && triggerResumeFunction) {
                this.resumeFunction();
            }
        }
    }

    CountdownManager.prototype.pause = function(triggerPauseFunction = true) {
        if (!this.isBlurred) {
            this.isCountdownRunning = false;
        }
        clearInterval(this.timer);

        if (this.remainingSeconds >= 0 && this.pauseFunction && triggerPauseFunction) {
            this.pauseFunction();
        }
    }

    CountdownManager.prototype.draw = function() {
        let days = ("" + parseInt(this.remainingSeconds / (3600 * 24)));
        let hours = ("" + parseInt((this.remainingSeconds - days * 24 * 3600) / 3600)).padStart(2, "0");
        let mins = ("" + parseInt((this.remainingSeconds - days * 24 * 3600 - hours * 3600) / 60)).padStart(2, "0");
        let seconds = ("" + (this.remainingSeconds % 60)).padStart(2, "0");

        if (this.container.querySelector(".countdown-day-value")) {
            this.container.querySelector(".countdown-day-value").innerText = days;
        }
        this.container.querySelector(".countdown-hour-value").innerText = hours;
        this.container.querySelector(".countdown-minute-value").innerText = mins;
        this.container.querySelector(".countdown-second-value").innerText = seconds;
    }

    CountdownManager.prototype.timerFunction = function(){
        this.remainingSeconds--;

        if (this.remainingSeconds < 0) {
            clearInterval(this.timer);
            this.isCountdownRunning = false;

            if (this.endFunction) {
                this.endFunction();
            }
            return;
        }

        this.draw();
    }

    CountdownManager.prototype.constructor = CountdownManager;

    return new CountdownManager;
})();

export { CountdownManager }
