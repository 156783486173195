<template>
    <button v-if="props.task.assessment_type" type="button" class="mb-4 btn btn-sm" @click="openTask()">
        Ödevi İncele
    </button>
    <div v-if="taskResults.length > 0" class="table-responsive">
        <table class="table table-striped student-list">
            <thead>
            <tr>
                <th>No</th>
                <th>Öğrenci Adı Soyad</th>
                <th>Durum</th>
                <th>Performans</th>
                <th>İçerik Süresi</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="taskResult in taskResults">
                <td>
                    {{ taskResult.student.school_number ?? '-' }}
                </td>
                <td>
                    {{ taskResult.student.full_name }}
                </td>
                <td>
                    {{ taskResult.status_text }}
                </td>
                <td>
                    <div class="flex items-center" v-if="props.task.assessment_type">
                        <select v-if="props.task.assessment_type === 1" v-model="taskResult.score" class="form-control"
                                @change="saveScore($event, taskResult)">
                            <option :value="null">Değerlendirme Sonucu Seçiniz</option>
                            <option value="1">Yapıldı</option>
                            <option value="0">Yapılmadı</option>
                        </select>
                        <input v-else-if="props.task.assessment_type === 2" type="text" class="form-control"
                               placeholder="Puan (0-100)" :value="taskResult.score"
                               @change="saveScore($event, taskResult)" />

                        <a v-if="props.task.file_upload === 1 && taskResult.file_url"
                           class="ml-2 sm:ml-4 btn btn-xs sm:btn-sm btn-yellow"
                           :href="taskResult.file_url" target="_blank">
                            Yüklenen Dosyayı İndir
                        </a>
                    </div>
                    <div v-else>
                        {{ taskResult.score ? '%' + taskResult.score : '-' }}
                    </div>
                </td>
                <td>
                    {{ taskResult.duration }}
                </td>
                <td>
                    <div v-if="taskResult.status === 2" class="flex space-x-2">  <!-- Ödev bitmişse -->
                        <a v-if="props.task.learning_content && taskResult.turn" target="_blank" class="btn btn-sm"
                           :href="'/kurslar/icerik/' + props.task.learning_content.id+'?trackStudentId=' + taskResult.student.id + '&turn=' + taskResult.turn">
                            İncele
                        </a>
                        <a v-else-if="props.task.learning_test" target="_blank" class="btn btn-sm"
                           :href="'/kurslar/test/' + props.task.learning_test.id+'?trackStudentId=' + taskResult.student.id">
                            İncele
                        </a>
                        <button v-else-if="props.task.document && props.task.document.type.auto_assessment"
                                type="button" class="btn btn-sm" @click="openResult(taskResult)">
                            İncele
                        </button>

                        <a v-if="props.task.learning_content || props.task.learning_test || props.task.document.type.auto_assessment"
                           class="btn btn-sm btn-yellow reset-btn"
                           :href="'/okul/panel/odevler/sifirla/' + taskResult.id" @click="reset">
                            Sıfırla
                        </a>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <div class="mt-4">
            <div v-if="props.task.assessment_type"> <!-- Manual Kontrol varsa -->
                <b>Tüm öğrencileri değerlendir:</b>
                <select v-if="props.task.assessment_type === 1" class="ml-2 form-control"
                        @change="saveScore($event)">
                    <option :value="null">Değerlendirme Sonucu Seçiniz</option>
                    <option value="1">Yapıldı</option>
                    <option value="0">Yapılmadı</option>
                </select>
                <input v-else-if="props.task.assessment_type === 2" type="text" class="ml-2 form-control"
                       placeholder="Puan (0-100)"
                       @change="saveScore($event)"/>
            </div>
        </div>
    </div>
    <div v-else class="mt-4 alert alert-info text-center">Hiç sonuç bulunmamaktadır</div>
    <DocumentAnswerModal ref="documentAnswerModal" :files-url="props.filesUrl" :data="props.task.document"></DocumentAnswerModal>
    <div v-show="isLoading" class="absolute w-full h-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center bg-black bg-opacity-10 z-10">
        <div class="w-full h-full flex items-center justify-center">
            <pulse-loader :color="'#03B0B9'" :size="'12px'"></pulse-loader>
        </div>
    </div>
</template>

<script setup>
    import {ref} from "vue";
    import DocumentAnswerModal from "../components/modal/DocumentAnswerModal.vue";
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    import {useToast} from "vue-toastification";

    const props = defineProps(['task', 'taskResults', 'filesUrl']);

    const isLoading = ref(false);
    const task = ref(null);
    const taskResults = ref(props.taskResults);
    const documentAnswerModal = ref(null);
    const toast = useToast();

    const openTask = () => {
        documentAnswerModal.value.isModalOpen = true;
    }

    const openResult = (taskResult) => {
        documentAnswerModal.value.isTaskFinished = taskResult.status === 2;
        documentAnswerModal.value.getStudentData(taskResult.student_id);

        // Ödevin bitme durumuna göre results frame'ini göster veya gizle
        documentAnswerModal.value.isResultsOpen = taskResult.status === 2;

        documentAnswerModal.value.isModalOpen = true;
    }

    const saveScore = (event, taskResult = null) => {
        isLoading.value = true;

        axios.post('/api/tasks/save-score', {
            'learningTaskResultId': taskResult ? taskResult.id : null,
            'learningTaskId': taskResult ? null : props.task.id,
            'score': event.target.value
        }).then((response) => {
            if (response.data.error === "" && response.data.data) {
                response.data.data.forEach(newTaskResult => {
                    let taskResult = taskResults.value.find(tr => tr.id === newTaskResult.id);
                    taskResult.score = newTaskResult.score;
                    taskResult.status = newTaskResult.status;
                    taskResult.status_text = newTaskResult.status_text;
                });

                let message = (response.data.data.length === 1 ? (response.data.data[0].student.full_name + ' isimli öğrencinin') : 'Tüm öğrencilerin') + ' notu başarıyla güncellendi';

                toast.success(message, {
                    type: 'success',
                    timeout: 3000
                });
            } else {
                toast.error(response.data.error, {
                    type: 'error',
                    timeout: 3000
                });
            }
        }).catch((error) => {
            toast.error(error.response.data.error, {
                type: 'error',
                timeout: 3000
            });
        }).then(() => {
            isLoading.value = false;
        });
    }

    const reset = (e) => {
        e.preventDefault();

        if (confirm('Öğrencinin ödevini yeniden yapabilmesi için sonucunu sıfırlamak istediğinize emin misiniz?')) {
            document.location.href = e.target.href;
        }
    }
</script>
