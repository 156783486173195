<template>
    <label class="toggle">
        <input type="checkbox" class="sr-only" v-model="checkedValue" v-bind:id="props.id" @change="onChanged">
        <span class="toggle-line"></span>
        <span class="toggle-dot"></span>
    </label>
</template>

<script setup>
    import { ref } from "vue";

    const props = defineProps(['id', 'checked']);
    const emit = defineEmits(['publish'])

    const checkedValue = ref(props.checked);

    const onChanged = (e) => {
        emit('publish', props.id, checkedValue.value)
    }
</script>
