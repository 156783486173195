<template>
    <div v-if="taskResults.length > 0" class="table-responsive">
        <table class="table table-striped item-list waiting">
            <thead>
            <tr>
                <th>Ders</th>
                <th>İçerik</th>
                <th>Başlangıç Tarihi</th>
                <th>Bitiş Tarihi</th>
                <th>Başarı Oranı</th>
                <th>Durum</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="taskResult in taskResults">
                <td>{{ taskResult.learning_task.course.name }}</td>
                <td>{{ taskResult.learning_task.title }}</td>
                <td>{{ taskResult.learning_task ? taskResult.learning_task.start_time : '-' }}</td>
                <td>{{ taskResult.learning_task ? taskResult.learning_task.end_time : '-' }}</td>
                <td>
                    <template v-if="taskResult.score !== null">
                        <template v-if="taskResult.learning_task.assessment_type === 1">
                            {{ taskResult.score ? 'Yapıldı' : 'Yapılmadı' }}
                        </template>
                        <template v-else>
                            % {{ taskResult.score }}
                        </template>
                    </template>
                    <template v-else>
                        -
                    </template>
                </td>
                <td>
                    <div v-if="!taskResult.learning_task.is_started"> <!-- Ödevin henüz aktif değilse -->
                        Ödev başlamadı
                    </div>
                    <div v-else-if="!taskResult.learning_task.is_ended"> <!-- Ödev aktifse-->
                        <div v-if="taskResult.learning_task.learning_content || taskResult.learning_task.learning_test">
                            <div v-if="taskResult.is_finished">
                                Tamamlandı
                            </div>
                            <div v-else>
                                <a class="btn btn-sm btn-yellow" :href="taskResult.learning_task.url">
                                    {{ taskResult.is_waiting ? 'Ödeve Başla' : 'Ödeve Devam Et' }}
                                </a>
                            </div>
                        </div>
                        <div v-else-if="taskResult.learning_task.document">
                            <div v-if="taskResult.is_finished"> <!-- Ödev bitirilmişse -->
                                Tamamlandı
                                <button type="button" class="ml-2 btn btn-sm btn-yellow" @click="openTask(taskResult)">
                                    Ödevi Gör
                                </button>
                            </div>
                            <div v-else-if="taskResult.is_waiting"> <!-- Ödeve başlanmamışsa -->
                                <button type="button" class="btn btn-sm btn-yellow" @click="openTask(taskResult)">
                                    Ödeve Başla
                                </button>
                            </div>
                            <div v-else> <!-- Ödeve başlanmışsa -->
                                <template v-if="!taskResult.learning_task.document.type.auto_assessment"> <!-- Manuel Kontrolse -->
                                    Kontrol bekliyor

                                    <button type="button" class="btn btn-sm btn-yellow" @click="openTask(taskResult)">
                                        Ödevi Gör
                                    </button>
                                </template>
                                <button v-else type="button" class="btn btn-sm btn-yellow" @click="openTask(taskResult)"> <!-- Otomatik --->
                                    Ödeve Devam Et
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-else> <!-- Ödevin tarihi dolmuşsa -->
                        <div v-if="taskResult.is_finished">
                            Tamamlandı
                        </div>
                        <div v-else>
                            Süresi geçti
                        </div>
                    </div>
                </td>
                <td>
                    <div v-if="taskResult.learning_task.description">
                        <popover icon="info" :text="taskResult.learning_task.description" color="text-black" position="left"></popover>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div v-else class="mt-4 alert alert-info text-center">
        <template v-if="props.user?.school?.is_premium">
            Şu anda aktif bir ödevin bulunmuyor.
            Aktif bir ödevinin olması için öğretmenlerinin ödev gönderimi yapması gerekmektedir.
        </template>
        <template v-else>
            Şu anda aktif bir ödevin bulunmuyor.
            Öğretmenlerini Derslig’e davet ederek sana ödev göndermesini sağlayabilirsin.
        </template>
    </div>
    <DocumentAnswerModal ref="documentAnswerModal" :solution-mode="true" :files-url="props.filesUrl" :data="activeTaskResult?.learning_task?.document"
                         @setTaskResult="setTaskResult"></DocumentAnswerModal>
</template>

<script setup>
    import {ref} from "vue";
    import DocumentAnswerModal from "../components/modal/DocumentAnswerModal.vue";
    import Popover from "../components/Popover.vue";

    const props = defineProps(['taskResults', 'filesUrl', 'user']);

    const taskResults = ref(props.taskResults);
    const documentAnswerModal = ref(null);
    const activeTaskResult = ref(null);

    const openTask = (taskResult) => {
        activeTaskResult.value = taskResult;
        activeTaskResult.value.learning_task.document.file_upload = taskResult.learning_task.file_upload;
        activeTaskResult.value.learning_task.document.task_id = taskResult.learning_task.id;

        // Ödevin bitme durumuna göre results frame'ini göster veya gizle
        documentAnswerModal.value.isTaskFinished = taskResult.status === 2;
        documentAnswerModal.value.isResultsOpen = taskResult.status === 2;

        // Dosya yüklüyse dosyayı modala aktar
        documentAnswerModal.value.fileUrl = taskResult.file_url;

        if (taskResult.status === 0 && (taskResult.learning_task.file_upload !== 1 || taskResult.file_url)) {
            startTask(taskResult);
        }

        documentAnswerModal.value.isModalOpen = true;
    }

    const startTask = (taskResult) => {
        axios.post('/api/dokuman/basla', {
            learningTaskResultId: taskResult.id
        }).then(response => {
            taskResult.status = response.data.data.status;
            taskResult.status_text = response.data.data.status_text;
            taskResult.is_waiting = response.data.data.is_waiting;
            taskResult.is_started = response.data.data.is_started;
            taskResult.is_finished = response.data.data.is_finished;
        }).catch((error) => {
        });
    }

    const setTaskResult = (newTaskResult) => {
        if (!newTaskResult) {
            alert("Bu ödev silindiği için sonucunuz kaydedilemedi!");
            return;
        }

        if (activeTaskResult.value) {
            activeTaskResult.value.status = newTaskResult.status;
            activeTaskResult.value.status_text = newTaskResult.status_text;
            activeTaskResult.value.is_waiting = newTaskResult.is_waiting;
            activeTaskResult.value.is_finished = newTaskResult.is_finished;
            activeTaskResult.value.is_started = newTaskResult.is_started;
            activeTaskResult.value.file_url = newTaskResult.file_url;
        }
    }
</script>
