<template>
    <div class="w-full flex justify-between items-center">
        <span class="font-bold">
            {{ content }}
        </span>
        <svg class="h-8 fill-current" x="0px" y="0px" viewBox="0 0 491.9 246"
             :class="{'text-green-700': type === 'success', 'text-red-700': type === 'wrong', 'text-toast-warning': type === 'info'}">
            <g>
                <g>
                    <circle cx="73.1" cy="170.6" r="30.5"/>
                    <circle cx="337.3" cy="86" r="30.5"/>
                    <circle cx="432.3" cy="147.8" r="25.3"/>
                    <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 82.6926 229.6937)" cx="176.3" cy="66.3" rx="56.3" ry="56.3"/>
                    <ellipse transform="matrix(2.463032e-03 -1 1 2.463032e-03 40.3043 443.4995)" cx="242.4" cy="201.5" rx="38.7" ry="38.7"/>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
    import {onMounted} from 'vue';

    export default {
        name: "Toast",
        props: {
            type: String,
            content: String
        },
        setup(props) {
            let toastAlertSound = new Audio("/assets/sounds/toast_alert.mp3");

            onMounted(() => {
                toastAlertSound.play();
            });
        }
    }
</script>
