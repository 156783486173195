<template>
    <div class="bg-white p-4 rounded-xl">
        <div class="mb-2 text-gray-960 font-bold text-center text-xs md:text-sm">{{ label }}</div>
        <div v-if="filterData.length" class="text-right">
            <select v-model="selectedFilterValue" class="form-control" @change="getData">
                <option value="">{{ filterDefaultOption }}</option>
                <option :key="filterDatum.id" v-for="filterDatum in filterData" :value="filterDatum.id">
                    {{ filterDatum.name }}
                </option>
            </select>
        </div>
        <div v-if="isLoading" class="mt-4">
            <div class="w-full h-full flex items-center justify-center">
                <clip-loader :color="'#03B0B9'" :size="'24px'"></clip-loader>
            </div>
        </div>
        <div v-else>
            <div v-if="isLoaded" :style="isHorizontal ? ('height: ' + chartHeight + 'rem') : ''">
                <Bar :data="chartData" :options="chartOptions" :key="chartVersion"  />
            </div>
            <div v-else class="mt-4 text-center text-xs text-secondary">
                {{ message ?? 'Veri yüklenemedi' }}
            </div>
        </div>
    </div>
</template>

<script setup>
    import {onMounted, ref, reactive} from "vue";
    import { Bar } from 'vue-chartjs';
    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
    import ClipLoader from "vue-spinner/src/ClipLoader.vue";

    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

    const props = defineProps({
        url: {type: String, required: true},
        label: {type: String, default: ''},
        isPercent: {type: Boolean, default: false},
        filterData: {type: Array, default: []},
        filterKey: {type: String, default: ''},
        filterDefaultOption: {type: String, default: ''}
    });

    const isLoaded = ref(false);
    const isLoading = ref(false);
    const isHorizontal = ref(false);
    const chartHeight = ref(0);
    const message = ref('');
    const selectedFilterValue = ref('');
    const chartVersion = ref(0);
    const chartData = reactive({
        labels: [],
        datasets: [{
            label: props.label,
            backgroundColor: '#0AB1B9',
            borderRadius: 5,
            maxBarThickness: 50,
            data: []
        }]
    });
    const chartOptions = ref({
        maintainAspectRatio: false,
        elements: {
            bar: {
                borderWidth: 1,
            }
        },
        plugins: {
            legend: {
                display: false
            }
        }
    });

    const loadChartOptions = () => {
        if (isHorizontal.value) {
            chartOptions.value = {
                ...chartOptions.value,
                indexAxis: 'y'
            }
        }

        if (props.isPercent) {
            let scalarLabel = isHorizontal.value ? 'x' : 'y';

            chartOptions.value = {
                ...chartOptions.value,
                scales: {
                    [scalarLabel]: {
                        ticks: {
                            callback: function (value) {
                                return "%" + value;
                            }
                        },
                        max: 100,
                        grace: '20%'
                    }
                }
            };

            chartOptions.value.plugins = {
                ...chartOptions.value.plugins,
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            return tooltipItem.dataset.label + ': %' + tooltipItem.dataset.data[tooltipItem.dataIndex];
                        }
                    }
                }
            };
        }
    }

    onMounted( () => {
        getData();
    });

    const getData = () => {
        isLoading.value = true;

        chartData.labels = [];
        chartData.datasets[0].data = [];

        let params = {};

        if (selectedFilterValue.value) {
            params.school_level_id = selectedFilterValue.value;
        }

        axios.get(props.url, {
            params: params
        }).then((response) => {
            if (response.data.error === "") {
                if (response.data.data) {
                    Object.entries(response.data.data).forEach(entry => {
                        const [key, value] = entry;

                        chartData.labels.push(key);
                        chartData.datasets[0].data.push(value);
                    });

                    if (chartData.labels.length > 5) {
                        isHorizontal.value = true;
                        chartData.datasets[0].maxBarThickness = 10;
                        chartHeight.value = Math.round(chartData.labels.length * 6 / 4);
                    }

                    if (chartData.labels.length > 0) {
                        loadChartOptions();
                        isLoaded.value = true;
                    } else {
                        message.value = 'Sistemde veri bulunamadı.';
                        isLoaded.value = false;
                    }
                }
            }
        }).catch((error) => {
        }).then(() => {
            isLoading.value = false;
            chartVersion.value++;
        });
    }
</script>
