<template>
    <div class="mb-2 p-4 text-sm text-gray-900 bg-gray-100 rounded-xl border border-primary-500">
        <div class="mb-2 flex justify-between">
            <div class="flex">
                <div class="font-bold text-lg mr-2">{{ props.no + 1 }})</div>
                <div>
                    <button type="button" class="btn btn-sm inline-flex" @click="questionImageFileUploadRef.click()">
                        <svg class="w-4 h-4 fill-current" viewBox="0 0 16 16">
                            <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path>
                            <path
                                d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"></path>
                        </svg>
                        <span class="ml-1">Soru Görseli Yükle</span>
                    </button>
                    <input v-show="false" type="file" ref="questionImageFileUploadRef" @change="$emit('onImageSelected', props.no, 'question', $event)">
                </div>
            </div>
            <div>
                <button type="button" @click="$emit('removeQuestion', props.no)">
                    <span aria-hidden="true" class="text-2xl font-extrabold">×</span>
                    <span class="sr-only">Sil</span>
                </button>
            </div>
        </div>
        <div class="mb-2">
            <a v-if="data.questionImageUrl" :href="data.questionImageUrl" class="inline-block cursor-zoom-in" target="_blank">
                <img class="h-64" :src="data.questionImageUrl"/>
            </a>
            <div v-else class="h-4 text-center">
                Soru görseli yüklenmemiş
            </div>
        </div>
        <div class="mb-2 p-2 flex space-x-2">
            <div :key="answerNo" v-for="(n, answerNo) in props.answerCount" @click="data.correctAnswer = answerNo"
                 class="w-full h-8 flex justify-center items-center rounded-xl cursor-pointer"
                 :class="data.correctAnswer === answerNo ? 'bg-primary-500 text-white' : 'bg-gray-300 text-gray-900'">
                {{ String.fromCharCode(65 + answerNo) }}
            </div>
        </div>
        <div class="mb-4 flex space-x-4">
            <select class="form-control" v-model="data.widthType">
                <option value="">Soru genişliği seçiniz</option>
                <option :value="1">Dar</option>
                <option :value="2">Geniş</option>
            </select>
            <select class="form-control" v-model="data.difficulty">
                <option value="">Soru zorluğu seçiniz</option>
                <option :key="i" v-for="i in [1,2,3,4,5]" :value="i">{{ i }}</option>
            </select>
        </div>
        <div class="mb-2 flex space-x-2 items-center">
            <div>
                <a v-if="data.solutionImageUrl" :href="data.solutionImageUrl" class="inline-block cursor-zoom-in" target="_blank">
                    <img class="h-32" :src="data.solutionImageUrl"/>
                </a>
                <div v-else class="p-2">
                    Çözüm görseli yüklenmemiş
                </div>
            </div>
            <div class="flex-column space-y-2">
                <div>
                    <button type="button" class="btn btn-sm inline-flex" @click="solutionImageFileUploadRef.click()">
                        <svg class="w-4 h-4 fill-current" viewBox="0 0 16 16">
                            <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path>
                            <path
                                d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"></path>
                        </svg>
                        <span class="ml-1">Çözüm Görseli Yükle</span>
                    </button>
                    <input v-show="false" type="file" ref="solutionImageFileUploadRef" @change="$emit('onImageSelected', props.no, 'solution', $event)">
                </div>
                <div v-if="data.solutionImageUrl">
                    <button type="button" class="btn btn-sm btn-gray inline-flex" @click="removeSolutionImage()">
                        <span class="ml-1">Çözüm Görselini Kaldır</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="mb-2">
            <input v-model="data.solutionVideoUrl" type="text" placeholder="Çözüm Videosu URL" class="form-control w-full">
        </div>
        <div>
            <textarea v-model="data.solutionText" placeholder="Çözüm Metni" class="form-control w-full"></textarea>
        </div>
        <div>
            <textarea v-model="data.hint" placeholder="İpucu Metni" class="form-control w-full"></textarea>
        </div>
    </div>
</template>

<script setup>
    import {ref, toRefs, watch} from 'vue'

    const props = defineProps(['no', 'data', 'answerCount'])
    const emit = defineEmits(['removeQuestion', 'onImageSelected'])

    const questionImageFileUploadRef = ref(null);
    const solutionImageFileUploadRef = ref(null);
    const data = ref({});

    data.value = toRefs(props.data);

    const removeSolutionImage = () => {
        data.value.solutionImageUrl = null;
        data.value.solutionImageTempFilename = null;
    }

    watch(data, (newData) => {
        emit('update:data', newData);
        data.value.isChanged = true;
    }, { deep: true });
</script>
