<template>
    <TransitionRoot as="template" :show="isModalOpen">
        <Dialog as="div" class="fixed z-50 inset-0" @close="isModalOpen = false">
            <div class="flex items-center justify-center text-center min-h-screen sm:min-h-full sm:h-full p-4">
                <TransitionChild as="template" enter="ease-out duration-500" enter-from="opacity-0" enter-to="opacity-100"
                                 leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-black bg-opacity-75 transition-opacity"/>
                </TransitionChild>

                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300"
                                 enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                 enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                 leave-from="opacity-100 translate-y-0 sm:scale-100"
                                 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="inline-block align-bottom bg-white rounded-lg shadow-xl transform transition-all
                    p-2 sm:p-4 border-2 border-gray-900 h-full">
                        <div class="bg-white pb-2">
                            <div class="mx-2 sm:mx-4 flex justify-between">
                                <DialogTitle as="h3" class="text-lg leading-6 font-semibold text-gray-900 mb-2">
                                    Soru Çözüm Videosu
                                </DialogTitle>
                                <div>
                                    <button class="btn btn-sm btn-yellow" @click="isModalOpen = false">
                                        Kapat
                                    </button>
                                </div>
                            </div>
                        </div>
                        <video class="max-h-9/10" :src="videoUrl" controls controlsList="nodownload"></video>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
    import {ref} from 'vue'
    import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'

    const isModalOpen = ref(false);
    const videoUrl = ref('');

    defineExpose({ isModalOpen, videoUrl });
</script>
