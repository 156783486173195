<template>
    <TransitionRoot as="template" :show="isModalOpen">
        <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="isModalOpen = false" :initialFocus="showSolutionsButtonRef">
            <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-500" enter-from="opacity-0"
                                 enter-to="opacity-100"
                                 leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-black bg-opacity-75 transition-opacity"/>
                </TransitionChild>

                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300"
                                 enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                 enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                 leave-from="opacity-100 translate-y-0 sm:scale-100"
                                 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="p-6 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full border-2 border-gray-900">
                        <div class="bg-white mb-4">
                            <div class="sm:flex sm:items-center">
                                <div
                                    class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-400 sm:mx-0 sm:h-10 sm:w-10">
                                    <InformationCircleIcon class="h-6 w-6 text-primary-default" aria-hidden="true"/>
                                </div>
                                <div class="w-full mt-2 text-center sm:mt-0 sm:ml-2 sm:text-left">
                                    <DialogTitle as="h3" class="text-lg leading-6 font-semibold text-gray-900">
                                        Test Özeti
                                    </DialogTitle>
                                </div>
                            </div>
                        </div>
                        <div class="text-sm text-gray-900">
                            <div class="mb-4">
                                Testi <b>{{ formatDate(results.finished_at) }}</b> tarihinde tamamladın.
                            </div>
                            <div class="mb-4">
                                Toplam <b>{{ results.correct + results.wrong + results.empty }}</b> sorudan oluşan testte
                                <b>{{ results.correct }}</b> soruyu doğru, <b>{{ results.wrong }}</b> soruyu yanlış cevapladın.
                                <b>{{ results.empty }}</b> soruyu boş bıraktın.
                            </div>
                            <div class="mb-4">
                                <div class="w-full bg-gray-200 rounded-full h-2.5">
                                    <div class="bg-secondary h-2.5 rounded-full"
                                         :style="{width: (results.correct/(results.correct+results.wrong+results.empty)*100) + '%'}">
                                    </div>
                                </div>
                            </div>
                            <div class="sm:flex sm:flex-row mt-4">
                                <button type="button" class="btn btn-sm" @click="isModalOpen = false" ref="showSolutionsButtonRef">
                                    ÇÖZÜMLERİ GÖR
                                </button>
                            </div>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
    import { ref } from 'vue'
    import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
    import {InformationCircleIcon} from '@heroicons/vue/outline'
    import dayjs from 'dayjs'
    const dayjstr = require('dayjs/locale/tr')

    const isModalOpen = ref(false);
    const results = ref(null);
    const showSolutionsButtonRef = ref(null);

    const formatDate = (dateString) => {
        return dayjs(dateString).locale(dayjstr).format('D MMMM YYYY');
    };

    defineExpose({ isModalOpen, results });
</script>
