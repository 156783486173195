<template>
    <TransitionRoot as="template" :show="isModalOpen">
        <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="isModalOpen = false">
            <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-500" enter-from="opacity-0"
                                 enter-to="opacity-100"
                                 leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-black bg-opacity-75 transition-opacity"/>
                </TransitionChild>

                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300"
                                 enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                 enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                 leave-from="opacity-100 translate-y-0 sm:scale-100"
                                 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="p-6 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg w-full border-2 border-gray-900">
                        <div class="bg-white mb-4">
                            <div class="sm:flex sm:items-center">
                                <div
                                    class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-default bg-opacity-10 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationIcon class="h-6 w-6 text-yellow-default" aria-hidden="true"/>
                                </div>
                                <div class="w-full mt-2 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <DialogTitle as="h3" class="text-lg leading-6 font-semibold text-gray-900">
                                        Testi Bitir
                                    </DialogTitle>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="mb-4 text-sm text-gray-900">
                                <div v-if="remainingQuestionCount >= Math.round(totalQuestionCount / 4)" class="mb-4">
                                    Heyy! Çok fazla boş soru bıraktığını görüyorum.
                                    Bu testi bitirirsen, tekrar çözemeyeceksin, hatırlatayım :)
                                    Dilersen teste ara ver ve bu konuya çalış.
                                </div>
                                <div v-else-if="remainingQuestionCount > 0" class="mb-4">
                                    Henüz cevaplamadığın <b>{{ props.remainingQuestionCount }}</b> soru var.
                                </div>
                                <div>
                                    <b>Testi bitirmek istediğinden emin misin?</b>
                                </div>
                            </div>
                            <div class="sm:flex sm:flex-row mt-4">
                                <button type="button" class="btn btn-sm" @click="isModalOpen = false">
                                    TESTE DÖN
                                </button>
                                <a :href="props.unitUrl" type="button" class="btn btn-sm btn-yellow sm:ml-3">
                                    ARA VER
                                </a>
                                <button type="button" class="btn btn-sm btn-secondary sm:ml-3" @click="props.finishTest()">
                                    TESTİ BİTİR
                                </button>
                            </div>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
    import {ref} from 'vue'
    import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
    import {ExclamationIcon} from '@heroicons/vue/outline'

    const props = defineProps(['unitUrl', 'totalQuestionCount', 'finishTest']);

    const isModalOpen = ref(false);
    const remainingQuestionCount = ref(0);

    defineExpose({ isModalOpen, remainingQuestionCount });
</script>
