<template>
    <div>
        <form ref="formRef" :action="props.url" method="post" enctype="multipart/form-data">
            <input type="hidden" name="_token" :value="props.csrf">
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 font-medium text-sm text-gray-900">
                <div>
                    <label for="type_id">Doküman Tipi</label>
                    <select id="type_id" name="type_id" class="mt-1 w-full form-control" v-model="data.type_id" @change="data.type_id && typeChanged(true)">
                        <option value="">Lütfen seçiniz</option>
                        <option :key="type.id" v-for="type in props.types" :value="type.id"
                                :data-no-unit="type.no_unit" :data-no-category="type.no_category">
                            {{ type.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-sm-6 col-sm-offset-right-6 check-selects"
                     v-show="data.type_id && selectedType && !selectedType.no_category">
                    <label>Sınıf</label>
                    <select name="grade_id" class="mt-1 w-full form-control" v-model="data.grade_id" @change="data.grade_id && getResponsibleCourses(true)">
                        <option value="">Lütfen seçiniz</option>
                        <option :key="grade.id" v-for="grade in grades" :value="grade.id">
                            {{ grade.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-sm-9 col-sm-offset-right-3 check-selects"
                     v-show="data.type_id && selectedType && !selectedType.no_category && data.grade_id">
                    <label>Ders</label>
                    <select name="course_id" class="mt-1 w-full form-control" v-model="data.course_id"
                            @change="data.grade_id && data.course_id && courseChanged(true)">
                        <option value="">Lütfen seçiniz</option>
                        <option :key="course.id" v-for="course in courses" :value="course.id">
                            {{ course.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-sm-12 check-selects"
                     v-show="data.type_id && selectedType && !selectedType.no_category && data.grade_id && data.course_id && !selectedType.no_unit">
                    <label>Ünite</label>
                    <select name="unit_id" class="mt-1 w-full form-control" v-model="data.unit_id">
                        <option value="">Lütfen seçiniz</option>
                        <option :key="unit.id" v-for="unit in units" :value="unit.id">
                            {{ unit.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-sm-12 check-selects" v-show="data.type_id && selectedType && selectedType.no_unit">
                    <label>Kategori</label>
                    <select name="category_id" class="mt-1 w-full form-control" v-model="data.category_id">
                        <option value="">Lütfen seçiniz</option>
                        <option :key="category.id" v-for="category in categories" :value="category.id">
                            {{ category.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-12">
                    <label>Başlık</label>
                    <input type="text" name="title" maxlength="100" class="mt-1 w-full form-control" v-model="data.title">
                </div>
                <div class="form-group col-md-12">
                    <label>Dokümanın açıklaması ve içerdiği konular</label>
                    <textarea name="description" class="mt-1 w-full form-control" v-model="data.description"></textarea>
                </div>
                <div class="form-group col-sm-6 col-sm-offset-right-6" v-show="data.type_id && selectedType && !selectedType.no_category">
                    <label for="document-difficulty">Zorluk</label>
                    <select name="difficulty" class="mt-1 w-full form-control" v-model="data.difficulty">
                        <option value="">Lütfen seçiniz</option>
                        <option value="1">Kolay</option>
                        <option value="2">Orta</option>
                        <option value="3">Zor</option>
                    </select>
                </div>
                <div class="form-group col-12" v-show="data.type_id">
                    <label for="document-file">{{ isEditMode ? 'Yeni ' : '' }} Doküman Dosyası</label>
                    <div class="flex">
                        <div class="mr-2" v-show="data.full_url">
                            <a target="_blank" :href="data.full_url" title="Yüklü dokümanı gör">
                                <svg class="w-12 h-12" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                                    <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625z"></path>
                                    <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z"></path>
                                </svg>
                            </a>
                        </div>
                        <div>
                            <div v-show="documentTypeInfo" class="text-xs text-gray-900 font-medium">{{ documentTypeInfo }}</div>
                            <input type="file" ref="fileInputRef" class="mt-1 w-full form-control" @change="onDocumentSelected">
                        </div>
                    </div>

                    <input type="hidden" name="document_temp_id" :value="data.document_temp_id" />
                    <input type="hidden" name="previous_temp_id" :value="data.previous_temp_id" />
                </div>
                <div class="form-group col-12" v-show="[1,3,4].includes(data.type_id)">
                    <label>Soru Sayısı</label>
                    <input type="number" name="question_count" min="1" max="99" maxlength="2" class="mt-1 form-control w-full"
                           v-model="data.question_count" @change="resetAnswers" />
                </div>
                <div class="form-group col-md-12" v-show="[1,3,4].includes(data.type_id) && data.question_count">
                    <div class="flex flex-col">
                        <label>Cevap Anahtarı</label>
                        <select name="answer_count" class="mt-1 w-full form-control" v-model.number="data.answer_count" @change="resetAnswers">
                            <option value="">Seçenek sayısı seçiniz</option>
                            <option :value="2">2 seçenekli</option>
                            <option :value="3">3 seçenekli</option>
                            <option :value="4">4 seçenekli</option>
                            <option :value="5">5 seçenekli</option>
                        </select>
                        <input type="button" class="mt-2 btn btn-yellow btn-xs" value="Cevap Anahtarı Düzenle"
                               @click="showAnswersModal()" v-show="data.answer_count && data.full_url" />
                    </div>
                </div>
            </div>
            <input type="hidden" name="answers2" :value="data.answers2" />
            <div class="mt-4 font-medium text-sm text-gray-900" >
                <h5>Derslig Doküman Havuzu Ayarları</h5>
                <div class="flex items-center"
                     v-if="props.isEditMode ? props.data.user.school_id && props.data.user.is_derslig_author
                  : props.user.school_id && props.user.is_derslig_author">
                    <span class="font-normal font-gray-990">Dokümanı sadece kendi okuluma göster</span>
                    <input name="school_only" type="checkbox" class="ml-2 form-control" :checked="data.published === 2" />
                </div>
                <div v-else>
                    <div class="italic">İçeriklerinizi şu anda yalnızca <span class="font-bold text-secondary">okulunuza özel</span> görüntülenecek şekilde yükleyebilirsiniz.</div>
                    <div class="mt-2">İçeriklerinizin Derslig havuzunda görüntülenmesini istiyorsanız;</div>
                    <ul class="list-disc ml-4">
                        <li><a class="text-secondary font-bold" href="https://forms.gle/J4tHX9FSKgQkpCkAA" target="_blank">Buraya</a> tıklayın ve Derslig Yazarlığı başvuru formunu doldurun</li>
                        <li>Gerekli eğitimleri tamamlayın</li>
                        <li>Derslig Yazarı statüsü kazanın</li>
                        <li>İçeriklerinizi derslig havuzunda yayınlayın</li>
                    </ul>
                </div>
            </div>
            <div class="mt-4 space-x-2">
                <button type="button" class="btn" @click="save">
                    {{ props.isEditMode ? 'Düzenle' : 'Ekle' }}
                </button>
                <a :href="props.backUrl" class="btn btn-gray">Geri dön</a>
            </div>
        </form>
        <ProgressBarModal ref="progressBarModalRef"></ProgressBarModal>
        <DocumentAnswerModal ref="documentAnswerModal" :management-mode="true" :data="data" :files-url="props.filesUrl"
             @setAnswers="setAnswers"></DocumentAnswerModal>
    </div>
</template>

<script setup>
    import {onMounted, ref} from "vue";
    import DocumentAnswerModal from "../../components/modal/DocumentAnswerModal.vue";
    import ProgressBarModal from '../../components/modal/ProgressBarModal.vue'

    const props = defineProps(['user', 'data', 'url', 'types', 'grades', 'courses', 'categories', 'backUrl', 'filesUrl', 'csrf', 'isEditMode']);

    const isFinished = ref(false);
    const formRef = ref(null);
    const documentAnswerModal = ref(null);
    const progressBarModalRef = ref(null);
    const fileInputRef = ref(null);
    const documentTypeInfo = ref('');

    const grades = ref([]);
    const courses = ref([]);
    const units = ref([]);
    const categories = ref([]);

    const data = ref({
        id: '',
        type_id: '',
        grade_id: '',
        course_id: '',
        unit_id: '',
        category_id: '',
        title: '',
        description: '',
        difficulty: '',
        document_temp_id: '',
        previous_temp_id: '',
        published: 0,
        answers2: '',
        question_count: 0,
        answer_count: '',
        full_url: '',
    });

    if (props.data) {
        data.value = props.data;
    }

    const selectedType = ref(null);

    onMounted(() => {
        if (props.isEditMode) {
            typeChanged();
        }
        getResponsibleGrades();
    });

    const typeChanged = (reset = false) => {
        let typeId = data.value.type_id;
        selectedType.value = props.types.find(type => type.id === typeId);

        if (reset) {
            data.value.grade_id = '';
            data.value.course_id = '';
            data.value.unit_id = '';
            data.value.category_id = '';
            data.value.difficulty = '';
            data.value.question_count = 0;
            data.value.answer_count = '';
            data.value.full_url = '';
        }

        if (data.value.document_temp_id && data.value.document_temp_id !== '') {
            data.value.previous_temp_id = data.value.document_temp_id;
            data.value.document_temp_id = '';
            documentAnswerModal.value.documentUrl = '';
        }
        fileInputRef.value.value = '';

        if (selectedType.value.no_category) {
            loadCategories();
            units.value = [];
        } else {
            categories.value = [];
        }

        if (typeId === 6) {
            documentTypeInfo.value = 'PDF, word, excel veya resim dosyası yükleyebilirsiniz';
        } else if ([2, 7, 5].includes(typeId)) {
            documentTypeInfo.value = 'PDF veya word dosyası yükleyebilirsiniz';
        } else {
            documentTypeInfo.value = 'Sadece PDF dosyası yükleyebilirsiniz';
        }
    }

    const courseChanged = async (reset = false) => {
        if (selectedType.value.no_unit) {
            loadCategories(reset, data.value.grade_id, data.value.course_id);
        } else {
            await getUnits(reset);
        }
    }

    const loadCategories = (reset = false, gradeId = null, courseId = null) => {
        categories.value = [];

        props.categories.forEach(category => {
            if (category.type_id === parseInt(data.value.type_id)) {
                if (gradeId && courseId) {
                    if (category.grade_id === gradeId && category.course_id === courseId) {
                        categories.value = [...categories.value, category];
                    }
                } else {
                    categories.value = [...categories.value, category];
                }
            }
        });

        if (reset) {
            data.value.category_id = '';
        }
    }

    const getResponsibleGrades = async () => {
        if (props.grades && props.grades.length > 0) {
            grades.value = props.grades;
        } else {
            let response = await axios.get('/api/responsible-grades/' + props.user.school_level_id + '/' + props.user.branch_id);
            grades.value = response.data.data;
        }

        if (data.value.grade_id && data.value.course_id) {
            await getResponsibleCourses(false);
            await courseChanged(false);
        }
    }

    const getResponsibleCourses = async (reset = false) => {
        if (!reset && props.courses && props.courses.length > 0) {
            courses.value = props.courses;
        } else {
            let response;

            if (props.user.is_allowed_for_all_branches) {
                response = await axios.get('/api/courses', {
                    params: {
                        gradeId: data.value.grade_id
                    }
                });
            } else {
                response = await axios.get('/api/responsible-courses/' + data.value.grade_id + '/' + props.user.branch_id);
            }

            courses.value = response.data.data;

            if (reset) {
                data.value.course_id = '';
                data.value.unit_id = '';
                data.value.category_id = '';
            }
        }
    }

    const getUnits = async (reset = false) => {
        let response = await axios.get('/api/units', {
            params: {
                'gradeId': data.value.grade_id,
                'courseId': data.value.course_id
            }
        });
        units.value = response.data.data;

        if (reset) {
            data.value.unit_id = '';
        }
    }

    const onDocumentSelected = (event) => {
        if (data.value.document_temp_id) {
            data.value.previous_temp_id = data.value.document_temp_id;
            data.value.document_temp_id = '';
        }

        if (event.target.files.length > 0) {
            const formData = new FormData();
            formData.append('type_id', data.value.type_id);
            formData.append('doc', event.target.files[0]);
            formData.append('previous_temp_id', data.value.previous_temp_id);

            progressBarModalRef.value.isModalOpen = true;

            axios.post('/icerik-yonetimi/dokuman/dokuman-ekle', formData, {
                onUploadProgress: uploadEvent => {
                    progressBarModalRef.value.percent = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
                }
            }).then(response => {
                data.value.previous_temp_id = '';
                data.value.document_temp_id = response.data.temp_id;
                data.value.full_url = response.data.document_url;
            }).catch(e => {
                data.value.full_url = '';
                fileInputRef.value.value = '';

                let message = '';

                if (!e.response) {
                    message = 'Lütfen internet bağlantınızı kontrol ediniz!';
                } else if (e.response.status === 422) {
                    Object.keys(e.response.data.errors).forEach(field => {
                        e.response.data.errors[field].forEach(errorMessage => {
                            message += errorMessage + "\n";
                        });
                    });
                } else {
                    message = 'Bir hata oluştu! Lütfen başka bir dosya ile tekrar deneyin.';
                }

                alert(message);
            }).then(() => {
                progressBarModalRef.value.isModalOpen = false;
            });
        } else {
            data.value.full_url = '';
        }
    }

    const resetAnswers = () => {
        data.value.answers2 = '';
        documentAnswerModal.value.answers = [];
    }

    const showAnswersModal = () => {
        documentAnswerModal.value.loadAnswers();
        documentAnswerModal.value.isModalOpen = true;
    }

    const setAnswers = (currentAnswers) => {
        data.value.answers2 = currentAnswers.join(',');
    }

    const check = () => {
        if (!data.value.type_id) {
            alert('Doküman tipi seçiniz');
            return false;
        }

        if (selectedType.no_category === 0 && data.value.grade_id === "") {
            alert('Sınıf seçiniz');
            return false;
        }

        var check = true;
        var checkSelects = document.getElementsByClassName('check-selects');
        for (let checkSelect of checkSelects) {
            if (checkSelect.style.display !== "none") {
                if (checkSelect.getElementsByTagName('select')[0].value === "") {
                    alert(checkSelect.getElementsByTagName('label')[0].textContent + ' seçiniz');
                    check = false;
                    return false;
                }
            }
        }

        if (!check) {
            return false;
        }

        if (data.value.title === "") {
            alert('Başlık giriniz');
            return false;
        }

        if (selectedType.value.no_category === 0) {
            if (data.value.difficulty === "") {
                alert('Zorluk seçiniz');
                return false;
            }
        }

        if (!props.isEditMode && fileInputRef.value.value === "" || data.value.full_url === "") {
            alert('Doküman dosyası yükleyiniz');
            return false;
        }

        if ([1,3,4].includes(data.value.type_id)) {
            if (data.value.question_count === 0) {
                alert('Soru sayısı giriniz');
                return false;
            }

            if (data.value.answer_count === 0) {
                alert('Cevap anahtarında bulunacak sorular için seçenek sayısı seçiniz');
                return false;
            }

            if (data.value.answers2.length === 0) {
                alert('Cevap anahtarını doldurunuz');
                return false;
            }

            if (data.value.answers2.split(',').length !== data.value.question_count) {
                alert('Cevap anahtarını tamamen doldurunuz');
                return false;
            }
        }

        return true;
    }

    const save = () => {
        if (!check()) {
            return;
        }

        formRef.value.submit();
    }
</script>
