export function initMediaElement() {
    $(function () {
        mejs.i18n.language('tr');
        $('.video-popup video').mediaelementplayer({
            iconSprite: '/img/icons/mejs-controls.svg',
            shimScriptAccess: 'always',
            stretching: "responsive",
            enableAutosize: false
        });

        $('.derslig-video-player-btn').click(function () {
            let videoId = $(this).data('video-src');
            $.magnificPopup.open({
                items: {
                    src: $('#' + videoId),
                    type: 'inline'
                },
                callbacks: {
                    open: function () {
                        $('#' + videoId).find('video')[0].play();
                    },
                    close: function () {
                        $('#' + videoId).find('video')[0].load();
                    }
                },
                mainClass: 'mfp-fade'
            });
        });
    });
}
