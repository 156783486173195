<template>
    <div v-show="isModalOpen" class="fixed inset-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-19/20 p-6 w-80 xs:w-96 h-20 bg-footer text-gray-900 text-center text-sm font-bold border border-primary-500 rounded z-50">
        <div id="uploadProgressBar" class="bg-secondary h-4" :style="{width: percent+'%'}"></div>
        <div class="mt-3">Yükleniyor...</div>
    </div>
</template>

<script setup>
    import {ref} from "vue";

    const isModalOpen = ref(false);
    const percent = ref(0);

    defineExpose({ isModalOpen, percent });
</script>
