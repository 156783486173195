import { ref, reactive, toRefs } from "vue";
import ClassManagementServices from "../services/ClassManagementServices";

export default function () {
  const loading = ref(false);
  const data = reactive({ list: [] });
  const error = ref(null);

  const getGrade = async () => {
    loading.value = true;
    return new Promise(async (resolve, reject) => {
      await ClassManagementServices.getGrade()
        .then((response) => {
            loading.value = false;
            if (response.error != ""){
                error.value = response.error;
                reject(response.error);
            }else{
                resolve(response);
            }
        })
        .catch((error) => {
          loading.value = false;
          error.value = "Lütfen okulunuzu seçiniz";
          reject(error);
        });
    });
  };


  const getStudents = async (obj) => {
    loading.value = true;
    return new Promise(async (resolve, reject) => {
      await ClassManagementServices.getStudents(obj)
        .then((response) => {
            loading.value = false;
            if (response.error != ""){
                error.value = response.error;
                reject(response.error);
            }else{
                resolve(response);
            }
        })
        .catch((error) => {
          loading.value = false;
          error.value = "Lütfen okulunuzu seçiniz";
          reject(error);
        });
    });
  };



  const setClassManagementError = (err) => {
    error.value = err
  }

  return {
    classManagementError: error,
    classManagementLoading: loading,
    classManagementData: toRefs(data).list,
    getGrade,
    getStudents,
    setClassManagementError
  };
}
