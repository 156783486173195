<template>
    <div class="typewriter-content" :class="{'typewriter-content-blink': isWriting}">
        {{ typeWriterContent }}
    </div>
</template>

<script setup>
    import {onMounted, ref} from 'vue';

    const props = defineProps(['content', 'stopBlinking']);
    const emit = defineEmits(['callback']);

    const typeWriterContent = ref('');
    const isWriting = ref(false);

    let typeWriterIndex = 0;
    let startTypeWriterHandler;
    let typeWriterHandler;

    let professorTypingAudio = new Audio("/img/learning/professor/typing.mp3");
    professorTypingAudio.loop = true;

    onMounted(() => {
        startTypeWriter();
    });

    const typeWriter = () => {
        if (typeWriterIndex < props.content.length) {
            typeWriterContent.value = typeWriterContent.value + props.content.charAt(typeWriterIndex);
            typeWriterIndex++;
        } else {
            stopTypeWriter();
        }
    }

    const startTypeWriter = () => {
        resetTypeWriter();

        isWriting.value = true;

        startTypeWriterHandler = setTimeout(function () {
            professorTypingAudio.play();
            typeWriterHandler = setInterval(typeWriter, 60);
        }, 1000);
    }

    const stopTypeWriter = () => {
        professorTypingAudio.pause();
        professorTypingAudio.currentTime = 0;
        clearInterval(typeWriterHandler);

        if (props.stopBlinking) {
            isWriting.value = false;
        }

        emit('callback');
    }

    const resetTypeWriter = () => {
        typeWriterIndex = 0;
        typeWriterContent.value = '';
        clearInterval(typeWriterHandler);
        clearTimeout(startTypeWriterHandler);
    }

    defineExpose({ stopTypeWriter })
</script>

<style scoped>
    .typewriter-content {
        font-family: 'Source Code Pro', monospace;
        color: #111;
    }

    .typewriter-content-blink:after {
        content: '|';
        display: inline-block;
        font: 1em solid;
        font-weight: bold;
        -webkit-animation: blink .5s step-end infinite alternate;
        animation: blink .5s step-end infinite alternate;
    }

    @keyframes blink {
        0% {
            opacity: 1;
        }
        49% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes blink {
        0% {
            opacity: 1;
        }
        49% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }
</style>
