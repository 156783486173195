import http from "./http";


const ClassManagement = {
  getGrade: async () => {
    try {
      const response = await http.get("school/grade-sections");
      return response?.data;
    } catch (e) {
      // console.log("hata e", e);
    }
  },
  getStudents: async (obj) => {
    try {
      const response = await http.get(`school/students?gradeId=${obj.grade_id}&gradeSectionId=${obj.grade_section_id}`);
      return response?.data;
    } catch (e) {
      // console.log("hata e", e);
    }
  }
};


const getParams = (obj) => {
    const params = new URLSearchParams();
    for (const key in obj) {
      params.append(key, obj[key]);
    }
return params
}

export default ClassManagement;
