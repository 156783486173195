<template>
    <div class="w-full border-2 border-gray-300 bg-gray-100 p-2">
        <div class="flex  justify-between">
            <div class="w-full flex items-center justify-start space-x-2">
                <div class="font-bold">{{ group_index + 1 }}. Grup</div>
            </div>
        </div>


        <div class="w-full flex flex-col space-y-2 items-center justify-center text-secondary my-4">
            <div><Gift size="30"/></div>
            <div class="text-2xl font-bold">{{ group_points.point }} Puan</div>

            <div :class="isSelectButtonVisible && !loading ? 'visible':'invisible'">
                <button class="bg-secondary rounded-lg px-2 py-1 flex items-center justify-center text-sm text-white min-w-max" @click="slectBTN">Öğrenci Seç</button>
            </div>
        </div>


        <div class="flex flex-wrap mt-3">
            <div class="w-1/3 flex items-center justify-center p-2" v-for="g, key2 in group" :key="key2">
                <div  :class="key2 < showCount ? 'visible':'invisible'">
                    <Student :avatar="false" :selected="group[temporarySelection] == g || group[selected] == g" :deactive-buttons="[]" :event="event" :obj="{...students[g], index: g}" />
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import Student from '../../other/student.vue'
import { computed, defineProps, defineEmits, onMounted, reactive, ref, watch } from 'vue';
import {Gift} from '@icon-park/vue-next';

const props               = defineProps(['students', 'group_index', 'group', 'event', 'showCount', 'options', 'selectAll', 'group_points', 'soundStart'])
const emit                = defineEmits(['loading', 'selectedd'])
const loading             = ref(false)
const selected            = ref(null)
const temporarySelection  = ref(null)


import useRandIndex from '../../../compositions/useRandIndex'
const {selectedIndexError, select, selectingIndex, getRandomSelectIndex, excludedIndexes, selectableLength, selectableSize, selectableIndexes} = useRandIndex()

onMounted(() => {
    selectableLength.value = props.group.length
    selectableSize.value = 1
})


watch(loading, cLoading => {
    emit('loading', cLoading)
})

watch(props, cProps => {
    if (cProps.selectAll) slectBTN()
}, {deep: true})



const slectBTN = () => {
    if (loading.value) return false
    loading.value = !loading.value
    select()

    selected.value = null
    temporarySelection.value = null

    let i = 0;
    const interval = setInterval(() => {
        temporarySelection.value = getRandomSelectIndex()
        props.soundStart(4)

        if (i == (props.options['randomSelectionSpeed'].value * 2)) {
            temporarySelection.value = null
            const selectIndex   = props.options['fineSelection'].value ? selectingIndex.value : getRandomSelectIndex()
            selected.value      = selectIndex[0]
            loading.value       = !loading.value
            props.students[props.group[selectIndex]].selectedSize += 1
            emit('selectedd', props.group[selectIndex])
            props.soundStart(3)
            clearInterval(interval)
        }
        i++
    }, 500);

}

const getGroupPoints = computed(() => {
    const selectStudents = [...props.group]
    return selectStudents.map(i => props.students[i].point).reduce((a, b) => a+b)
})

const isSelectButtonVisible = computed(() => {
    return reactive(props.showCount >= props.group.length ? true : false)
})

</script>
