<template>

    <fs v-model="fullscreen" class="h-full w-full select-none bg-primary-darkest">

        <div class="flex flex-col w-full h-full space-y-2 items-center justify-center py-6">
            <div class="text-white absolute -left-24 -bottom-24 z-10 opacity-40"><BigClock size="240" /></div>
            <div class="flex font-bold py-6 text-yellow-default" :class="fullscreen ? 'text-8xl': 'text-5xl'">
                <span>{{cTime.hours <= 9 ? '0':''}}{{time.hours}}</span>:
                <span>{{cTime.minutes <= 9 ? '0':''}}{{time.minutes}}</span>:
                <span>{{cTime.seconds <= 9 ? '0':''}}{{time.seconds}}</span>
            </div>
            <div class="flex space-x-2 text-white h-12">
                <button class="px-4 py-2 rounded-lg bg-secondary" @click="fullscreen = !fullscreen">
                    <FullScreen size="20" v-if="!fullscreen"/>
                    <OffScreen size="20" v-else/>
                </button>
            </div>
        </div>
    </fs>
</template>

<script setup>
import { defineProps, ref, watch} from "vue";
import { useTime } from 'vue-timer-hook';
import {BigClock, FullScreen, OffScreen} from '@icon-park/vue-next'

const fullscreen  = ref(false)
const cTime       = ref({seconds: 0, minutes:0, hours:0})

const props = defineProps(['index', 'hour', 'second', 'minute'])
const time  = useTime('24-hour');


watch(time.seconds,(t) => {
    cTime.value.seconds = t
}, {immediate: true})


watch(time.minutes,(t) => {
    cTime.value.minutes = t
}, {immediate: true})

watch(time.hours,(t) => {
    cTime.value.hours = t
}, {immediate: true})


watch(time.isExpired,(t) => {
    if (t) {
        isFinish.value = true
    }
})


</script>
