<template>
    <div class="relative">
        <button class="z-10 relative flex items-center focus:outline-none select-none" @click="open = !open">
            <slot name="button"></slot>
        </button>
        <button class="fixed inset-0 h-full w-full cursor-default focus:outline-none z-20" v-if="open" @click="open = false" tabindex="-1"></button>
        <transition enter-active-class="transition-all duration-100 ease-out" leave-active-class="transition-all duration-750 ease-in" enter-class="opacity-0 scale-75" enter-to-class="opacity-100 scale-100" leave-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-75">
            <div class="absolute shadow-lg border w-48 rounded py-1 px-2 text-sm mt-2 mr-2 bg-white z-20" :class="placement === 'right' ? 'right-0' : 'left-0'" v-if="open">
                <slot name="content"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            open: false,
        };
    },
    props: {
        placement: {
            type: String,
            default: "right",
            validator: (value) => ["right", "left"].indexOf(value) !== -1,
        },
    },
    mounted() {
        const onEscape = (e) => {
            if (e.key === "Esc" || e.key === "Escape") {
                this.open = false;
            }
        };

        document.addEventListener("keydown", onEscape);
    },
    beforeUnmount() {
        document.removeEventListener("keydown", onEscape);
    }
};
</script>
