<template>
    <div>
        <div class="flex w-full items-center justify-center">
            <div class="w-full flex space-x-2 items-center">
                <div class="text-xl font-bold ">{{ grade?.sectionName || 'Derslig' }} Sınıfı </div>
            </div>

            <div class="">
                <div class="min-w-max flex items-center justify-center">
                    <div class="flex">
                        <div class="flex items-center justify-center space-x-2" v-if="options['displayStudentName'].value">
                            <div class="text-xs">Listeleme Seçeneği</div>
                            <ValueSelect :obj="options['displayStudentName']" :prefix="['İsim', 'Numara']"/>
                        </div>
                        <div class="flex -ml-2" v-else>
                            <ValueSelect :obj="options['gradeSize']" />
                            <div class="p-1">
                                <button class="w-full h-full px-3 bg-secondary rounded-full hover:bg-primary-300 text-white" @click="options['displayStudentName'].value = true" v-if="options['displayStudentName'].visible">
                                    x
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>


        <div class="my-4" v-if="students && options['displayStudentName'].value && students.length < 10">
            <div class="w-full flex flex-col space-y-4 items-center justify-center">
                <div class="w-full bg-secondary text-left p-4 text-white">
                    <div>
                        Öğrencilerinizin tamamı listede görünmüyorsa <a href="/sinif-paketi/ekle" class="font-bold">öğrencileriniz Derslig'e davet edebilir</a> ya da sol üst kısımdan listeleme seçeneğini "Numara" olarak değiştirebilirsiniz.
                    </div>
                 </div>
            </div>
        </div>

        <div class="flex space-x-2 mt-12">
            <div class="w-full">
                <div class="flex flex-wrap" v-if="students">
                    <div class="w-1/6 flex items-center justify-center p-2" v-for="i, index in students" :key="i">
                        <StudentCard :event="event"  :obj="{...i, index}" :buttons="['deactiveSelection','activeSelection']" :deactive-buttons="[!i.selectable ? 'deactiveSelection' : 'activeSelection']"/>
                    </div>
                </div>
            </div>

            <div class="w-1/3 bg-gray-100 rounded-lg" v-if="students && sortPointStudents && sortPointStudents.filter(i => i.point > 0).length > 0">
                <div class="bg-gray-200 py-2 px-4 font-bold">Puan Sıralaması [ilk 5]</div>
                <div>
                    <div  v-for="s, key in sortPointStudents" :key="s.index">
                        <div class="border-b-2 border-gray-200 py-2 px-4" v-if="key < 5">
                            <div class="flex items-center justify-center ">
                                <div class="w-full">{{ s?.full_name || `${(s.index + 1)}. Öğrenci` }}</div>
                                <div class="min-w-max">
                                    <div class="flex space-x-1 items-center justify-center text-secondary">
                                        <div class="text-xs"><Gift /></div>
                                        <div class="text-lg font-bold">{{ s.point }} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>


<script setup>

import ValueSelect from '../other/valueSelect.vue'
import StudentCard from '../other/student.vue'
import {defineProps, reactive, computed} from 'vue'
import {Gift} from '@icon-park/vue-next';
const props  = defineProps(['user', 'grade', 'options', 'event'])

const students = computed(() => {
    const cStudents = props.options['displayStudentName'].value ? props.grade?.students : props.grade?.noNameStudents
    return reactive(cStudents)
})

const sortPointStudents = computed(() => {
    let newStudents = [...students.value]
    let index = -1
    newStudents.map(s => {
        index += 1
        s.index = index
        return s
    })

    return newStudents.sort((a,b) => b.point - a.point)
})



</script>
