<template>
    <TransitionRoot as="template" :show="isModalOpen">
        <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="isModalOpen = false">
            <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-500" enter-from="opacity-0" enter-to="opacity-100"
                                 leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-black bg-opacity-75 transition-opacity"/>
                </TransitionChild>

                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300"
                                 enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                 enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                 leave-from="opacity-100 translate-y-0 sm:scale-100"
                                 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full border-2 border-gray-900">
                        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div class="sm:flex sm:items-center">
                                <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-default bg-opacity-10 sm:mx-0 sm:h-10 sm:w-10">
                                    <QuestionMarkCircleIcon class="h-6 w-6 text-yellow-default" aria-hidden="true"/>
                                </div>
                                <div class="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex justify-between items-center">
                                    <DialogTitle as="h3" class="text-lg leading-6 font-bold text-gray-900">
                                        İpucu
                                    </DialogTitle>
                                    <div>
                                        <button class="btn btn-sm btn-yellow" @click="isModalOpen = false">
                                            Kapat
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="px-4 py-3 sm:px-6 text-sm">
                            <type-writer :content="hint" ref="typewriter"></type-writer>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import {ref, watch} from 'vue'
    import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
    import {QuestionMarkCircleIcon} from '@heroicons/vue/outline'
    import TypeWriter from "../../utils/TypeWriter.vue";

    const isModalOpen = ref(false);
    const hint = ref('');
    const typewriter = ref(null);

    defineExpose({ isModalOpen, hint });

    watch(isModalOpen, (isModalOpen) => {
        if (!isModalOpen) {
            typewriter.value.stopTypeWriter();
        }
    });
</script>
