<template>
    <fs v-model="fullscreen" class="h-full w-full select-none bg-primary-darkest">

        <div class="flex flex-col w-full h-full space-y-2 items-center justify-center py-6">
            <div class="text-white absolute -left-24 -bottom-24 z-10 opacity-40"><Stopwatch size="240" /></div>
            <div class="flex font-bold py-6 text-yellow-default" :class="fullscreen ? 'text-8xl': 'text-5xl'">
                <span>{{cTimer.hours <= 9 ? '0':''}}{{timer.hours}}</span>:
                <span>{{cTimer.minutes <= 9 ? '0':''}}{{timer.minutes}}</span>:
                <span>{{cTimer.seconds <= 9 ? '0':''}}{{timer.seconds}}</span>
            </div>

            <div class="flex space-x-2 text-white h-12" v-if="!isFinish">
                <button class="bg-secondary px-4 py-2 rounded-lg"   @click="actions = 'start'" v-if="actions == 'beginning'">
                    <PlayOne size="20"/>
                </button>
                <div class="flex space-x-2" v-else>
                    <button class="px-4 py-2 rounded-lg" :class="['pause'].includes(actions) ? 'hidden':'bg-secondary'"  @click="!['pause'].includes(actions) && (actions = 'pause')">
                        <Pause size="20"/>
                    </button>
                    <button class="px-4 py-2 rounded-lg" :class="['start', 'resume'].includes(actions) ? 'hidden':'bg-secondary'"  @click="!['start', 'resume'].includes(actions) && (actions = 'resume')">
                        <PlayOne size="20"/>
                    </button>
                    <button class="bg-secondary px-4 py-2 rounded-lg" @click="actions = 'restart'">
                        <Refresh size="20"/>
                    </button>
                </div>
                <button class="px-4 py-2 rounded-lg bg-secondary" @click="fullscreen = !fullscreen">
                    <FullScreen size="20" v-if="!fullscreen"/>
                    <OffScreen size="20" v-else/>
                </button>
            </div>
            <div v-else>
                <div>
                    <button class="bg-secondary px-4 py-2 rounded-lg text-white" @click="actions = 'restart'">Restart</button>
                </div>
            </div>

        </div>
    </fs>
</template>

<script setup>
import { watch, defineProps, ref} from "vue";
import { useTimer } from 'vue-timer-hook';
import {Stopwatch, Pause, PlayOne, Refresh, FullScreen, OffScreen} from '@icon-park/vue-next'


const actions   = ref('beginning') //beginning
const cTimer    = ref({seconds: 0, minutes:0, hours:0})
const isFinish  = ref(false)
const fullscreen  = ref(false)

const props = defineProps(['index', 'hour', 'second', 'minute'])
const time  = new Date();
time.setSeconds(time.getSeconds() + (3600 * props.hour) + (60 * props.minute) + (props.second));
const timer  = useTimer(time, false);


const resetTimer = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + (3600 * props.hour) + (60 * props.minute) + (props.second));
    timer.restart(time);
}

watch(actions, (cActions) => {
    if (cActions == 'start') {
        resetTimer()
    }else if (cActions == 'restart') { //ilk başladı
        resetTimer()
        actions.value = 'beginning'
        timer.pause()
        isFinish.value = false
    }
    //else if(cActions == 'start') timer.start()
    else if(cActions == 'pause') timer.pause()
    else if(cActions == 'resume') timer.resume()

})


watch(timer.seconds,(t) => {
    cTimer.value.seconds = t
}, {immediate: true})


watch(timer.minutes,(t) => {
    cTimer.value.minutes = t
}, {immediate: true})

watch(timer.hours,(t) => {
    cTimer.value.hours = t
}, {immediate: true})


watch(timer.isExpired,(t) => {
    if (t) {
        isFinish.value = true
    }
})



</script>
