let sidebar = document.getElementById('sidebar');

if (sidebar) {
    document.getElementById('menu-button').addEventListener("click", function(e) {
        sidebar.classList.add("opened");
        document.getElementById('menu-close-button').style.display = 'block';
        document.getElementById('menu-button').style.display = 'none';
        document.getElementById('menu-close-overlay').style.display = 'block';
    });
    document.getElementById('menu-close-button').addEventListener("click", function(e) {
        closeSidebar();
    });
    document.getElementById('menu-close-overlay').addEventListener("click", function(e) {
        closeSidebar();
    });
    function closeSidebar(){
        sidebar.classList.remove("opened");
        document.getElementById('menu-button').style.display = 'block';
        document.getElementById('menu-close-button').style.display = 'none';
        document.getElementById('menu-close-overlay').style.display = 'none';
    }
}
