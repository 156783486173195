export function startTour (tourguideOptions, tourSteps, startUrl, endUrl) {
    const tg = new tourguide.TourGuideClient({
        finishLabel: tourguideOptions.finish_label,
        progressBar: "#0AB1B9",
        exitOnEscape: false,
        exitOnClickOutside: false,
        closeButton: true,
        nextLabel: 'İleri',
        prevLabel: 'Geri'
    });

    tg.addSteps(tourSteps);
    tg.onFinish(()=>{
        axios.post(endUrl); // Save tour ended info
    });
    tg.onAfterExit(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });

    tg.start();

    axios.post(startUrl); // Save tour started info
}
