<template>
    <div>
        <input type="hidden" :name="name" :value="selectedValue" ref="selectedValueInputRef">
        <div ref="menuRef">
            <Menu as="div" class="relative inline-block text-left w-full">
                <input type="text" class="form-control w-full" :class="selectedValue ? 'bg-gray-200' : ''" ref="inputRef" @keyup="getItems($event)" />
                <MenuItems as="div" v-show="isOpen" class="absolute left-0 w-full max-h-36 bg-white divide-y divide-gray-500 focus:outline-none shadow-flat z-10 overflow-y-scroll" static
                    :class="[position === 'top' ? '-top-36' : '']">
                    <div ref="menuListRef">
                        <MenuItem v-slot="{ active }" v-for="(item, index) in data">
                            <button type="button" :class="[active ? 'bg-gray-300' : '','group flex items-center w-full px-3 py-1.5',]" @click="selectItem($event, index)">
                                <span class="ml-2">{{ item[textLabel] }}</span>
                            </button>
                        </MenuItem>
                    </div>
                </MenuItems>
            </Menu>
        </div>
        <div v-show="isLoading"
             class="absolute w-full h-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center bg-black bg-opacity-10 z-10">
            <div class="w-full h-full flex items-center justify-center">
                <pulse-loader :color="'#03B0B9'" :size="'12px'"></pulse-loader>
            </div>
        </div>
    </div>
</template>

<style scoped>
    button {
        text-align: unset;
    }
</style>

<script>
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import {onMounted, ref} from "vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/vue/solid";

export default {
    name: "AutoComplete",
    components: {
        MenuButton,
        ChevronUpIcon, ChevronDownIcon,
        PulseLoader,
        Menu,
        MenuItems,
        MenuItem,
    },
    props: {
        preselectedValue: {
            type: String,
            default: null
        },
        url: {
            type: String,
            default: null
        },
        name: String,
        textLabel: String,
        valueLabel: String,
        position: String,
    },
    setup(props) {
        const isLoading = ref(false);
        const q = ref('');
        const data = ref([]);
        const menuRef = ref(null);
        const menuListRef = ref(null);
        const selectedValueInputRef = ref(null);
        const inputRef = ref(null);
        const isOpen = ref(false);
        const selectedValue = ref(props.preselectedValue ?? '');

        onMounted(() => {
            document.addEventListener("keydown", (e) => {
                if (e.key === "Esc" || e.key === "Escape") {
                    isOpen.value = false;
                }
            });

            document.addEventListener("mousedown", (e) => {
                if (isOpen.value === false)
                    return false;

                if (!menuRef.value.contains(e.target)) {
                    isOpen.value = false;
                }
            });
        });

        const getItems = (event) => {
            if (q.value === event.target.value) {
                return;
            }

            if (event.target.value.length > 1) {
                q.value = event.target.value;

                isLoading.value = true;

                axios.get(props.url, {
                    params: {
                        q: q.value
                    }
                }).then((response) => {
                    if (response.data.error === "") {
                        if (response.data.data.length > 0) {
                            data.value = response.data.data;
                            inputRef.value.dataset.data = JSON.stringify(data.value);

                            isOpen.value = true;
                        } else {
                            isOpen.value = false;
                        }
                    }
                }).catch((error) => {
                }).then(() => {
                    isLoading.value = false;
                });
            } else {
                isOpen.value = false;
            }
        };

        const selectItem = (e, index) => {
            let item = data.value[index];
            inputRef.value.value = item.summary;
            selectedValue.value = item.id;

            isOpen.value = false;
            inputRef.value.disabled = true;
        };

        return {
            isLoading,
            isOpen,
            data,
            menuRef,
            menuListRef,
            selectedValueInputRef,
            inputRef,
            selectedValue,
            getItems,
            selectItem,
        };
    },
}
</script>
