<template>
    <div v-if="taskResults.length > 0" v-for="(taskResult, index) in taskResults" :key="taskResult.id">
        <template v-if="taskResult.status !== 2 && activeTasks.indexOf(taskResult) < maxTaskVisibleCount">
            <a :href="(taskResult.learning_task.learning_content || taskResult.learning_task.learning_test) && taskResult.learning_task.url">
                <div class="mt-4 p-4 bg-gray-200 bg-opacity-70 rounded-xl">
                    <div class="font-semibold text-gray-950">
                        {{ taskResult.learning_task.grade.name }}
                        {{ taskResult.learning_task.course.name }}
                    </div>
                    <h2 class="font-black text-gray-900 text-lg">
                        {{ taskResult.learning_task.title }}
                    </h2>
                    <div class="mt-2 font-bold text-gray-900 text-sm">
                        <div class="flex items-center">
                            <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none">
                                <path d="M9 7H11V12H16V14H9V7Z" fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
                                      fill="currentColor"></path>
                            </svg>
                            <span class="ml-1">
                                {{ taskResult.learning_task ? taskResult.learning_task.start_time : '-' }} -
                                {{ taskResult.learning_task ? taskResult.learning_task.end_time : '-' }}
                            </span>
                        </div>
                        <div class="flex items-center">
                            <svg class="w-4 h-4" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clip-rule="evenodd"></path>
                            </svg>
                            <span class="ml-1">
                                {{ taskResult.learning_task.user.full_name }}
                            </span>
                        </div>
                        <div v-if="taskResult.learning_task.description" class="flex items-center">
                            <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8zm6.5-.25A.75.75 0 017.25 7h1a.75.75 0 01.75.75v2.75h.25a.75.75 0 010 1.5h-2a.75.75 0 010-1.5h.25v-2h-.25a.75.75 0 01-.75-.75zM8 6a1 1 0 100-2 1 1 0 000 2z"></path></svg>
                            <span class="ml-1">
                                <i>
                                    {{ taskResult.learning_task.description }}
                                </i>
                            </span>
                        </div>
                    </div>

                    <div class="mt-4 inline-flex space-x-2 items-center cursor-pointer"
                         @click="taskResult.learning_task.document && openTask(taskResult)">
                        <div class="p-2 inline-block bg-yellow-default leading-4 rounded-full">
                            <AkArrowRight />
                        </div>
                        <div class="font-bold text-gray-950">
                            <span v-if="taskResult.is_waiting">
                                Başla
                            </span>
                            <span v-if="taskResult.is_started">
                                Devam Et
                            </span>
                        </div>
                    </div>
                    <div style="clear: both;"></div>
                </div>
            </a>
        </template>
    </div>
    <div v-else class="mt-4 alert alert-info text-center">Hiç ödeviniz bulunmamaktadır</div>
    <DocumentAnswerModal ref="documentAnswerModal" :solution-mode="true" :files-url="props.filesUrl" :data="activeTaskResult?.learning_task?.document"
                         @setTaskResult="setTaskResult"></DocumentAnswerModal>
</template>

<script setup>
    import {computed, ref} from "vue";
    import DocumentAnswerModal from "../components/modal/DocumentAnswerModal.vue";
    import { AkArrowRight } from "@kalimahapps/vue-icons";

    const props = defineProps(['taskResults', 'filesUrl', 'maxTaskVisibleCount']);

    const taskResults = ref(props.taskResults);
    const documentAnswerModal = ref(null);
    const activeTaskFinished = ref(false);
    const activeTaskResult = ref(null);

    const openTask = (taskResult) => {
        activeTaskResult.value = taskResult;
        activeTaskResult.value.learning_task.document.file_upload = taskResult.learning_task.file_upload;
        activeTaskResult.value.learning_task.document.task_id = taskResult.learning_task.id;

        // Ödevin bitme durumuna göre results frame'ini göster veya gizle
        documentAnswerModal.value.isTaskFinished = taskResult.status === 2;
        documentAnswerModal.value.isResultsOpen = taskResult.status === 2;

        // Dosya yüklüyse dosyayı modala aktar
        documentAnswerModal.value.fileUrl = taskResult.file_url;

        if (taskResult.status === 0 && (taskResult.learning_task.file_upload !== 1 || taskResult.file_url)) {
            startTask(taskResult);
        }

        documentAnswerModal.value.isModalOpen = true;
    }

    const startTask = (taskResult) => {
        axios.post('/api/dokuman/basla', {
            learningTaskResultId: taskResult.id
        }).then(response => {
            taskResult.status = response.data.data.status;
            taskResult.status_text = response.data.data.status_text;
            taskResult.is_waiting = response.data.data.is_waiting;
            taskResult.is_started = response.data.data.is_started;
            taskResult.is_finished = response.data.data.is_finished;
        }).catch((error) => {
        });
    }

    const setTaskResult = (newTaskResult) => {
        if (activeTaskResult.value) {
            activeTaskResult.value.status = newTaskResult.status;
            activeTaskResult.value.status_text = newTaskResult.status_text;
            activeTaskResult.value.is_waiting = newTaskResult.is_waiting;
            activeTaskResult.value.is_finished = newTaskResult.is_finished;
            activeTaskResult.value.is_started = newTaskResult.is_started;
            activeTaskResult.value.file_url = newTaskResult.file_url;
        }
    }

    const activeTasks = computed( () => {
        return taskResults.value ? taskResults.value.filter(tr => tr.status !== 2).sort((a,b) => b.status - a.status) : [];
    });
</script>
